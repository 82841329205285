import * as React from 'react';
import { Link, withRouter } from 'react-router-dom';
import AppContext from '../../context';
import { Button, Modal } from 'react-bootstrap';
import { useForm, Controller } from "react-hook-form";
import { ErrorMsg } from '../../components';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';


const UserDetails = ({ data, onUserActionSubmit, initSetPassword }) => {
    const { checkArrayData, getUserType, badgeToggle, userData } = React.useContext(AppContext);


    React.useEffect(() => {
    }, []);


    return (<form id={'form-user-details'}>
        <Modal.Body>
            <div className="smy-ssb">
                <div className="smy-block smy-block-md">
                    <div className="smy-titles">Email Address</div>
                    <div className="smy-desc">{data?.email ? data?.email : 'N/A'}</div>
                </div>


                <div className="smy-block smy-block-md">
                    <div className="smy-titles">Status</div>
                    {badgeToggle(data?.status, ['Active', 'Inactive'])}
                </div>
            </div>


            <div className="smy-block smy-block-md mb-3">
                <div className="smy-titles">Phone Number</div>
                <div className="smy-desc">{data?.phone}</div>
            </div>


            <div className="smy-ssb">
                <div className="smy-block smy-block-md">
                    <div className="smy-titles">Account Type</div>
                    {getUserType(data?.user_type)}
                    {data?.user_type === 'FRONT_DESK' && (<><br />
                        <span className='font-weight-bold text-primary'>
                            {data?.branch?.name} Branch
                        </span>
                    </>)}


                    {(data?.user_type === 'IT' || data?.user_type === 'IT_ADMIN') && (<div>
                        {checkArrayData(data?.departments) && data?.departments.map((dt) => <span
                            key={dt._id} className='font-weight-bold text-primary'>
                            {dt.name} Department{` `}
                        </span>)}


                        {(data?.user_type === 'IT') && (<div>
                            {checkArrayData(data?.assignments) && data?.assignments.map((dt, index) => <span
                                key={dt._id} className='font-weight-bold text-dark'>
                                {dt.name} {data.assignments.length !== (index + 1) && ', '}
                            </span>)}


                        </div>)}


                    </div>)}



                </div>


                <div className="smy-block smy-block-md"></div>
            </div>


        </Modal.Body>
        <Modal.Footer style={{ justifyContent: 'flex-start' }}>

            {userData?.user_type === "ADMIN" && (<>
                <button className="btn btn-success btn-main" type="button" onClick={() => onUserActionSubmit(data?._id, 'logout')}>Logout</button>
                <button className="btn btn-success btn-main" type="button"
                    onClick={() => onUserActionSubmit(data?._id, 'reset-password')}>
                    Reset Password</button>
                <button className="btn btn-success btn-main" type="button"
                    onClick={() => initSetPassword(data)}>
                    Set Password</button>
            </>)}
            <button className="btn btn-success btn-main" type="button" onClick={() => onUserActionSubmit(data?._id, 'toggle-status')}>{data?.status ? "Disable" : "Activate"}</button>
        </Modal.Footer>
    </form>);
}


export default UserDetails;