import * as React from "react";
import { Link, withRouter, useHistory } from "react-router-dom";
import AppContext from "../../context";
import usePageSetup from "../../hooks/usePageSetup";
import useRouter from "./../../hooks/useRouter";
import {
  branches as branchesSelect,
  branchAdd,
  branchEdit,
  branchDelete,
} from "../../services";
import { useQuery, useQueryClient, useMutation } from "react-query";
import Form from "./form";
import useFormState from "../../hooks/useFormState";
import { useForm } from "react-hook-form";
import useModal from "../../hooks/useModal";
import { Modal } from "react-bootstrap";

const Branches = ({ history }) => {
  const {
    dispatch,
    formatDate,
    handleCloseModal,
    pageTitle,
    setQueryString,
    errorResponse,
    checkArray,
    checkArrayData,
    badgeToggle,
    setStateData,
    formActionType,
    notify,
    userData,
  } = React.useContext(AppContext);
  const router = useRouter();

  const queryClient = useQueryClient();
  const [queryName] = React.useState("branches");
  const [query, setQuery] = React.useState(router.query);
  const [defaultValues, setDefaultValues] = React.useState({});
  const [formID] = React.useState("form-ticket-type");
  const { toggleFormState } = useFormState(formID);
  const forms = useForm({ defaultValues });

  const {
    modalState,
    setModalState,
    modalTitle,
    setModalTitle,
    closeModal,
    showModal,
  } = useModal();

  usePageSetup({
    breadcrumb: [{ to: null, label: pageTitle }],
  });

  /* Page data */
  const { isLoading, isFetching, isError, data, error } = useQuery(
    [queryName, query],
    () => branchesSelect(setQueryString(query)),
    {
      keepPreviousData: true,
      staleTime: 9000,
      onError: (error) => errorResponse({ error, history, dispatch }),
    }
  );

  const handleCloseFormModal = () => {
    //console.log('handleCloseFormModal...')
    closeModal();
    //setDefaultValues({});
    forms.reset();
  };

  /* Add data */
  const { mutate: handleSubmitAdd } = useMutation((value) => branchAdd(value), {
    onSuccess: () => {
      handleCloseModal();
      notify("success", "Data Added", "New data successfully created!");
    },
    onError: (error) => errorResponse({ error, dispatch, history, forms }),
    onSettled: (data, error, variables, context) => {
      queryClient.refetchQueries([queryName, query]);
      if (!error) handleCloseFormModal();
      toggleFormState(false);
    },
  });

  /* Edit data */
  const { mutate: handleSubmitEdit } = useMutation(
    ({ id, values }) => branchEdit({ id, values }),
    {
      onSuccess: () => {
        handleCloseModal();
        notify("success", "Data Modified", "Data successfully modified!");
      },
      onError: (error) => errorResponse({ error, dispatch, history, forms }),
      onSettled: (data, error, variables, context) => {
        queryClient.refetchQueries([queryName, query]);
        if (!error) handleCloseFormModal();
        toggleFormState(false);
      },
    }
  );

  /* Delete data */
  const { mutate: handleSubmitDelete } = useMutation((id) => branchDelete(id), {
    onSuccess: () => {
      notify("success", "Data Deleted", "Select data successfully deleted!");
    },
    onMutate: (id) => {
      queryClient.cancelQueries([queryName, query]);

      const previousData = queryClient.getQueryData([queryName, query]);
      const updateValue = previousData?.data;

      const removeDeleted = updateValue.filter((dt) => dt._id !== id);
      const newData = { ...previousData, data: removeDeleted };
      return queryClient.setQueryData([queryName, query], newData);
    },
    onError: (error) => errorResponse({ error, dispatch, history }),
    onSettled: () => queryClient.refetchQueries([queryName, query]),
  });

  const onSubmit = (data) => {
    toggleFormState(true, "saving...");
    //data = { ...data, user: userData._id }
    if (formActionType === 1) {
      handleSubmitAdd(data);
    } else {
      const { email, ...values } = data;
      handleSubmitEdit({ id: formActionType, values });
    }
  };

  const initAdd = () => {
    setStateData(dispatch, "formActionType", 1);
    setModalTitle("Create User Type");
    showModal();
  };

  const initEdit = ({ _id, name }) => {
    setStateData(dispatch, "formActionType", _id);
    setModalTitle("Edit Ticket Type");
    forms.setValue("name", name);
    showModal();
  };

  const initDelete = (id) => {
    const conf = window.confirm(
      "You are about to delete a data.. Are you sure?"
    );
    if (!conf) return;
    handleSubmitDelete(id);
  };

  return (
    <>
      {isLoading ? (
        <>loading, please wait...</>
      ) : (
        <>
          {checkArray(data?.data) && (
            <>
              <div className="row mb-3">
                <div className="col-sm-6"></div>
                <div className="col-sm-6 text-sm-right">
                  <button
                    type="button"
                    onClick={initAdd}
                    className="btn btn-success btn-rounded"
                  >
                    <i className="bx bx-user-plus align-middle"></i> Create
                    Ticket Type
                  </button>
                </div>
              </div>

              {!checkArrayData(data?.data) && <>No data found!</>}

              {checkArrayData(data?.data) && (
                <div className="table-responsive">
                  <table className="table table-centered table-nowrap">
                    <thead className="thead-light">
                      <tr>
                        <th width="1%">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="customCheck1"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customCheck1"
                            >
                              &nbsp;
                            </label>
                          </div>
                        </th>
                        <th width="80%">Name</th>
                        <th width="20%" className="text-right">
                          Created At
                        </th>
                        <th width="1%" className="text-center">
                          <i className="mdi mdi-dots-horizontal font-size-18"></i>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.data?.map((row, index) => {
                        return (
                          <tr>
                            <td>
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="customCheck2"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="customCheck2"
                                >
                                  &nbsp;
                                </label>
                              </div>
                            </td>
                            <td>{row?.name}</td>
                            <td className="text-right">
                              {formatDate(row?.createdAt, "lll")}
                            </td>

                            <td className="text-center">
                              <div className="dropdown">
                                <span
                                  className="dropdown-toggle card-drop"
                                  data-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i className="mdi mdi-dots-horizontal font-size-18"></i>
                                </span>
                                <ul className="dropdown-menu dropdown-menu-right">
                                  {userData?.user_type === "ADMIN" && (
                                    <>
                                      <li>
                                        <span
                                          className="dropdown-item"
                                          onClick={() => initEdit(row)}
                                        >
                                          <i className="fas fa-pencil-alt text-success mr-1"></i>{" "}
                                          Edit
                                        </span>
                                      </li>
                                      <li>
                                        <span
                                          className="dropdown-item"
                                          onClick={() => initDelete(row._id)}
                                        >
                                          <i className="fas fa-trash-alt text-danger mr-1"></i>{" "}
                                          Delete
                                        </span>
                                      </li>
                                    </>
                                  )}
                                </ul>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </>
          )}
        </>
      )}

      <Modal
        show={modalState}
        onHide={handleCloseFormModal}
        animation={false}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>

        <Form
          handleCloseModal={handleCloseFormModal}
          formID={formID}
          formActionType={formActionType}
          onSubmit={onSubmit}
          forms={forms}
        />
      </Modal>
    </>
  );
};

export default Branches;
