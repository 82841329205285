import * as React from "react";
import { Link, withRouter } from "react-router-dom";
import AppContext from "../../context";
import Chart from "react-apexcharts";
import {
  summary,
  ticket_type_summary,
  ticket_status_by_dates,
  recent_tickets,
  ticket_status_summary,
  create_ticket,
  tickets,
} from "../../services";
import { useQuery, useMutation, useQueryClient, queryCache } from "react-query";
import { DataPlacer, PaginateWrapper, Placeholder } from "../../components";
import moment from "moment";
import {
  PiCaretDownFill,
  PiCaretUpFill,
  PiCheckCircleFill,
  PiDotOutlineFill,
  PiDotsThreeOutlineVerticalFill,
  PiWarningFill,
} from "react-icons/pi";
import { FaLock, FaLockOpen } from "react-icons/fa";
// import { Tab, Tabs } from "react-bootstrap";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Button, Divider, Drawer } from "@mui/material";
import TicketForm from "./ticket";
import { Dropdown, Modal } from "react-bootstrap";
import useModal from "../../hooks/useModal";
import { useForm } from "react-hook-form";
import useFormState from "../../hooks/useFormState";
import useRouter from "../../hooks/useRouter";

const PolicyDetails = ({ history }) => {
  const {
    dispatch,
    numberFormat,
    errorResponse,
    checkArrayData,
    userData,
    policy360,
    formatDate,
    setStateData,
    formActionType,
    setTicket360,
    notify,
  } = React.useContext(AppContext);

  const router = useRouter();

  const queryClient = useQueryClient();
  const [value, setValue] = React.useState("1");
  const [defaultValues, setDefaultValues] = React.useState({});
  const [queryName, setQueryName] = React.useState("policyticket");
  const [expanded, setExpanded] = React.useState(false);
  const [active, setActive] = React.useState("");
  const [query, setQuery] = React.useState(router.query);
  const forms = useForm({ defaultValues });
  const [formID] = React.useState("form-ticket");
  const [ticketDetails, setTicketDetails] = React.useState({});
  const { toggleFormState: tfsTicket } = useFormState("form-ticket");
  const [isStatementModal, setIsStatementModal] = React.useState(false);

  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const anchor = "right"; // Set the anchor to 'right'

  const { isLoading, isFetching, isError, data, error } = useQuery(
    [queryName, query],
    () => tickets(`?policy_number=${policy360?.details?.polPolicyNo}`),
    {
      keepPreviousData: true,
      staleTime: 9000,
      onError: (error) => errorResponse({ error, history, dispatch }),
    }
  );

  function truncateTo50Characters(sentence) {
    if (sentence && sentence.length <= 40) {
      return sentence;
    } else {
      return `${sentence && sentence.slice(0, 40)}...`;
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const {
    modalState,
    setModalState,
    modalTitle,
    setModalTitle,
    closeModal,
    showModal,
  } = useModal();

  const { mutate: handleSubmitAdd } = useMutation(
    (value) => create_ticket(value),
    {
      onSuccess: () => {
        notify("success", "Data Added", "Ticket successfully created!");
        // history.push(`/tickets`);
      },
      onError: (error) => errorResponse({ error, dispatch, history, forms }),
      onSettled: async (data, error, variables, context) => {
        await queryClient.refetchQueries([queryName, query]);
        if (!error) handleCloseFormModal();
        tfsTicket(false);
      },
    }
  );

  /* handle paginate data */
  const handlePageClick = ({ selected }) => {
    const page = selected + 1;
    let nQ = { ...query, page };
    console.log(nQ);
    // setQuery(nQ);
    // history.push(`${currentPath}${setQueryString(nQ)}`);
  };

  const handleCloseFormModal = () => {
    //console.log('handleCloseFormModal...')
    closeModal();
    //setDefaultValues({});
    forms.reset();
  };
  const onSubmit = (data) => {
    tfsTicket(true, "saving...");
    handleSubmitAdd({ ...data });
  };

  const initEdit = (email, address, phone, name, policyNumber) => {
    setStateData(dispatch, "formActionType", "policyTicket");
    setModalTitle("Edit Ticket");
    //setDefaultValues(data)
    forms.setValue("customer_email", email);
    forms.setValue("customer_address", address);
    forms.setValue("customer_phone", phone);
    forms.setValue("customer_name", name);
    forms.setValue("policy_number", policyNumber);

    showModal();
  };

  return (
    <>
      <div className="row">
        <div className="col-12 policy-details">
          <div className="d-flex align-items-center justify-content-between">
            <div className="mb-3">
              <h5>{policy360?.details?.polPolicyNo}</h5>
              <p className="mb-1">{policy360?.details?.prodDesc}</p>
              <p className="mb-1">{policy360?.details?.productOptionCode}</p>
              <td className="text-success">
                {policy360?.details?.polStatusDesc}
              </td>
            </div>
            <div className="mb-4">
              <h5
                className="text-primary cursor-pointer"
                onClick={() => {
                  history.push("/customers/1");
                }}
              >
                {policy360?.details?.lifeAssured}
              </h5>
              <p className="mb-0">{policy360?.details?.prpTel}</p>
              <p className="mb-0">{policy360?.details?.prpEmail}</p>
              <p className="mb-0">#{policy360?.details?.prpCode}</p>
              <p className="mb-0">{policy360?.details?.prpPhysicalAddr}</p>
              <p
                className="text-primary cursor-pointer mt-2 mb-0"
                onClick={() => {
                  initEdit(
                    policy360?.details?.prpEmail,
                    policy360?.details?.prpPhysicalAddr,
                    policy360?.details?.prpTel,
                    policy360?.details?.lifeAssured,
                    policy360?.details?.polPolicyNo
                  );
                }}
              >
                <i className="bx bx-plus align-middle"></i> Create ticket
              </p>
              <p
                className="text-primary cursor-pointer mt-2 mb-0"
                onClick={toggleDrawer(anchor, true)}
              >
                <i className="bx bx-plus align-middle"></i> View payment history
              </p>
            </div>
          </div>

          {/* <Divider className="mb-4" /> */}

          <div className="">
            <Box sx={{ width: "100%", typography: "body1" }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                  >
                    <Tab
                      label="DETAILS"
                      value="1"
                      style={{ fontSize: "0.75rem" }}
                    />
                    <Tab
                      label="TICKETS"
                      value="2"
                      style={{ fontSize: "0.75rem" }}
                    />
                  </TabList>
                </Box>
                <TabPanel value="1" style={{ padding: 0, marginTop: "20px" }}>
                  <h5 className="mb-4">Policy details</h5>
                  <div className="row">
                    <div className="col-6 mb-3">
                      <p className="text-muted mb-2">Proposal number</p>
                      <h6 style={{ color: "#002C47" }}>
                        {policy360?.details?.polProposalNo}
                      </h6>
                    </div>
                    <div className="col-6 mb-3">
                      <p className="text-muted mb-2">Reference number</p>
                      <h6 style={{ color: "#002C47" }}>
                        {policy360?.details?.polRefNo}
                      </h6>
                    </div>{" "}
                    <div className="col-4 mb-3">
                      <p className="text-muted mb-2">Gross premium</p>
                      <h6 style={{ color: "#002C47" }}>
                        N{numberFormat(policy360?.details?.grossPremium)}
                      </h6>
                    </div>
                    <div className="col-4 mb-3">
                      <p className="text-muted mb-2">Total premium paid</p>
                      <h6 style={{ color: "#002C47" }}>
                        N{numberFormat(policy360?.details?.totPremiumPaid)}
                      </h6>
                    </div>
                    <div className="col-4 mb-3">
                      <p className="text-muted mb-2">Policy invoice balance</p>
                      <h6 style={{ color: "#002C47" }}>
                        {numberFormat(policy360?.details?.polInvBalance)}
                      </h6>
                    </div>
                    <div className="col-4 mb-3">
                      <p className="text-muted mb-2">
                        Policy outstanding balance
                      </p>
                      <h6 style={{ color: "#002C47" }}>
                        {numberFormat(policy360?.details?.polOsBalance)}
                      </h6>
                    </div>
                    <div className="col-4 mb-3">
                      <p className="text-muted mb-2">Effective date</p>
                      <h6 style={{ color: "#002C47" }}>
                        {policy360?.details?.polEffectiveDate}
                      </h6>
                    </div>
                    <div className="col-4 mb-3">
                      <p className="text-muted mb-2">Maturity date</p>
                      <h6 style={{ color: "#002C47" }}>
                        {policy360?.details?.polMaturityDate}
                      </h6>
                    </div>
                    <div className="col-4 mb-3">
                      <p className="text-muted mb-2">Policy paid to date</p>
                      <h6 style={{ color: "#002C47" }}>
                        {policy360?.details?.polPaidToDate}
                      </h6>
                    </div>
                    <div className="col-4 mb-3">
                      <p className="text-muted mb-2">
                        Policy underwriting year
                      </p>
                      <h6 style={{ color: "#002C47" }}>
                        {policy360?.details?.polUnderwritingYear}
                      </h6>
                    </div>
                    <div className="col-4 mb-3">
                      <p className="text-muted mb-2">Policy code</p>
                      <h6 style={{ color: "#002C47" }}>
                        {policy360?.details?.polCode}
                      </h6>
                    </div>
                    <div className="col-4 mb-3">
                      <p className="text-muted mb-2">Policy branch</p>
                      <h6 style={{ color: "#002C47" }}>
                        {policy360?.details?.polBranch}
                      </h6>
                    </div>
                    <div className="col-4 mb-3">
                      <p className="text-muted mb-2">Bank branch</p>
                      <h6 style={{ color: "#002C47" }}>
                        {policy360?.details?.bankBranch}
                      </h6>
                    </div>
                    <div className="col-4 mb-3">
                      <p className="text-muted mb-2">Bank name</p>
                      <h6 style={{ color: "#002C47" }}>
                        {policy360?.details?.bankName}
                      </h6>
                    </div>
                  </div>

                  <Divider className="mb-4 mt-4" />

                  <h5 className="mb-4">Payment details</h5>
                  <div className="row">
                    <div className="col-4 mb-3">
                      <p className="text-muted mb-2">Installment premium</p>
                      <h6 style={{ color: "#002C47" }}>
                        {numberFormat(
                          policy360?.payment_details?.installmentPremium
                        )}
                      </h6>
                    </div>
                    <div className="col-4 mb-3">
                      <p className="text-muted mb-2">Next installment amount</p>
                      <h6 style={{ color: "#002C47" }}>
                        {numberFormat(
                          policy360?.payment_details?.nextInstallAmt
                        )}
                      </h6>
                    </div>
                    <div className="col-4 mb-3">
                      <p className="text-muted mb-2">Next installment number</p>
                      <h6 style={{ color: "#002C47" }}>
                        {policy360?.payment_details?.nextInstallment}
                      </h6>
                    </div>
                    <div className="col-4 mb-3">
                      <p className="text-muted mb-2">
                        Policy outstanding installments
                      </p>
                      <h6 style={{ color: "#002C47" }}>
                        {policy360?.payment_details?.polOsInstallment}
                      </h6>
                    </div>
                    <div className="col-4 mb-3">
                      <p className="text-muted mb-2">
                        Number of paid installments
                      </p>
                      <h6 style={{ color: "#002C47" }}>
                        {policy360?.payment_details?.polPaidInstallmentNo}
                      </h6>
                    </div>
                    <div className="col-4 mb-3">
                      <p className="text-muted mb-2">Policy paid to date</p>
                      <h6 style={{ color: "#002C47" }}>
                        {policy360?.payment_details?.polPaidToDate}
                      </h6>
                    </div>
                    <div className="col-4 mb-3">
                      <p className="text-muted mb-2">
                        Policy total installments
                      </p>
                      <h6 style={{ color: "#002C47" }}>
                        {policy360?.payment_details?.polTotalInstallment}
                      </h6>
                    </div>
                    <div className="col-4 mb-3">
                      <p className="text-muted mb-2">Total balances</p>
                      <h6 style={{ color: "#002C47" }}>
                        {policy360?.payment_details?.totalBalances}
                      </h6>
                    </div>
                  </div>

                  <p className="text-primary cursor-pointer">Send statement</p>
                  <Divider className="mb-4 mt-4" />
                  <h5 className="mb-4">Beneficiaries</h5>

                  {policy360?.beneficiaries?.map((beneficiary) => {
                    return (
                      <div className="row">
                        <div className="col-4 mb-3">
                          <p className="text-muted mb-2">Beneficiary name</p>
                          <h6 style={{ color: "#002C47" }}>
                            {beneficiary.benFirstName}
                          </h6>
                        </div>
                        <div className="col-4 mb-3">
                          <p className="text-muted mb-2">Phone</p>
                          <h6 style={{ color: "#002C47" }}>
                            {beneficiary.benTelNo}
                          </h6>
                        </div>
                        <div className="col-4 mb-3">
                          <p className="text-muted mb-2">Birth date</p>
                          <h6 style={{ color: "#002C47" }}>
                            {beneficiary.benDob}
                          </h6>
                        </div>

                        {beneficiary.benGuardianEmailAddrs !== null && (
                          <div className="col-6 mb-3">
                            <p className="text-muted mb-2">Guardian Email</p>
                            <h6 style={{ color: "#002C47" }}>
                              {beneficiary.benGuardianEmailAddrs}
                            </h6>
                          </div>
                        )}
                      </div>
                    );
                  })}

                  <Divider className="mb-4 mt-4" />

                  <h5 className="mb-4">Dependants</h5>

                  {policy360?.dependants?.map((dependant) => {
                    return (
                      <div className="row">
                        <div className="col-4 mb-3">
                          <p className="text-muted mb-2">Dependant name</p>
                          <h6 style={{ color: "#002C47" }}>
                            {dependant.podSurname} {dependant.podOtherNames}
                          </h6>
                        </div>
                        <div className="col-4 mb-3">
                          <p className="text-muted mb-2">Birth date</p>
                          <h6 style={{ color: "#002C47" }}>
                            {dependant.podDob}
                          </h6>
                        </div>
                        <div className="col-4 mb-3">
                          <p className="text-muted mb-2">Gender</p>
                          <h6 style={{ color: "#002C47" }}>
                            {dependant.podSex}
                          </h6>
                        </div>
                        <div className="col-4 mb-3">
                          <p className="text-muted mb-2">Cover description</p>
                          <h6 style={{ color: "#002C47" }}>
                            {dependant.coverTypeDescription}
                          </h6>
                        </div>
                        <div className="col-4 mb-3">
                          <p className="text-muted mb-2">DTY Description</p>
                          <h6 style={{ color: "#002C47" }}>
                            {dependant.dtyDescription}
                          </h6>
                        </div>
                        <div className="col-4 mb-3">
                          <p className="text-muted mb-2">Premium</p>
                          <h6 style={{ color: "#002C47" }}>
                            N{numberFormat(dependant.premium)}
                          </h6>
                        </div>
                        <div className="col-4 mb-3">
                          <p className="text-muted mb-2">Sum Assured</p>
                          <h6 style={{ color: "#002C47" }}>
                            N{numberFormat(dependant.sum_assured)}
                          </h6>
                        </div>
                      </div>
                    );
                  })}

                  <Divider className="mb-4 mt-4" />

                  <h5 className="mb-4">Agent details</h5>
                  <div className="row">
                    <div className="col-4 mb-3">
                      <p className="text-muted mb-2">Name</p>
                      <h6 style={{ color: "#002C47" }}>
                        {policy360?.details?.polAgentName}
                      </h6>
                    </div>
                    <div className="col-4 mb-3">
                      <p className="text-muted mb-2">Agent code</p>
                      <h6 style={{ color: "#002C47" }}>
                        {policy360?.details?.polAgentCode}
                      </h6>
                    </div>
                    <div className="col-4 mb-3">
                      <p className="text-muted mb-2">Agent short description</p>
                      <h6 style={{ color: "#002C47" }}>
                        {policy360?.details?.polAgentShortDescription}
                      </h6>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value="2" style={{ padding: 0, marginTop: "20px" }}>
                  {isLoading ? (
                    <>loading, please wait</>
                  ) : (
                    <>
                      {!checkArrayData(data?.data) && <>No ticket found!</>}

                      {checkArrayData(data?.data) && (
                        <div className="table-responsive">
                          <table className="table table-centered table-hover  table-nowrap">
                            <thead className="thead-light">
                              <tr>
                                {/* <th width="1%">
                                  <div className="custom-control custom-checkbox">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id="customCheck1"
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="customCheck1"
                                    >
                                      &nbsp;
                                    </label>
                                  </div>
                                </th> */}
                                <th width="11%">Ticket Details</th>
                                <th width="20%">Customer Details</th>
                                <th width="20%">Subject</th>
                                {/* <th width="22%">Body</th> */}
                                <th width="12%">Category</th>
                                <th width="12%" className="text-right">
                                  Assgn. to
                                </th>
                                <th width="12%" className="text-right">
                                  Status
                                </th>
                                <th width="12%" className="text-right">
                                  Created
                                </th>
                                <th width="1%" className="text-center">
                                  <i className="mdi mdi-dots-horizontal font-size-18"></i>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {data?.data?.map((row, index) => {
                                const newDate = new Date(
                                  row?.createdAt
                                ).toLocaleDateString();

                                return (
                                  <tr
                                    key={row.id}
                                    onClick={() => {
                                      setTicket360(row?.id);
                                      sessionStorage.setItem("nnn", row?.id);
                                      history.push(
                                        `/tickets/details/${row?.ticketNumber}`
                                      );
                                    }}
                                    className="cursor-pointer"
                                  >
                                    {/* <td>
                                      <div className="custom-control custom-checkbox">
                                        <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          id="customCheck2"
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="customCheck2"
                                        >
                                          &nbsp;
                                        </label>
                                      </div>
                                    </td> */}
                                    <td>
                                      <span
                                        // onClick={() => initDetails(row)}
                                        className="text-body font-weight-bold cursor-pointer"
                                      >
                                        {row?.ticketNumber}
                                      </span>
                                      <br />
                                      <span className="text-muted">
                                        {row?.ticketType?.ticketTypeName}
                                      </span>
                                    </td>
                                    <td>
                                      <span
                                        // onClick={() => initDetails(row)}
                                        className="text-body font-weight-bold cursor-pointer"
                                      >
                                        {row?.customer?.customerName}
                                      </span>
                                      <br />
                                      <span className="text-muted">
                                        {row?.customer?.customerEmail}
                                      </span>
                                    </td>
                                    <td>
                                      {truncateTo50Characters(
                                        row?.ticketSubject
                                      )}
                                    </td>
                                    <td>
                                      <span
                                        // onClick={() => initDetails(row)}
                                        className="text-body font-weight-bold cursor-pointer"
                                      >
                                        {
                                          row?.ticketCategory
                                            ?.ticketCategoryName
                                        }
                                      </span>
                                      <br />
                                      <span className="text-muted">
                                        {
                                          row?.ticketSubcategory
                                            ?.ticketSubCategoryName
                                        }
                                      </span>
                                    </td>

                                    <td className="text-right">
                                      <span
                                        // onClick={() => initDetails(row)}
                                        className="text-body font-weight-bold cursor-pointer "
                                      >
                                        {row?.assignee?.fullname}
                                      </span>
                                      <br />
                                      <span className="text-muted">
                                        {row?.assignee?.role?.roleName}
                                      </span>
                                    </td>

                                    <td className="text-right">
                                      {row?.currentStatus?.ticketStatus}
                                    </td>

                                    <td className="text-right">{newDate}</td>

                                    <td className="cursor-pointer">
                                      <Dropdown>
                                        <Dropdown.Toggle
                                          variant="custom"
                                          className="p-0"
                                          style={{ margin: 0, border: 0 }}
                                        >
                                          <PiDotsThreeOutlineVerticalFill
                                          // onClick={() => handleClick(id)}
                                          />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                          <Dropdown.Item
                                            onClick={() => {
                                              history.push(
                                                `/tickets/details/${row?.ticketNumber}`
                                              );
                                            }}
                                          >
                                            View details
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            onClick={() => {
                                              initEdit(row);
                                            }}
                                          >
                                            Update status
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </td>
                                    {/* <td className="text-center">
                              <div className="dropdown">
                                <span
                                  className="dropdown-toggle card-drop"
                                  data-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i className="mdi mdi-dots-horizontal font-size-18"></i>
                                </span>
                                <ul className="dropdown-menu dropdown-menu-right">
                                  <li>
                                    <span
                                      className="dropdown-item"
                                      onClick={() => initEdit(row)}
                                    >
                                      <i className="fas fa-pencil-alt text-success mr-1"></i>{" "}
                                      Edit
                                    </span>
                                    {userData?.user_type === "ADMIN" && (
                                      <>
                                        <span
                                          className="dropdown-item"
                                          onClick={() => initDelete(row.id)}
                                        >
                                          <i className="fas fa-trash-alt text-danger mr-1"></i>{" "}
                                          Delete
                                        </span>

                                        <span
                                          className="dropdown-item"
                                          onClick={() => initSetPassword(row)}
                                        >
                                          <i className="fas fa-lock text-danger mr-1"></i>{" "}
                                          Set Password
                                        </span>
                                      </>
                                    )}
                                  </li>
                                </ul>
                              </div>
                            </td> */}
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                          <div>
                            <PaginateWrapper
                              data={data}
                              onPageChange={handlePageClick}
                            />
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </TabPanel>
              </TabContext>
            </Box>
          </div>

          {/* <h5 className="mb-4">Policy details</h5>
          <div className="row">
            <div className="col-6 mb-3">
              <p className="text-muted mb-2">Proposal number</p>
              <h6 style={{ color: "#002C47" }}>
                {policy360?.details?.polProposalNo}
              </h6>
            </div>
            <div className="col-6 mb-3">
              <p className="text-muted mb-2">Reference number</p>
              <h6 style={{ color: "#002C47" }}>
                {policy360?.details?.polRefNo}
              </h6>
            </div>{" "}
            <div className="col-4 mb-3">
              <p className="text-muted mb-2">Gross premium</p>
              <h6 style={{ color: "#002C47" }}>
                N{numberFormat(policy360?.details?.grossPremium)}
              </h6>
            </div>
            <div className="col-4 mb-3">
              <p className="text-muted mb-2">Total premium paid</p>
              <h6 style={{ color: "#002C47" }}>
                N{numberFormat(policy360?.details?.totPremiumPaid)}
              </h6>
            </div>
            <div className="col-4 mb-3">
              <p className="text-muted mb-2">Policy invoice balance</p>
              <h6 style={{ color: "#002C47" }}>
                {numberFormat(policy360?.details?.polInvBalance)}
              </h6>
            </div>
            <div className="col-4 mb-3">
              <p className="text-muted mb-2">Policy outstanding balance</p>
              <h6 style={{ color: "#002C47" }}>
                {numberFormat(policy360?.details?.polOsBalance)}
              </h6>
            </div>
            <div className="col-4 mb-3">
              <p className="text-muted mb-2">Effective date</p>
              <h6 style={{ color: "#002C47" }}>
                {policy360?.details?.polEffectiveDate}
              </h6>
            </div>
            <div className="col-4 mb-3">
              <p className="text-muted mb-2">Maturity date</p>
              <h6 style={{ color: "#002C47" }}>
                {policy360?.details?.polMaturityDate}
              </h6>
            </div>
            <div className="col-4 mb-3">
              <p className="text-muted mb-2">Policy paid to date</p>
              <h6 style={{ color: "#002C47" }}>
                {policy360?.details?.polPaidToDate}
              </h6>
            </div>
            <div className="col-4 mb-3">
              <p className="text-muted mb-2">Policy underwriting year</p>
              <h6 style={{ color: "#002C47" }}>
                {policy360?.details?.polUnderwritingYear}
              </h6>
            </div>
            <div className="col-4 mb-3">
              <p className="text-muted mb-2">Policy code</p>
              <h6 style={{ color: "#002C47" }}>
                {policy360?.details?.polCode}
              </h6>
            </div>
            <div className="col-4 mb-3">
              <p className="text-muted mb-2">Policy branch</p>
              <h6 style={{ color: "#002C47" }}>
                {policy360?.details?.polBranch}
              </h6>
            </div>
            <div className="col-4 mb-3">
              <p className="text-muted mb-2">Bank branch</p>
              <h6 style={{ color: "#002C47" }}>
                {policy360?.details?.bankBranch}
              </h6>
            </div>
            <div className="col-4 mb-3">
              <p className="text-muted mb-2">Bank name</p>
              <h6 style={{ color: "#002C47" }}>
                {policy360?.details?.bankName}
              </h6>
            </div>
          </div> */}

          {/* <Divider className="mb-4 mt-4" />

          <h5 className="mb-4">Payment details</h5>
          <div className="row">
            <div className="col-4 mb-3">
              <p className="text-muted mb-2">Installment premium</p>
              <h6 style={{ color: "#002C47" }}>
                {numberFormat(policy360?.payment_details?.installmentPremium)}
              </h6>
            </div>
            <div className="col-4 mb-3">
              <p className="text-muted mb-2">Next installment amount</p>
              <h6 style={{ color: "#002C47" }}>
                {numberFormat(policy360?.payment_details?.nextInstallAmt)}
              </h6>
            </div>
            <div className="col-4 mb-3">
              <p className="text-muted mb-2">Next installment number</p>
              <h6 style={{ color: "#002C47" }}>
                {policy360?.payment_details?.nextInstallment}
              </h6>
            </div>
            <div className="col-4 mb-3">
              <p className="text-muted mb-2">Policy outstanding installments</p>
              <h6 style={{ color: "#002C47" }}>
                {policy360?.payment_details?.polOsInstallment}
              </h6>
            </div>
            <div className="col-4 mb-3">
              <p className="text-muted mb-2">Number of paid installments</p>
              <h6 style={{ color: "#002C47" }}>
                {policy360?.payment_details?.polPaidInstallmentNo}
              </h6>
            </div>
            <div className="col-4 mb-3">
              <p className="text-muted mb-2">Policy paid to date</p>
              <h6 style={{ color: "#002C47" }}>
                {policy360?.payment_details?.polPaidToDate}
              </h6>
            </div>
            <div className="col-4 mb-3">
              <p className="text-muted mb-2">Policy total installments</p>
              <h6 style={{ color: "#002C47" }}>
                {policy360?.payment_details?.polTotalInstallment}
              </h6>
            </div>
            <div className="col-4 mb-3">
              <p className="text-muted mb-2">Total balances</p>
              <h6 style={{ color: "#002C47" }}>
                {policy360?.payment_details?.totalBalances}
              </h6>
            </div>
          </div>

          <p className="text-primary cursor-pointer">Send statement</p>
          <Divider className="mb-4 mt-4" />
          <h5 className="mb-4">Beneficiaries</h5>

          {policy360?.beneficiaries?.map((beneficiary) => {
            return (
              <div className="row">
                <div className="col-4 mb-3">
                  <p className="text-muted mb-2">Beneficiary name</p>
                  <h6 style={{ color: "#002C47" }}>
                    {beneficiary.benFirstName}
                  </h6>
                </div>
                <div className="col-4 mb-3">
                  <p className="text-muted mb-2">Phone</p>
                  <h6 style={{ color: "#002C47" }}>{beneficiary.benTelNo}</h6>
                </div>
                <div className="col-4 mb-3">
                  <p className="text-muted mb-2">Birth date</p>
                  <h6 style={{ color: "#002C47" }}>{beneficiary.benDob}</h6>
                </div>

                {beneficiary.benGuardianEmailAddrs !== null && (
                  <div className="col-6 mb-3">
                    <p className="text-muted mb-2">Guardian Email</p>
                    <h6 style={{ color: "#002C47" }}>
                      {beneficiary.benGuardianEmailAddrs}
                    </h6>
                  </div>
                )}
              </div>
            );
          })}

          <Divider className="mb-4 mt-4" />

          <h5 className="mb-4">Dependants</h5>

          {policy360?.dependants?.map((dependant) => {
            return (
              <div className="row">
                <div className="col-4 mb-3">
                  <p className="text-muted mb-2">Dependant name</p>
                  <h6 style={{ color: "#002C47" }}>
                    {dependant.podSurname} {dependant.podOtherNames}
                  </h6>
                </div>
                <div className="col-4 mb-3">
                  <p className="text-muted mb-2">Birth date</p>
                  <h6 style={{ color: "#002C47" }}>{dependant.podDob}</h6>
                </div>
                <div className="col-4 mb-3">
                  <p className="text-muted mb-2">Gender</p>
                  <h6 style={{ color: "#002C47" }}>{dependant.podSex}</h6>
                </div>
                <div className="col-4 mb-3">
                  <p className="text-muted mb-2">Cover description</p>
                  <h6 style={{ color: "#002C47" }}>
                    {dependant.coverTypeDescription}
                  </h6>
                </div>
                <div className="col-4 mb-3">
                  <p className="text-muted mb-2">DTY Description</p>
                  <h6 style={{ color: "#002C47" }}>
                    {dependant.dtyDescription}
                  </h6>
                </div>
                <div className="col-4 mb-3">
                  <p className="text-muted mb-2">Premium</p>
                  <h6 style={{ color: "#002C47" }}>
                    N{numberFormat(dependant.premium)}
                  </h6>
                </div>
                <div className="col-4 mb-3">
                  <p className="text-muted mb-2">Sum Assured</p>
                  <h6 style={{ color: "#002C47" }}>
                    N{numberFormat(dependant.sum_assured)}
                  </h6>
                </div>
              </div>
            );
          })}

          <Divider className="mb-4 mt-4" />

          <h5 className="mb-4">Agent details</h5>
          <div className="row">
            <div className="col-4 mb-3">
              <p className="text-muted mb-2">Name</p>
              <h6 style={{ color: "#002C47" }}>
                {policy360?.details?.polAgentName}
              </h6>
            </div>
            <div className="col-4 mb-3">
              <p className="text-muted mb-2">Agent code</p>
              <h6 style={{ color: "#002C47" }}>
                {policy360?.details?.polAgentCode}
              </h6>
            </div>
            <div className="col-4 mb-3">
              <p className="text-muted mb-2">Agent short description</p>
              <h6 style={{ color: "#002C47" }}>
                {policy360?.details?.polAgentShortDescription}
              </h6>
            </div>
          </div> */}
        </div>
        {/* <div className="col-4  policy-details">
          <h6>Premium payment history</h6>
          <Divider className="mb-3 mt-3" />
          <div className="row">
            {policy360?.receipts?.map((receipt) => {
              return (
                <div className="col-12">
                  <div className="row">
                    <h6 className="col-4">
                      N{numberFormat(receipt.oprAmount)}
                    </h6>
                    <div className="col-8 text-right">
                      <p className="mb-1"> {receipt.oprReceiptNo}</p>
                      <p className="mb-1">
                        {formatDate(receipt.oprReceiptDate)}
                      </p>
                      <p
                        className={
                          receipt.oprDrCr === `CREDIT`
                            ? "mb-1 text-success"
                            : "mb-1 text-danger"
                        }
                        style={{ fontSize: "0.6rem" }}
                      >
                        {receipt.oprDrCr}
                      </p>
                    </div>
                  </div>

                  <Divider className="mb-3 mt-3 " />
                </div>
              );
            })}
          </div>
        </div> */}
      </div>
      <Modal
        show={modalState}
        onHide={handleCloseFormModal}
        backdrop="static"
        keyboard={false}
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>

        <TicketForm
          handleCloseModal={handleCloseFormModal}
          formID={formID}
          formActionType={formActionType}
          onSubmit={onSubmit}
          forms={forms}
          history={history}
          setModalState={setModalState}
        />
      </Modal>

      <Modal
        show={isStatementModal}
        onHide={() => {
          setIsStatementModal(false);
        }}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{`You would be sending a statement to ${policy360?.details?.prpEmail}`}</p>
        </Modal.Body>
        <Modal.Footer>
          <input
            type="button"
            onClick={() => {
              setIsStatementModal(false);
            }}
            className="btn btn-default"
            value="Cancel"
          />
          <button className="btn btn-primary btn-main" type="submit">
            Proceed
          </button>
        </Modal.Footer>
      </Modal>

      <div>
        <Drawer
          anchor={anchor}
          open={state[anchor]}
          onClose={toggleDrawer(anchor, false)}
        >
          <Box
            sx={{
              width: anchor === "top" || anchor === "bottom" ? "auto" : 450,
            }}
            role="presentation"
            className="p-4"
          >
            <div className="row">
              <h6 className="col-6">Premium payment history</h6>
              <p
                className="text-primary cursor-pointer col-6 text-right mb-0"
                onClick={() => {
                  toggleDrawer(anchor, false);
                  setIsStatementModal(true);
                  setState({ right: false });
                }}
              >
                Send full statement <i className="bx bx-send align-middle"></i>
              </p>
            </div>

            <Divider className="mb-3 mt-3" />
            <div className="row">
              {policy360?.receipts?.map((receipt) => {
                return (
                  <div className="col-12">
                    <div className="row">
                      <div className="col-4">
                        <h6>N{numberFormat(receipt.oprAmount)}</h6>
                        <p className="text-primary underline cursor-pointer">
                          Send alert
                        </p>
                      </div>

                      <div className="col-8 text-right">
                        <p className="mb-1"> {receipt.oprReceiptNo}</p>
                        <p className="mb-1">
                          {formatDate(receipt.oprReceiptDate)}
                        </p>
                        <p
                          className={
                            receipt.oprDrCr === `CREDIT`
                              ? "mb-1 text-success"
                              : "mb-1 text-danger"
                          }
                          style={{ fontSize: "0.6rem" }}
                        >
                          {receipt.oprDrCr}
                        </p>
                      </div>
                    </div>

                    <Divider className="mb-3 mt-3 " />
                  </div>
                );
              })}
            </div>
          </Box>
        </Drawer>
      </div>
    </>
  );
};

export default PolicyDetails;
