import * as React from "react";
import { Link, withRouter } from "react-router-dom";
import AppContext from "../context";
import { Button, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { ErrorMsg } from "../components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import ReactExport from "react-export-excel";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { searchPolicyByPhoneNum, searchPolicyByPolicyNum } from "../services";
import useFormState from "../hooks/useFormState";
// import { useHistory } from "react-router-dom";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Search = ({
  defaultValue,
  query,
  handleCloseModal,
  onSearchSubmit,
  isExport,
  onExportSubmit,
  resetExport,
  exporting,
  exportData,
  exportReady,
  tfsSearch,
  history,
  closeModalSearch,
}) => {
  const {
    userData,
    checkObjectData,
    userTypes,
    checkArrayData,
    formatDate,
    getUserType,
    notify,
    errorResponse,
    dispatch,
    policy360,
    setPolicy360,
    phonePolicies,
    setPhonePolicies,
  } = React.useContext(AppContext);
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    forms,
  } = useForm({ defaultValue });

  const [formID] = React.useState("form-search");
  const { toggleFormState } = useFormState(formID);
  const [selectedPolicyNo, setSelectedPolicyNo] = React.useState(null);
  const [fetchingPolicyInfoFor, setFetchingPolicyInfoFor] = React.useState();

  const { mutate: handlePolicyNumSearch } = useMutation(
    (data) => searchPolicyByPolicyNum(data),
    {
      onSuccess: (res) => {
        if (res?.message !== "Policy not found") {
          notify("success", "Success", "Policy fetched successfully!");
          setPolicy360(res?.data);
          history.push(`/policy-details/${res?.data?.details?.polPolicyNo}`);
          console.log(res);
          closeModalSearch();
          return;
        }
        notify("danger", "No Policy", res?.message);
      },
      onError: (error) => errorResponse({ error, dispatch, history, forms }),
      onSettled: async (data, error, variables, context) => {
        console.log(error, "error....");
        // await queryClient.refetchQueries([queryName, query]);
        //   if (!error) closeModalSearch();ss
        toggleFormState(false);
      },
    }
  );

  const { mutate: handlePhoneNumSearch } = useMutation(
    (data) => searchPolicyByPhoneNum(data),
    {
      onSuccess: (res) => {
        if (res?.message !== "No policy found") {
          notify("success", "Success", "Policies fetched successfully!");
          // setPolicy360(res?.data);
          // history.push(`/policy-details`);
          console.log(res);
          // closeModalSearch();
          setPhonePolicies(res);
          return;
        }
        // setPhonePolicies(res?.code);
        notify("danger", "No Policy", res?.message);
      },
      onError: (error) => errorResponse({ error, dispatch, history, forms }),
      onSettled: async (data, error, variables, context) => {
        console.log(error, "error....");
        // await queryClient.refetchQueries([queryName, query]);
        //   if (!error) closeModalSearch();ss
        toggleFormState(false);
      },
    }
  );

  // const handleSubmits = (data) => {
  //   toggleFormState(true, "Searching...");
  //   handlePolicyNumSearch({ ...data });
  // };

  const handleSubmits = (data) => {
    toggleFormState(true, "Searching...");

    console.log(data);

    const containsLettersAndNumbers = /[a-zA-Z]/.test(
      Object.values(data).join("")
    );
    const containsOnlyNumbers = /^[0-9]+$/.test(Object.values(data).join(""));

    if (containsLettersAndNumbers) {
      handlePolicyNumSearch({ ...data });
    } else if (containsOnlyNumbers) {
      handlePhoneNumSearch({ ...data });
    }
  };

  const handlePolicyNoClick = (policyNo) => {
    handlePolicyNumSearch({ data: policyNo });
  };

  return (
    <form id={"form-search"} onSubmit={handleSubmit(handleSubmits)}>
      {!exportReady && (
        <Modal.Body>
          <div className="row">
            <div className="form-group col-12">
              <input
                type="text"
                className="form-control"
                {...register("data")}
                placeholder="Enter policy number / phone number"
              />
            </div>
          </div>
          <div>
            {phonePolicies?.length > 0 && (
              <>
                <h5 className="mb-3">Policies Found</h5>
                {phonePolicies?.map((policy) => {
                  return (
                    <div
                      className="col-6 p-3 border cursor-pointer"
                      onClick={() => {
                        setFetchingPolicyInfoFor(policy?.POLICYNO);
                        handlePolicyNoClick(policy?.POLICYNO);
                      }}
                    >
                      <h6 className="text-primary">{policy?.POLICYNO}</h6>
                      <p className="mb-0">{policy?.POL_REF_NO}</p>
                      {fetchingPolicyInfoFor === policy?.POLICYNO && (
                        <p className="mb-0 mt-3">
                          <span
                            class="spinner-border spinner-border-sm mr-1 text-primary"
                            role="status"
                          ></span>
                          Getting policy 360...
                        </p>
                      )}
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </Modal.Body>
      )}
      <Modal.Footer>
        <input
          type="button"
          onClick={handleCloseModal}
          className="btn btn-default"
          value="Close"
        />
        <button className="btn btn-success btn-main" type="submit">
          <i className="bx bx-search-alt search-icon font-size-16 align-middle"></i>{" "}
          Search
        </button>
      </Modal.Footer>
    </form>
  );
};

export default Search;
