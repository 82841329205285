import * as React from "react";
import axios from "axios";
import { baseurl } from "../config/config";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.timeout = process.env.REACT_APP_TIMEOUT;
//axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post["Accept"] = "application/json";
//axios.defaults.headers.common.accept = 'application/json';

axios.interceptors.request.use(
  async (config) => {
    const token =
      localStorage.getItem(process.env.REACT_APP_USER_TOKEN) || null;
    if (token) {
      //config.headers['Authorization'] = `Bearer 209|DL7gNO4fJGRqGxjRCb9aJmyuIM6ZtkTPIYibn7gB`;
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const axiosJson = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}static/`,
  timeout: process.env.REACT_APP_TIMEOUT,
  crossdomain: true,
  mode: "no-cors",
  credentials: "same-origin",
  preflightContinue: true,
  headers: {
    //'Content-Type': 'application/json',
    Accept: "application/json",
    //'Access-Control-Allow-Origin': true,
    "Access-Control-Allow-Origin": "*",
  },
});

const instance = axios.create({
  baseURL: "http://staging-upgrade.fbninsurance.co/api/",
  timeout: process.env.REACT_APP_TIMEOUT,
  crossdomain: true,
  //mode: 'no-cors',
  //withCredentials: true,
  //credentials: 'same-origin',
  headers: {
    Authorization: "Bearer 209|DL7gNO4fJGRqGxjRCb9aJmyuIM6ZtkTPIYibn7gB",
    "Access-Control-Allow-Origin": true,
    "Content-Type": "application/json",
  },
});

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export async function user_types() {
  const { data } = await axios.get(`statics/user-types`);
  return data;
}

export async function login(values) {
  const { data } = await axios.post(`user/auth`, values);
  return data;
}

export async function user() {
  const { data } = await axios.get(`user`);
  return data;
}

export async function users_it() {
  const { data } = await axios.get(`user/it`);
  return data;
}

export async function forgotPassword(values) {
  const { data } = await axios.post(`user/forgot-password`, values);
  return data;
}

export async function resetPasswordValidate(values) {
  const { data } = await axios.post(`user/validate-reset-token`, values);
  return data;
}

export async function createPassword(values) {
  const { data } = await axios.post(`user/create-password`, values);
  return data;
}

export async function resetPassword(values) {
  const { data } = await axios.post(`user/reset-password`, values);
  return data;
}

export async function get_settings() {
  const { data } = await axios.get(`settings`);
  return data;
}

export async function updateSettings(values) {
  const { data } = await axios.put(`settings`, values);
  return data;
}

/* USER */
export async function userUpdate(values) {
  const { data } = await axios.post(`profile/update`, values);
  return data;
}

export const tickets = async (query) => {
  const { data } = await axios.get(`tickets/${query}`);
  return data;
};
export const ticket_statuses = async () => {
  const { data } = await axios.get(`ticket-status`);
  return data;
};
export const channels = async () => {
  const { data } = await axios.get(`channels`);
  return data;
};
export const units = async () => {
  const { data } = await axios.get(`units`);
  return data;
};
export const create_ticket = async (values) => {
  const { data } = await axios.post(`tickets`, values);
  return data;
};
export const assign_ticket = async () => {
  const { data } = await axios.post(`tickets/user-assign-tickets`);
  return data;
};
export const ticket_categories = async () => {
  const { data } = await axios.get(`ticket-categories`);
  return data;
};
export const ticket_sub_categories = async () => {
  const { data } = await axios.get(`ticket-sub-categories`);
  return data;
};
export const ticket_types = async () => {
  const { data } = await axios.get(`ticket-types`);
  return data;
};
// export const ticket_details = async (query) => {
//   const { data } = await axios.get(`tickets/details/${query}`);
//   return data;
// };
export const update_ticket_status = async ({ id, values }) => {
  const { data } = await axios.put(`tickets/update-status/${id}`, values);
  return data;
};

export const closeTicket = async (id, comment) => {
  const { data } = await axios.put(`tickets/update-status/${id}`, {
    status_id: 6,
    comment: comment,
  });
  return data;
};

export const forwardTicket = async (id, unit) => {
  const { data } = await axios.post(`tickets/forward-ticket`, {
    ticket_id: id,
    unit_key: unit,
  });
  return data;
};

export const updateTicket = async (id, tid, comment) => {
  const { data } = await axios.put(`tickets/update-status/${tid}`, {
    status_id: id,
    comment: comment,
  });
  return data;
};

export const addAttachment = async (tid, values) => {
  const { data } = await axios.put(
    `tickets/update-status/upload-attachment/${tid}`,
    values
  );
  return data;
};

export const ticket_policy = async (values) => {
  const { data } = await axios.post(`tickets/policy`, values);
  return data;
};

export const ticket_unassigned = async () => {
  const { data } = await axios.get(`tickets/unassigned-count`);
  return data;
};

export const get_assignments = async () => {
  const { data } = await axios.get(`assignments`);
  return data;
};

export const benefitRequest = async (values) => {
  const { data } = await axios.post(`tickets`, values);
  return data;
};

export const updateStatus = async (values) => {
  const { data } = await axios.post(`tickets/update-status`, values);
  return data;
};

export const assignTickets = async (values) => {
  const { data } = await axios.post(`tickets/assign`, values);
  return data;
};

export const reassignTickets = async (values) => {
  const { data } = await axios.post(`tickets/reassign`, values);
  return data;
};

export const deleteTicket = async ({ id, values }) => {
  //console.log({ id, values }, '{ id, values }____')
  const { data } = await axios.put(`tickets/truncate/${id}`, values);
  return data;
};

export const ticket_details = async (id) => {
  const { data } = await axios.get(`tickets/details/${id}`);
  return data;
};

/* Users management */
// export const users = async (query) => {
//   const { data } = await axios.get(`user/users${query}`);
//   return data;
// };

export const users = async () => {
  const { data } = await axios.get(`users`);
  return data;
};

export const userAdd = async (values) => {
  const { data } = await axios.post(`users`, values);
  return data;
};

export const userUpload = async (values) => {
  const { data } = await axios.post(`user/upload`, values);
  return data;
};

export const userEdit = async ({ id, values }) => {
  const { data } = await axios.put(`users/${id}`, values);
  return data;
};

export const userSetPassword = async ({ id, values }) => {
  //console.log(id, values, 'values...')
  const { data } = await axios.put(`user/set-password/${id}`, values);
  return data;
};

export const userDelete = async (id) => {
  const { data } = await axios.delete(`user/${id}`);
  return data;
};

export const user_actions = async (values) => {
  const { data } = await axios.post(`user/actions`, values);
  return data;
};

export const claims = async (policy_number) => {
  const { data } = await axios.get(`policies/claims/${policy_number}`);
  return data;
};

export const claim_details = async (claim_number) => {
  const { data } = await axios.get(`policies/claim/${claim_number}`);
  return data;
};

export const policy_details = async (policy_number) => {
  //await sleep(1000);
  //const { data } = await Promise.resolve(policy__details);
  const { data } = await axios.get(`policies/policy/${policy_number}`);
  return data;
};

/* export const uploadFile = async (values) => {
  const { data } = await axios.post(`tickets/upload-file`, values);
  return data;
} */

export const uploadFile = async (values) => {
  const { data } = await axios.post(
    `http://staging-upgrade.fbninsurance.co/api/file-upload`,
    values
  );
  return data;
};

/* Reporting */
export const summary = async () => {
  const { data } = await axios.get(`reports/summary`);
  return data;
};

export const ticket_type_summary = async () => {
  const { data } = await axios.get(`reports/ticket-type-summary`);
  return data;
};

export const ticket_status_summary = async () => {
  const { data } = await axios.get(`reports/ticket-status-summary`);
  return data;
};

export const ticket_status_by_dates = async () => {
  const { data } = await axios.get(`reports/ticket-status-by-dates`);
  return data;
};

export const recent_tickets = async () => {
  const { data } = await axios.get(`reports/recent-tickets`);
  return data;
};

/* Schedules */
export const schedules = async (query) => {
  const { data } = await axios.get(`schedules${query}`);
  return data;
};

export const schedule_list = async (session, query) => {
  const { data } = await axios.get(`schedules/${session}${query}`);
  return data;
};

export const scheduleAdd = async (values) => {
  const { data } = await axios.post(`schedules`, values);
  return data;
};

export const scheduleDelete = async (id) => {
  const { data } = await axios.delete(`schedules/${id}`);
  return data;
};

export const scheduleRerun = async (values) => {
  const { data } = await axios.post(`schedules/rerun`, values);
  return data;
};

/* Ticket Categories */
export const ticketCategories = async () => {
  const { data } = await axios.get(`ticket-categories`);
  return data;
};

export const ticketCategoryAdd = async (values) => {
  const { data } = await axios.post(`ticket-categories`, values);
  return data;
};

export const ticketCategoryEdit = async ({ id, values }) => {
  const { data } = await axios.put(`ticket-categories/${id}`, values);
  return data;
};

export const ticketCategoryDelete = async (id) => {
  const { data } = await axios.delete(`ticket-categories/${id}`);
  return data;
};

/* Ticket Types */
export const ticketTypes = async () => {
  const { data } = await axios.get(`ticket-types`);
  return data;
};

export const ticketTypeAdd = async (values) => {
  const { data } = await axios.post(`ticket-types`, values);
  return data;
};

export const ticketTypeEdit = async ({ id, values }) => {
  const { data } = await axios.put(`ticket-types/${id}`, values);
  return data;
};

export const ticketTypeDelete = async (id) => {
  const { data } = await axios.delete(`ticket-types/${id}`);
  return data;
};

/* Departments */
export const departments = async () => {
  const { data } = await axios.get(`departments`);
  return data;
};

export const departmentAdd = async (values) => {
  const { data } = await axios.post(`departments`, values);
  return data;
};

export const departmentEdit = async ({ id, values }) => {
  console.log({ id, values }, "{ id, values }_____");
  const { data } = await axios.put(`departments/${id}`, values);
  return data;
};

export const departmentDelete = async (id) => {
  const { data } = await axios.delete(`departments/${id}`);
  return data;
};

/* Branches */
export const branches = async () => {
  const { data } = await axios.get(`branches`);
  return data;
};

export const branchAdd = async (values) => {
  const { data } = await axios.post(`branches`, values);
  return data;
};

export const branchEdit = async ({ id, values }) => {
  const { data } = await axios.put(`branches/${id}`, values);
  return data;
};

export const branchDelete = async (id) => {
  const { data } = await axios.delete(`branches/${id}`);
  return data;
};

export const bootstraps = async () => {
  let [
    user_data,
    companies_data,
    branches_data,
    roles_data,
    ticket_types_data,
    ticket_statuses_data,
    ticket_categories_data,
    ticket_sub_categories_data,
    channels_data,
    departments_data,
    units_data,
    team_mates_data,
  ] = await axios.all([
    user(),
    companies(),
    branches(),
    roles(),
    ticket_types(),
    ticket_statuses(),
    ticket_categories(),
    ticket_sub_categories(),
    channels(),
    departments(),
    units(),
    team_mates(),
  ]);
  return {
    user: user_data,
    companies: companies_data,
    branches: branches_data,
    roles: roles_data,
    ticketTypes: ticket_types_data,
    ticketStatuses: ticket_statuses_data,
    ticketCategories: ticket_categories_data,
    ticketSubCategories: ticket_sub_categories_data,
    channels: channels_data,
    departments: departments_data,
    units: units_data,
    team_mates: team_mates_data,
  };
};

/* Notifications */
export const get_notifications = async (query) => {
  const { data } = await axios.get(`notifications${query}`);
  return data;
};

export const notifications_delete = async (id) => {
  const { data } = await axios.delete(`notifications/${id}`);
  return data;
};

/* Logs */
export const get_logs = async (query) => {
  const { data } = await axios.get(`logs${query}`);
  return data;
};

/* Reports */
export const report_current_status = async (query) => {
  const { data } = await axios.get(`reports/current-status${query}`);
  return data;
};

export const report_current_status_history = async (query) => {
  const { data } = await axios.get(`reports/current-status-history${query}`);
  return data;
};

export const assignee_summary = async (query) => {
  const { data } = await axios.get(`reports/assignee-summary${query}`);
  return data;
};

export const report_status_history = async (query) => {
  const { data } = await axios.get(`reports/status-history${query}`);
  return data;
};

/* Ticket Status options */
export const status_options = async () => {
  const { data } = await axios.get(`ticket-status-options`);
  return data;
};

export const status_options_add = async (values) => {
  const { data } = await axios.post(`ticket-status-options`, values);
  return data;
};

export const status_options_edit = async ({ id, values }) => {
  const { data } = await axios.put(`ticket-status-options/${id}`, values);
  return data;
};

export const status_options_delete = async (id) => {
  const { data } = await axios.delete(`ticket-status-options/${id}`);
  return data;
};

export const adAuth = async () => {
  const { data } = await axios.get(
    `user/auth/365/request?redirect_uri=https://crm360.fbninsurance.co/login`
  );
  return data;
};

export const adValidate = async (code) => {
  const { data } = await axios.post(`user/auth/365/validate`, code);
  return data;
};

// ROLES
export const roles = async () => {
  const { data } = await axios.get(`roles`);
  return data;
};

// COMPANIES
export const companies = async () => {
  const { data } = await axios.get(`companies`);
  return data;
};

export const team_mates = async () => {
  const { data } = await axios.get(`users/list`);
  return data;
};

//SEARCH
export const searchPolicyByPolicyNum = async (param) => {
  const { data } = await axios.get(`policies/policy/${param.data}`);
  return data;
};

export const searchPolicyByPhoneNum = async (param) => {
  const { data } = await axios.get(`policies/policy/phone/${param.data}`);
  return data;
};

//NOTE

export const addNote = async (values) => {
  const { data } = await axios.put(
    `tickets/update-status/note/${values.ticket_id}`,
    values
  );
  return data;
};

export const reassign_ticket = async (values) => {
  const { data } = await axios.put(
    `tickets/update-status/${values.assignee_id}`,
    values
  );
  return data;
};

export const reassign_ticket_user = async (values) => {
  const { data } = await axios.post(
    `tickets/reassign-ticket`,
    values
  );
  return data;
};

export const getNotes = async (id) => {
  const { data } = await axios.get(`tickets/notes/${id}`);
  return data;
};

// mail

export async function replyMail(id, values) {
  const { data } = await axios.put(`tickets/update-status/reply/${id}`, values);
  return data;
}

export async function sendMail(id, values) {
  const { data } = await axios.put(
    `tickets/update-status/send-email/${id}`,
    values
  );
  return data;
}
