import * as React from "react";

import NotFound from "./pages/not-found";
import Login from "./pages/login";
import Dashboard from "./pages/dashboard";
import Branches from "./pages/branches";
import Tickets from "./pages/tickets";
import Customers from "./pages/customers";
import Customer from "./pages/customer";
import Users from "./pages/users";
import PolicyDetails from "./pages/policyDetails";
import Customer360 from "./pages/customer360";
import TicketDetails from "./pages/tickets/details";
import Auth from "./pages/auth";

const routes = [
  {
    id: 1,
    path: "/login",
    component: Login,
    auth: false,
    exact: true,
    route: true,
    pageTitle: "Login",
    for: ["ALL"],
  },
  {
    id: 5,
    path: "/branches",
    component: Branches,
    auth: true,
    exact: true,
    route: true,
    pageTitle: "Branches",
    for: ["ALL"],
  },
  {
    id: 99,
    path: "/",
    component: Dashboard,
    title: "Dashboard",
    auth: true,
    exact: true,
    route: true,
    pageTitle: "Dashboard",
    for: ["ALL"],
  },
  {
    id: 7,
    path: "/tickets",
    component: Tickets,
    title: "Tickets",
    auth: true,
    exact: true,
    route: true,
    pageTitle: "Tickets",
    for: ["ALL"],
  },
  {
    id: 8,
    path: "/customers",
    component: Customers,
    title: "Customers",
    auth: true,
    exact: true,
    route: true,
    pageTitle: "Customers",
    for: ["ALL"],
  },
  {
    id: 9,
    path: "/customers/:id",
    component: Customer,
    title: "Customer",
    auth: true,
    exact: true,
    route: true,
    pageTitle: "Customer",
    for: ["ALL"],
  },
  {
    id: 10,
    path: "/users",
    component: Users,
    title: "Users",
    auth: true,
    exact: true,
    route: true,
    pageTitle: "Users",
    for: ["ALL"],
  },
  {
    id: 11,
    path: "/policy-details/:policyNumber",
    component: PolicyDetails,
    title: "Policy Details",
    auth: true,
    exact: true,
    route: true,
    pageTitle: "Policy Details",
    for: ["ALL"],
  },
  {
    id: 12,
    path: "/customer360",
    component: Customer360,
    title: "Customer 360",
    auth: true,
    exact: true,
    route: true,
    pageTitle: "Customer 360",
    for: ["ALL"],
  },
  {
    id: 12,
    path: "/tickets/details/:ticketId",
    component: TicketDetails,
    title: "Ticket Details",
    auth: true,
    exact: true,
    route: true,
    pageTitle: "Ticket Details",
    for: ["ALL"],
  },
  {
    id: 13,
    path: "/auth",
    component: Auth,
    title: "Auth",
    auth: true,
    exact: true,
    route: true,
    pageTitle: "Auth",
    for: ["ALL"],
  },
  {
    id: 404,
    path: "/*",
    component: NotFound,
    exact: false,
    route: true,
    pageTitle: "Page Not Found!",
    menu: { status: false },
    for: ["ALL"],
  },
];

export default routes;
