import * as React from 'react';

const authActions = {
    setUserData: (dispatch, userToken, userData, userTypes) => {
        //localStorage.setItem(process.env.REACT_APP_USER_DATA, JSON.stringify(userData));
        localStorage.setItem(process.env.REACT_APP_USER_TOKEN, userToken);
        dispatch({ type: 'LOGIN', userToken, userData, userTypes });
    },
    logoutUser: (dispatch, history) => {
        //localStorage.removeItem(process.env.REACT_APP_USER_DATA);
        localStorage.removeItem(process.env.REACT_APP_USER_TOKEN);
        dispatch({ type: 'LOGOUT' });
        history.push('/login');
    },
    updateUserData: (dispatch, userData) => {
        //localStorage.setItem(process.env.REACT_APP_USER_DATA, JSON.stringify(userData));
        dispatch({ type: 'UPDATE_USER_DATA', userData });
    },
    nameInitials: (data) => {
        const name = data?.name ? (data?.name).split(' ') : '';
        const fname = name[0] ? (name[0]).substr(0, 1) : '';
        const lname = name[1] ? (name[1]).substr(0, 1) : '';
        return `${fname}${lname}`;

    }
}

export default authActions;