import * as React from "react";
import AppContext from "../../context";
import { Modal } from "react-bootstrap";
import { ErrorMsg } from "../../components";

export default function CustomerForm({
  handleCloseModal,
  onSubmit,
  formID,
  forms,
}) {
  const { dispatch } = React.useContext(AppContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = forms;

  return (
    <>
      <form id={formID} onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="row">
            <div className="form-group col-6">
              <label>Full Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="John Doe"
                {...register("name", {
                  required: "Full name is required",
                })}
              />
              <ErrorMsg errors={errors} name="name" />
            </div>
            <div className="form-group col-6">
              <label>Phone</label>
              <input
                type="text"
                className="form-control"
                placeholder="07067896171"
                {...register("phone", {
                  required: "Phone number is required",
                })}
              />
              <ErrorMsg errors={errors} name="phone" />
            </div>
            <div className="form-group col-12">
              <label>Email Address</label>
              <input
                type="text"
                className="form-control"
                placeholder="samuelefe.37@gmail.com"
                {...register("email", {
                  required: "Email address is required",
                })}
              />
              <ErrorMsg errors={errors} name="email" />
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <input
            type="button"
            onClick={handleCloseModal}
            className="btn btn-default"
            value="Cancel"
          />
          <button className="btn btn-primary btn-main" type="submit">
            Save
          </button>
        </Modal.Footer>
      </form>
    </>
  );
}
