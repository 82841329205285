import * as React from "react";
import { ErrorMessage } from "@hookform/error-message";
import { Link, NavLink } from "react-router-dom";
import ReactPaginate from "react-paginate";
import NumberFormat from "react-number-format";
import loaderSvg from "./loader.svg";
import warningGraySvg from "./warning-gray.svg";
import errorSvg from "./error.svg";

export const ErrorMsg = ({ errors, name }) => {
  return (
    <ErrorMessage
      errors={errors}
      name={name}
      render={({ message }) => (
        <p
          style={{ fontWeight: 600 }}
          className="text-danger font-size-13 mb-0 mt-1"
        >
          {message}
        </p>
      )}
    />
  );
};

export const Breadcrumb = ({ data }) => {
  return (
    <ol className="breadcrumb">
      {data ? (
        <li className="breadcrumb-item">
          <NavLink exact to="/">
            Dashboard
          </NavLink>
        </li>
      ) : (
        <li className="breadcrumb-item active">Dashboard</li>
      )}

      {data &&
        Array.isArray(data) &&
        data.length > 0 &&
        data.map((dt, index) => {
          if (data.length === index + 1) {
            return (
              <li key={index} className="breadcrumb-item active">
                {dt?.label}
              </li>
            );
          } else {
            return (
              <li key={index} className="breadcrumb-item">
                <Link to={dt?.to}>{dt?.label}</Link>
              </li>
            );
          }
        })}
    </ol>
  );
};

export function Currency({ value }) {
  return (
    <NumberFormat
      value={value}
      displayType={"text"}
      thousandSeparator={true}
      decimalScale={2}
      fixedDecimalScale={true}
      renderText={(value) => <>&#8358;{value}</>}
    />
  );
}

export function NumFormat({ value }) {
  return (
    <NumberFormat value={value} displayType={"text"} thousandSeparator={true} />
  );
}

export const Paginate = ({ data, onPageChange }) => {
  return (
    <ReactPaginate
      pageCount={data?.totalPages}
      //initialPage={data?.page - 1}
      forcePage={data?.page - 1}
      pageRangeDisplayed={4}
      marginPagesDisplayed={2}
      containerClassName="pagination"
      previousLabel="Previous"
      previousClassName="page-item"
      previousLinkClassName="page-link"
      nextLabel="Next"
      nextClassName="page-item"
      nextLinkClassName="page-link"
      activeClassName="active"
      pageClassName="page-item"
      pageLinkClassName="page-link"
      disabledClassName="disabled"
      onPageChange={onPageChange}
      disableInitialCallback={true}
    />
  );
};

export const PaginateWrapper = ({ data, onPageChange }) => {
  return (
    <div className="row pagination-box">
      <div className="col-md-5">
        Showing <span>{data?.from}</span> - <span>{data?.to}</span> of{" "}
        <span>{data?.total}</span>
      </div>
      <div className="col-md-7">
        <Paginate data={data} onPageChange={onPageChange} />
      </div>
    </div>
  );
};

export const Btn = ({
  type = "button",
  variant = "primary",
  className,
  ...rest
}) => {
  return (
    <button
      className={`btn btn-${variant} btn-block waves-effect waves-light`}
      type={type}
      {...rest}
    >
      Log In
    </button>
  );
};

export const disableForms = (id, status) => {
  var form = document.getElementById(id);
  var allElements = form.elements;
  for (var i = 0, l = allElements.length; i < l; ++i) {
    // allElements[i].readOnly = true;
    if (status) allElements[i].disabled = true;
    else allElements[i].disabled = false;
  }
};

export function randomString(length = 10) {
  let result = "";
  const characters = "abcdefghijklmnopqrstuvwxyz";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function Placeholder({
  text = <>loading, please wait...</>,
  type = null,
  typeText = null,
  btn = null,
  showIco = true,
}) {
  let icon, type_text;
  switch (type) {
    case "error":
      icon = errorSvg;
      type_text = typeText || "There seems to be an error, please retry!";
      break;
    case "empty":
      icon = warningGraySvg;
      type_text = typeText || "No result found!";
      break;
    default:
  }

  return (
    <div className="placeholder-block">
      {type ? (
        <>
          {showIco && (
            <div>
              <img src={icon} width="50" />
            </div>
          )}
          <div className="mt-1">{type_text}</div>
          {btn && <div className="mt-1">{btn}</div>}
        </>
      ) : (
        <>
          {showIco && (
            <div>
              <img src={loaderSvg} width="50" />
            </div>
          )}
          <div className="mt-1">{text}</div>
        </>
      )}
    </div>
  );
}

export const DataPlacer = ({
  loading,
  data,
  loadingText = "please wait..",
  extract,
}) => {
  return (
    <>
      {loading ? (
        <>{loadingText}</>
      ) : (
        <>{data ? <>{extract ? data[extract] : data}</> : <>...</>}</>
      )}
    </>
  );
};
