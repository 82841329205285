import * as React from "react";
import commonActions from "./commonActions";

const errorResponseActions = {
  errorResponse: ({ error, history = null, dispatch = null }) => {
    let errorHeader, errMsg, errCode;

    if (error?.response?.status === undefined) {
      if (error?.message === "Network Error") {
        if (!navigator.onLine) {
          errorHeader = "Connectivity Error";
          errMsg = "No internet connectivity, please check and try again!";
          errCode = `ERR_G1000`;
        } else {
          errorHeader = "Error Notification";
          errMsg = "Can not process request, please retry or reload page!";
          errCode = `ERR_G1002`;
        }
      } else if (error?.code === "ECONNABORTED") {
        errorHeader = "Error Notification!";
        errMsg = "Can not process request, please retry.";
        errCode = `ERR_T29`;
      } else {
        errorHeader = "Error Notification!";
        errMsg = "Can not process request, please retry.";
        errCode = `ERR_S31`;
      }

      return { errorHeader, errMsg, errCode };
    }

    const errors = error?.response?.data;
    const status = error?.response?.status;
    const { message } = errors;
    switch (status) {
      case 0:
        if (error.message === "Network Error") {
          if (!navigator.onLine) {
            errorHeader = "Connectivity Error";
            errMsg =
              "No connectivity, please check your internet and try again!";
            errCode = `ERR_G1003`;
          } else {
            errorHeader = "Error Notification";
            errMsg = "An error occurred, please retry!";
            errCode = `ERR_G1004`;
          }
        }
      case 401:
      case 403:
        //localStorage.removeItem(process.env.REACT_APP_USER_DATA);
        // localStorage.removeItem(process.env.REACT_APP_USER_TOKEN);
        // localStorage.removeItem(process.env.REACT_APP_USER_TOKEN_EXP);
        // dispatch({ type: 'LOGOUT' });
        history.replace("/");
        commonActions.notify("danger", "Authentication Error", "Please login!");
        break;

      case 404:
      case 405:
      case 400:
      case 500:
        errorHeader = "Error Notification";
        errMsg = "An error occurred, please retry!";
        errCode = status;
      default:
        errorHeader = "Error Notification";
        errMsg = "An error occurred, please retry!";
        errCode = `ERR_D5100D`;
    }

    return { errorHeader, errMsg, errCode };
  },
};

export default errorResponseActions;
