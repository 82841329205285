import React from "react";

const Auth = () => {
  return (
    <div>
      <h1>LOADING...</h1>
    </div>
  );
};

export default Auth;
