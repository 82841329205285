import { useState, useEffect } from 'react';

const useFormState = (id) => {
    const form = document.getElementById(id);
    const btn = document.querySelector(`#${id} .btn-main`);
    const [initBtnText, setInitBtnText] = useState(null);
    const [iel] = useState('<span className="btn-loading-icon"><i className="bx bx-spin dripicons-loading"></i></span>');

    const [formState, setFormState] = useState(null);
    const [loadingText, setLoadingText] = useState(null);

    const toggleFormState = (state, loadingText = null) => {
        setFormState(state);
        if (loadingText) setLoadingText(loadingText);
    }

    const toggleDisableForm = async (form, formState) => {
        if (btn) {
            const vvBtn = await Promise.resolve(btn);
            if (vvBtn) {
                if (formState === true) {
                    btn.disabled = true;
                } else {
                    btn.disabled = false;
                }
            }
        }

        const allElements = form?.elements;
        if (allElements && allElements.length > 0) {
            for (let i = 0, l = allElements.length; i < l; ++i) {

                if (formState === true) {
                    allElements[i].disabled = true;
                    //btn.disabled = true;
                } else {
                    allElements[i].disabled = false;
                    //btn.disabled = false;
                }
            }
        }
    }

    useEffect(() => {
        (async () => {
            if ((formState === true || formState === false) && form /* && btn */) {

                toggleDisableForm(form, formState);

                if (btn) {
                    if (formState === true) {
                        const currentBtnText = await Promise.resolve(btn.innerHTML);
                        setInitBtnText(currentBtnText);
                    }

                    if (formState === true) {
                        if (!btn.classList.contains('btn-block')) {
                            btn.classList.add('btn-loading-icon-pad');
                        }

                        if (loadingText) {
                            btn.innerHTML = `${loadingText} ${iel}`
                        } else {
                            btn.insertAdjacentHTML('beforeend', iel);
                        }

                    } else {
                        if (!btn.classList.contains('btn-block')) {
                            btn.classList.remove('btn-loading-icon-pad');
                        }

                        if (loadingText) {
                            btn.innerHTML = initBtnText;
                        } else {
                            const rel = document.querySelector(`#${id} .btn-main .btn-loading-icon`);
                            if (rel) rel.remove();
                        }
                    }
                }
            }
        })()

        return () => {
            toggleDisableForm(form, false);
        }
    }, [formState, form, btn, loadingText]);

    return { toggleFormState, formState };
};

export default useFormState;