import * as React from "react";
import AppContext from "../../../context";
import { InputGroup, Modal, Button, Form } from "react-bootstrap";
import { ErrorMsg } from "../../../components";
import { searchPolicyByPolicyNum } from "../../../services";
import { useMutation } from "react-query";
import useFormState from "../../../hooks/useFormState";

export default function TicketForm({
  handleCloseModal,
  onSubmit,
  formID,
  forms,
  history,
  setModalState,
}) {
  const {
    dispatch,
    ticketTypesData,
    ticketCategoriesData,
    setStateData,
    ticketSubCategoriesData,
    notify,
    errorResponse,
  } = React.useContext(AppContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = forms;
  const { toggleFormState } = useFormState(formID);
  const [searchPolicy, setSearchPolicy] = React.useState("");
  const [selectedCategory, setSelectedCategory] = React.useState('')

  const initTry = (
    id,
    name,
    email,
    phone,
    address,
    bankName,
    accountNumber,
    accountName,
    policyNumber
  ) => {
    setModalState(true);
    setStateData(dispatch, "formActionType", id);
    // setModalTitle("try");
    //setDefaultValues(data)
    forms.setValue("customer_name", name);
    forms.setValue("customer_email", email);
    forms.setValue("customer_phone", phone);
    forms.setValue("customer_address", address);
    forms.setValue("customer_account_bank_name", bankName);
    forms.setValue("customer_account_number", accountNumber);
    forms.setValue("customer_account_name", accountName);
    forms.setValue("policy_number", policyNumber);
  };
  const [isCustomer, setIsCustomer] = React.useState(false);
  const [finding, setFinding] = React.useState(false);
  const { mutate: handlePolicyNumSearch } = useMutation(
    (data) => searchPolicyByPolicyNum(data),
    {
      onSuccess: (res) => {
        if (res?.message !== "Policy not found") {
          notify("success", "Success", "Policy found!");
          initTry(
            100,
            res?.data?.details?.lifeAssured,
            res?.data?.details?.prpEmail,
            res?.data?.details?.prpTel,
            res?.data?.details?.prpPhysicalAddr,
            res?.data?.details?.bankName,
            res?.data?.details?.endrBankAccNo,
            res?.data?.details?.endrBankAccName,
            res?.data?.details?.polPolicyNo
          );
          console.log(res);
          return;
        }
        notify("danger", "No Policy", res?.message);
      },
      onError: (error) => errorResponse({ error, dispatch, history, forms }),
      onSettled: async (data, error, variables, context) => {
        console.log(error, "error....");
        toggleFormState(false);
        setFinding(false);
      },
    }
  );

  const handleFind = (data) => {
    setFinding(true);
    handlePolicyNumSearch(data);
  };

  return (
    <>
      <form id={formID} onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="row">
            <div className="form-group col-12">
              <label>Policy number</label>
              <InputGroup>
                <Form.Control
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                  // value={searchPolicy}
                  onChange={(e) => setSearchPolicy({ data: e.target.value })}
                />
                <Button
                  className="text-white bg-primary"
                  onClick={() => {
                    handleFind(searchPolicy);
                  }}
                  style={{ borderRadius: "0px" }}
                >
                  {finding ? (
                    <span
                      class="spinner-border spinner-border-sm mr-1 text-white"
                      role="status"
                    ></span>
                  ) : (
                    "Find"
                  )}
                </Button>
              </InputGroup>
              <ErrorMsg errors={errors} name="name" />
            </div>
            <div className="form-group col-8">
              <label>Customer name</label>
              <input
                type="text"
                className="form-control"
                {...register("customer_name", {
                  // required: "Customer Name is required",
                })}
              />
              <ErrorMsg errors={errors} name="customer_name" />
            </div>
            <div className="form-group col-4">
              <label>Customer phone</label>
              <input
                type="text"
                className="form-control"
                {...register("customer_phone", {
                  // required: "Phone number is required",
                })}
              />
              <ErrorMsg errors={errors} name="customer_phone" />
            </div>
            <div className="form-group col-8">
              <label>Customer email</label>
              <input
                type="text"
                className="form-control"
                {...register("customer_email", {
                  // required: "Customer email is required",
                })}
              />
              <ErrorMsg errors={errors} name="customer_email" />
            </div>

            <div className="form-group col-4">
              <label>Policy number</label>
              <input
                type="text"
                className="form-control"
                {...register("policy_number", {
                  // required: "Policy number is required",
                })}
              />
              <ErrorMsg errors={errors} name="policy_number" />
            </div>

            {/* <div className="form-group col-12">
              <label>Customer address</label>
              <input
                type="text"
                className="form-control"
                {...register("customer_address", {
                  // required: "Address is required",
                })}
              />
              <ErrorMsg errors={errors} name="customer_address" />
            </div> */}
            {/* <div className="form-group col-4">
              <label>Account bank name</label>
              <input
                type="text"
                className="form-control"
                {...register("customer_account_bank_name", {
                  required: "Bank name is required",
                })}
              />
              <ErrorMsg errors={errors} name="customer_account_bank_name" />
            </div>
            <div className="form-group col-4">
              <label>Account number</label>
              <input
                type="text"
                className="form-control"
                {...register("customer_account_number", {
                  required: "Account number is required",
                })}
              />
              <ErrorMsg errors={errors} name="customer_account_number" />
            </div>

            <div className="form-group col-4">
              <label>Account name</label>
              <input
                type="text"
                className="form-control"
                {...register("customer_account_name", {
                  required: "Account name is required",
                })}
              />
              <ErrorMsg errors={errors} name="customer_account_name" />
            </div> */}

            <div className="form-group col-6">
              <label>Ticket type</label>
              <select
                type="text"
                className="form-control"
                placeholder="Admin"
                {...register("ticket_type", {
                  required: "Company is required!",
                })}
              >
                <option></option>
                {ticketTypesData.map((type) => (
                  <option key={type.id} value={type.id}>
                    {type.ticketTypeName}
                  </option>
                ))}
              </select>

              <ErrorMsg errors={errors} name="ticket_type" />
            </div>

            <div className="form-group col-6">
              <label>Ticket category</label>
              <select
                type="text"
                className="form-control"
                placeholder="Admin"
                {...register("ticket_category", {
                  required: "Category is required!",
                })}
                onChange={(e)=>{
                  // console.log('e ', e)
                  setSelectedCategory(e.target.value)
                }}
              >
                <option></option>
                {ticketCategoriesData
                  .sort(function (a, b) {
                    if (a.ticketCategoryName < b.ticketCategoryName) {
                      return -1;
                    }
                    if (a.ticketCategoryName > b.ticketCategoryName) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.ticketCategoryName}
                    </option>
                  ))}
              </select>

              <ErrorMsg errors={errors} name="ticket_category" />
            </div>

            <div className="form-group col-12">
              <label>Ticket subcategory</label>
              <select
                type="text"
                className="form-control"
                placeholder="Admin"
                {...register("ticket_sub_category", {
                  required: "Sub category is required!",
                })}
              >
                <option></option>
                {ticketSubCategoriesData
                .filter((category)=> category?.ticketCategoryId == selectedCategory)
                  .sort(function (a, b) {
                    if (a.ticketSubCategoryName < b.ticketSubCategoryName) {
                      return -1;
                    }
                    if (a.ticketSubCategoryName > b.ticketSubCategoryName) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((sub) => (
                    <option key={sub.id} value={sub.id}>
                      {sub.ticketSubCategoryName}
                    </option>
                  ))}
              </select>

              <ErrorMsg errors={errors} name="ticket_sub_category" />
            </div>

            {/* <div className="form-group col-6">
              <label>Ticket subject</label>
              <input
                type="text"
                className="form-control"
                {...register("ticket_subject", {
                  required: "Subject is required",
                })}
              />
              <ErrorMsg errors={errors} name="ticket_subject" />
            </div> */}
            {/* <div className="form-group col-12">
              <label>Ticket body</label>
              <textarea
                rows={5}
                type="text"
                className="form-control"
                {...register("ticket_body", {
                  required: "A body is required",
                })}
              />
              <ErrorMsg errors={errors} name="ticket_body" />
            </div> */}

            {/* <div className="form-group col-4">
              <label>is customer</label>
              <input
                type="text"
                className="form-control"
                {...register("is_customer", {
                  required: "is customer is required",
                })}
              />
              <ErrorMsg errors={errors} name="is_customer" />
            </div> */}

            {/* <div className="form-group col-12">
              <label>Ticket summary</label>
              <textarea className="form-control" as="textarea" rows={3} />
              <ErrorMsg errors={errors} name="name" />
            </div> */}
            {/* <div
              className="w-100 p-4 rounded d-flex align-items-center justify-content-center cursor-pointer"
              style={{
                border: "1px dashed gray",
                marginLeft: "12px",
                marginRight: "12px",
              }}
            >
              <p className="m-0 text-primary underline">Upload any file here</p>
            </div> */}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <input
            type="button"
            onClick={handleCloseModal}
            className="btn btn-default"
            value="Cancel"
          />
          <button className="btn btn-primary btn-main" type="submit">
            Save
          </button>
        </Modal.Footer>
      </form>
    </>
  );
}
