import * as React from "react";
import { Link, withRouter } from "react-router-dom";
import AppContext from "../../context";
import Chart from "react-apexcharts";
import {
  summary,
  ticket_type_summary,
  ticket_status_by_dates,
  recent_tickets,
  ticket_status_summary,
} from "../../services";
import { useQuery, useMutation, useQueryClient, queryCache } from "react-query";
import { DataPlacer, Placeholder } from "../../components";
import moment from "moment";
import {
  PiCaretDownFill,
  PiCaretUpFill,
  PiCheckCircleFill,
  PiDotOutlineFill,
  PiWarningFill,
} from "react-icons/pi";
import { FaLock, FaLockOpen } from "react-icons/fa";
// import { Tab, Tabs } from "react-bootstrap";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

const Customer = ({ history }) => {
  const { dispatch, numberFormat, errorResponse, checkArrayData, userData } =
    React.useContext(AppContext);
  const [value, setValue] = React.useState("1");
  const [expanded, setExpanded] = React.useState(false);
  const [active, setActive] = React.useState("");

  const policies = [
    {
      id: 1,
      number: "CFLN10277",
      name: "FLEXIBLE CASHFLOW PLAN",
      status: "Surrendered",
    },
    {
      id: 2,
      number: "EDUN12341",
      name: "FLEXI EDU PLAN",
      status: "Active",
    },
    {
      id: 3,
      number: "CFLN10277",
      name: "FLEXIBLE CASHFLOW PLAN",
      status: "Lapsed",
    },
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="row">
      <div className="col-8">
        <div
          className="d-flex align-items-start w-100"
          //   style={{ height: "98px" }}
        >
          <div className="d-flex align-items-center flex-row justify-content-center">
            <div
              className="h-100 rounded d-flex align-items-center justify-content-center flex-column bg-primary"
              style={{ padding: "35px" }}
            >
              <h1 className="m-0 text-white">ES</h1>
            </div>
            <div className="d-flex align-items-start pl-2 flex-column text-left h-100">
              <h4 className="m-1">Efe Samuel</h4>
              <p className="m-1 text-muted">07067896171</p>
              <p className="m-1 text-muted">samuelefe.37@gmail.com</p>
            </div>
          </div>
        </div>

        <div className="mt-4">
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab
                    label="POLICIES"
                    value="1"
                    style={{ fontSize: "0.75rem" }}
                  />
                  <Tab
                    label="TIMELINE"
                    value="2"
                    style={{ fontSize: "0.75rem" }}
                  />
                  <Tab
                    label="TICKETS"
                    value="3"
                    style={{ fontSize: "0.75rem" }}
                  />
                  <Tab
                    label="DV REQUESTS"
                    value="4"
                    style={{ fontSize: "0.75rem" }}
                  />
                  <Tab
                    label="OTHER REQUESTS"
                    value="5"
                    style={{ fontSize: "0.75rem" }}
                  />
                </TabList>
              </Box>
              <TabPanel value="1" style={{ padding: 0, marginTop: "20px" }}>
                <div className="row">
                  {policies.map((policy) => {
                    const { id, name, number, status } = policy;
                    return (
                      <div
                        className="col-md-4 cursor-pointer"
                        onClick={() => {
                          setExpanded(true);
                          setActive(id);
                        }}
                      >
                        <div
                          className={
                            active === id
                              ? "h-100 p-2 border rounded bg-grayss border-dark"
                              : "h-100 p-2 border rounded"
                          }
                        >
                          <h6 style={{ color: "#002C47" }}>{number}</h6>
                          <p className="mb-1" style={{ fontSize: "0.7rem" }}>
                            {name}
                          </p>
                          <p
                            className={
                              status === "Surrendered"
                                ? "text-warning mb-1"
                                : status === "Active"
                                ? "text-success mb-1"
                                : "text-danger mb-1"
                            }
                            style={{ fontSize: "0.7rem", fontWeight: "500" }}
                          >
                            <PiDotOutlineFill /> {status}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </TabPanel>
              <TabPanel value="2">Item Two</TabPanel>
              <TabPanel value="3">Item Three</TabPanel>
              <TabPanel value="4">Item Four</TabPanel>
              <TabPanel value="5">Item Five</TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
      <div className="col-4">
        <div>
          <Accordion
            style={{
              marginBottom: "10px",
              border: "1px solid gray",
              boxShadow: "unset",
            }}
            className="rounded"
            expanded={expanded}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              onClick={handleExpandClick}
            >
              <h6>Policy details</h6>
            </AccordionSummary>
            <AccordionDetails>
              <div>
                <p className="mb-1 text-muted" style={{ fontSize: "0.8rem" }}>
                  Policy number
                </p>
                <p className="font-weight-bold" style={{ fontSize: "0.8rem" }}>
                  CFLN12077
                </p>
              </div>
              <div>
                <p className="mb-1 text-muted" style={{ fontSize: "0.8rem" }}>
                  Policy name
                </p>
                <p className="font-weight-bold" style={{ fontSize: "0.8rem" }}>
                  Flexible cashflow plan
                </p>
              </div>
              <div>
                <p className="mb-1 text-muted" style={{ fontSize: "0.8rem" }}>
                  Effective date
                </p>
                <p className="font-weight-bold" style={{ fontSize: "0.8rem" }}>
                  Jan 15, 2019
                </p>
              </div>
              <div>
                <p className="mb-1 text-muted" style={{ fontSize: "0.8rem" }}>
                  Maturity date
                </p>
                <p className="font-weight-bold" style={{ fontSize: "0.8rem" }}>
                  Jan 14, 2025
                </p>
              </div>
              <div>
                <p className="mb-1 text-muted" style={{ fontSize: "0.8rem" }}>
                  Policy duration
                </p>
                <p className="font-weight-bold" style={{ fontSize: "0.8rem" }}>
                  6 years
                </p>
              </div>
              <div>
                <p className="mb-1 text-muted" style={{ fontSize: "0.8rem" }}>
                  Policy status
                </p>
                <p
                  className="font-weight-bold text-warning"
                  style={{ fontSize: "0.8rem" }}
                >
                  Surrendered
                </p>
              </div>

              <p
                className="text-primary font-weight-bold cursor-pointer"
                onClick={() => {
                  history.push(`/policy-details`);
                }}
              >
                See full policy details
              </p>
            </AccordionDetails>
          </Accordion>
          <Accordion
            style={{
              marginBottom: "10px",
              border: "1px solid gray",
              boxShadow: "unset",
            }}
            className="rounded"
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <h6>Contact details</h6>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                eget.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            style={{
              marginBottom: "10px",
              border: "1px solid gray",
              boxShadow: "unset",
            }}
            className="rounded"
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <h6>Beneficiary details</h6>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                eget.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            style={{
              marginBottom: "10px",
              border: "1px solid gray",
              boxShadow: "unset",
            }}
            className="rounded"
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4a-content"
              id="panel4a-header"
            >
              <h6>Dependant details</h6>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                eget.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            style={{
              marginBottom: "10px",

              border: "1px solid gray",
              boxShadow: "unset",
            }}
            className="rounded"
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel5a-content"
              id="panel5a-header"
            >
              <h6>Financial advisor details</h6>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                eget.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            style={{
              marginBottom: "10px",
              border: "1px solid gray",
              boxShadow: "unset",
            }}
            className="rounded"
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel6a-content"
              id="panel6a-header"
            >
              <h6>Payment details</h6>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                eget.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default Customer;
