import * as React from "react";
import { Link, withRouter } from "react-router-dom";
import AppContext from "../../context";
import { Button, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { ErrorMsg } from "../../components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import ReactExport from "react-export-excel";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Search = ({
  defaultValue,
  query,
  handleCloseModal,
  onSearchSubmit,
  isExport,
  onExportSubmit,
  resetExport,
  exporting,
  exportData,
  exportReady,
  tfsSearch,
}) => {
  const {
    userData,
    checkObjectData,
    userTypes,
    checkArrayData,
    ticketCategoriesData,
    formatDate,
    getUserType,
    ticketStatusesData,
  } = React.useContext(AppContext);
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({ defaultValue });

  const onSubmit = (data) => {
    if (!isExport) {
      if (!checkObjectData(data)) {
        alert("At least one search value is required!");
        return;
      }
    }

    if (
      (data?.start_date && !data?.end_date) ||
      (data?.end_date && !data?.start_date)
    ) {
      alert("Both start and end date must be selected!");
      return;
    }

    let newSearch = {};
    Object.entries(data).forEach(async ([key, value]) => {
      if (value) {
        newSearch = { ...newSearch, [key]: value };
      }
    });

    if (!isExport) {
      if (Object.keys(newSearch).length === 0) {
        alert("At least one search value is required!");
        return;
      }

      const { ["page"]: remove, ...params } = newSearch;
      onSearchSubmit(params);
      //handleCloseModal();
      console.log("params", params);
    } else {
      tfsSearch(true, "exporting, please wait...");
      onExportSubmit(newSearch);
    }
  };

  React.useEffect(() => {
    if (checkObjectData(query)) {
      Object.entries(query).forEach(async ([key, value]) => {
        if (key === "start_date" || key === "end_date")
          setValue(key, new Date(value));
        else setValue(key, value);
      });
    }
  }, [query]);


  return (
    <form id={"form-search"} onSubmit={handleSubmit(onSubmit)}>
      {!exportReady && (
        <Modal.Body>
          <div className="row">
            <div className="form-group col-12">
              <input
                type="text"
                className="form-control"
                {...register("customer")}
                placeholder="Name/Email address/Phone number"
              />
            </div>
            <div className="form-group col-12">
              <input
                type="text"
                className="form-control"
                {...register("subject")}
                placeholder="Subject"
              />
            </div>
            <div className="form-group col-md-6">
              <input
                type="text"
                className="form-control"
                {...register("policy_number")}
                placeholder="Policy Number"
              />
            </div>
            <div className="form-group col-md-6">
              <input
                type="text"
                className="form-control"
                {...register("ticket_number")}
                placeholder="Ticket Number"
              />
            </div>
            <div className="form-group col-md-12">
              <select className="form-control" {...register("ticket_status")}>
                <option value="">- Status -</option>
                {ticketStatusesData?.map((status) => {
                  return (
                    <option value={status.ticketStatusKey} key={status.id}>
                      {status.ticketStatusName}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="form-group col-md-12">
              <select className="form-control" {...register("category")}>
                <option value="">- Category -</option>
                {ticketCategoriesData?.map((status) => {
                  return (
                    <option value={status.ticketCategoryKey} key={status.id}>
                      {status.ticketCategoryName}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </Modal.Body>
      )}
      <Modal.Footer>
        {isExport ? (
          <>
            {exportReady ? (
              <>
                <input
                  type="button"
                  onClick={resetExport}
                  className="btn btn-secondary"
                  value={
                    exportData.length > 0
                      ? "Close"
                      : "No data found, please retry"
                  }
                />
                {exportData.length > 0 && (
                  <ExcelFile
                    element={
                      <button
                        className="btn btn-success btn-download"
                        type="button"
                      >
                        Download to Excel ({exportData.length} Results)
                      </button>
                    }
                    filename={`users-${moment()}`}
                  >
                    <ExcelSheet data={exportData} name="DV Tracker Users">
                      <ExcelColumn label="Name" value="name" />
                      <ExcelColumn label="Email" value="email" />
                      <ExcelColumn
                        label="User Type"
                        value={(col) => getUserType(col?.user_type)}
                      />
                      <ExcelColumn
                        label="Agent Code"
                        value={(col) =>
                          col?.agentCode &&
                          col?.agentCode !== "false" &&
                          col?.agentCode !== "true" &&
                          col?.agentCode !== "null"
                            ? col?.agentCode
                            : ""
                        }
                      />
                      <ExcelColumn
                        label="Status"
                        value={(col) => (col?.status ? "Active" : "Inactive")}
                      />
                      <ExcelColumn
                        label="Date Created"
                        value={(col) => formatDate(col?.createdAt)}
                      />
                    </ExcelSheet>
                  </ExcelFile>
                )}
              </>
            ) : (
              <>
                <input
                  type="button"
                  onClick={handleCloseModal}
                  className="btn btn-default"
                  value="Close"
                />
                <button className="btn btn-success" type="submit">
                  {exporting ? "exporting, please wait..." : "Begin Export"}
                </button>
              </>
            )}
          </>
        ) : (
          <>
            <input
              type="button"
              onClick={handleCloseModal}
              className="btn btn-default"
              value="Close"
            />
            <button className="btn btn-success" type="submit">
              Submit
            </button>
          </>
        )}
      </Modal.Footer>
    </form>
  );
};

export default Search;
