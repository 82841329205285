import * as React from "react";
import { Link, withRouter } from "react-router-dom";
import AppContext from "../../../context";
import Chart from "react-apexcharts";
import {
  addNote,
  closeTicket,
  create_ticket,
  forwardTicket,
  getNotes,
  replyMail,
  searchPolicyByPolicyNum,
  ticket_details,
  updateTicket,
  update_ticket_status,
  addAttachment,
  sendMail,
  reassign_ticket,
  reassign_ticket_user,
} from "../../../services";
import { useQuery, useMutation, useQueryClient, queryCache } from "react-query";
import {
  Button,
  ButtonGroup,
  Card,
  Collapse,
  Dropdown,
  DropdownButton,
  Modal,
} from "react-bootstrap";
import { BiMailSend, BiReply, BiWalk } from "react-icons/bi";
import { useForm } from "react-hook-form";
import { VscClose, VscEdit, VscReply } from "react-icons/vsc";
import { PiDotOutlineFill, PiNote } from "react-icons/pi";
import { CgAttachment, CgMail, CgMailForward } from "react-icons/cg";
import { GrAttachment, GrClose, GrRefresh } from "react-icons/gr";
import { Box, Chip, Divider, Tab } from "@mui/material";
import useRouter from "../../../hooks/useRouter";
import TicketForm from "./ticketForm";
import useModal from "../../../hooks/useModal";
import useFormState from "../../../hooks/useFormState";
import UpdateStatus from "./updateStatus";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { IosSpinner } from "../../../context/actions/IosSpinner";
import ReactQuill from "react-quill";
import './details.css'
import moment from "moment";

const TicketDetails = ({ history, defaultValue }) => {
  const {
    dispatch,
    numberFormat,
    errorResponse,
    checkArrayData,
    userData,
    ticket360,
    setTicket360,
    notify,
    setPolicy360,
    ticketStatusesData,
    formActionType,
    setStateData,
    handleCloseModal,
    departmentsData,
    unitsData,
    teamMatesData,
  } = React.useContext(AppContext);
  const router = useRouter();
  const queryClient = useQueryClient();
  const [value, setValue] = React.useState("1");
  const [expanded, setExpanded] = React.useState(false);
  const [queryName] = React.useState("ticketDetails");
  const [query, setQuery] = React.useState(router.query);
  const [active, setActive] = React.useState("");
  const [fetchingPolicyInfo, setFetchingPolicyInfo] = React.useState(false);
  const forms = useForm({ defaultValue });
  const [formID] = React.useState("form-ticket");
  const [isClosing, setIsClosing] = React.useState(false);
  const [isLoadingItem, setIsLoadingItem] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);
  const [statusId, setStatusId] = React.useState();
  const [val, setVal] = React.useState();
  const [isAddingNote, setIsAddingNote] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [note, setNote] = React.useState("");
  const [mail, setMail] = React.useState("");
  const [openReply, setOpenReply] = React.useState(false);
  const [isFullMail, setIsFullMail] = React.useState(false);
  const [isFullMailId, setIsFullMailId] = React.useState(0);
  const [isReplying, setIsReplying] = React.useState(false);
  const { toggleFormState: tfsUpdateStatus } =
    useFormState("form-update-status");
  const [isUpdated, setIsUpdated] = React.useState(false);
  const { toggleFormState: tfsTicket } = useFormState("form-ticket");
  const [ticketData, setTicketData] = React.useState();
  const [isForward, setIsForward] = React.useState(false);
  const [unitId, setUnitId] = React.useState(null);
  const [attachmentIds, setAttachmentIds] = React.useState([]);
  const [selectedDepartment, setSelectedDepartment] = React.useState(null);
  const [attachments, setAttachments] = React.useState([]);
  const [openNewMail, setOpenNewMail] = React.useState(false);
  const [selectedAssignee, setSelectedAssignee] = React.useState();
  const [isReassignModal, setIsReassignModal] = React.useState(false);
  // const [file, setFile] = React.useState(null);
  const [uploadingAttachment, setUploadingAttachment] = React.useState(false);
  const {
    modalState,
    setModalState,
    modalTitle,
    setModalTitle,
    closeModal,
    showModal,
  } = useModal();
  const handleCloseFormModal = () => {
    closeModal();
    forms.reset();
  };

  const {
    modalState: modalStatusUpdate,
    modalTitle: modalTitleUpdate,
    setModalTitle: setModalTitleUpdate,
    closeModal: closeModalUpdate,
    showModal: showModalUpdate,
  } = useModal();

  const nnn = sessionStorage.getItem("nnn");

  const { isLoading, isFetching, isError, data, error, refetch } = useQuery(
    [queryName, query],
    () => ticket_details(nnn === undefined ? ticket360 : nnn),
    {
      keepPreviousData: true,
      staleTime: 9000,
      onSettled: async (data, error, variables, context) => {
        setTicketData(data);
      },
      onError: (error) => errorResponse({ error, history, dispatch }),
      refetchInterval: 120000,
    }
  );

  React.useEffect(() => {
    if (data) {
      setTicketData(data);
    }
  }, [data]);

  console.log("data", teamMatesData);

  const { mutate: handleSubmitAdd } = useMutation(
    (value) => create_ticket(value),
    {
      onSuccess: () => {
        notify("success", "Ticket updated", "Ticket updated successfully!");
      },
      onError: (error) => errorResponse({ error, dispatch, history, forms }),
      onSettled: async (data, error, variables, context) => {
        await queryClient.refetchQueries([queryName, query]);
        if (!error) handleCloseFormModal();
        tfsTicket(false);
      },
    }
  );

  const { mutate: handleStatusUpdate } = useMutation(
    ({ id, values }) => update_ticket_status({ id, values }),
    {
      onSuccess: () => {
        notify("success", "Data Modified", "Status successfully modified!");
      },
      onError: (error) => errorResponse({ error, dispatch, history, forms }),
      onSettled: async (data, error, variables, context) => {
        setTicketData(data);
        if (!error) handleCloseUpdateFormModal();
        tfsUpdateStatus(false);
      },
    }
  );

  // const { mutate: handleStatusUpdate } = useMutation(
  //   ({ id, values }) => update_ticket_status({ id, values }),
  //   {
  //     onSuccess: () => {
  //       notify("success", "Data Modified", "Status successfully modified!");
  //     },
  //     onError: (error) => errorResponse({ error, dispatch, history, forms }),
  //     onSettled: async (data, error, variables, context) => {
  //       if (!error) {
  //         await queryClient.refetchQueries([queryName, query]);
  //         handleCloseUpdateFormModal();
  //         tfsUpdateStatus(false);
  //       }
  //     },
  //   }
  // );

  const handleCloseForwardModal = () => {
    setIsForward(false);
  };

  const handleCloseReassignModal = () => {
    setIsReassignModal(false);
  };

  const onSubmit = (values) => {
    tfsTicket(true, "Updating...");
    handleSubmitAdd({ ...values, ticket_id: ticketData?.id });
  };

  const handleCloseTicketModal = () => {
    setIsClosing(!isClosing);
  };
  const handleCloseAddingNoteModal = () => {
    setIsAddingNote(!isAddingNote);
  };

  const { mutate: handlePolicyNumSearch } = useMutation(
    (data) => searchPolicyByPolicyNum(data),
    {
      onSuccess: (res) => {
        if (res?.message !== "Policy not found") {
          notify("success", "Success", "Policy fetched successfully!");
          setPolicy360(res?.data);
          history.push(
            `/policy-details/${res?.ticketData?.details?.polPolicyNo}`
          );
          return;
        }
        notify("danger", "No Policy", res?.message);
      },
      onError: (error) => errorResponse({ error, dispatch, history, forms }),
      onSettled: async (data, error, variables, context) => {
        console.log(error, "error....");
        // await queryClient.refetchQueries([queryName, query]);
        //   if (!error) closeModalSearch();ss
        // toggleFormState(false);
        setFetchingPolicyInfo(false);
      },
    }
  );

  const handlePolicyNumClick = (policyNo) => {
    setFetchingPolicyInfo(true);
    handlePolicyNumSearch({ data: policyNo });
    // setFetchingPolicyInfoFor(null);
  };

  const handleCloseTicket = (id) => {
    setIsLoadingItem(true);
    closeTicket(id, "")
      .then((res) => {
        console.log(res);
        setPolicy360(res);
        notify("success", "Ticket Closed", "ticket closed successfully");
        handleCloseTicketModal();
        setIsLoadingItem(false);
        refetch();
      })
      .catch((err) => {
        notify("danger", "Error", err.message);
      });
  };
  const handleForwardTicket = (id, unit) => {
    setIsLoadingItem(true);
    forwardTicket(id, unit)
      .then((res) => {
        console.log(res);
        // setPolicy360(res);
        notify("success", "Ticket Forwarded", "ticket forwarded successfully");
        handleCloseForwardModal();
        setIsLoadingItem(false);
        refetch();
      })
      .catch((err) => {
        notify("danger", "Error", err.message);
      });
  };

  const updateTicketStatus = (id, tid, comment) => {
    setIsSaving(true);
    updateTicket(id, tid, comment).then((res) => {
      setTicket360(res);
      setIsEditing(false);
      setIsSaving(false);
      queryClient.refetchQueries([queryName, query]);
    });
  };

  const handleAddNote = () => {
    setIsLoadingItem(true);
    const values = { ticket_id: ticketData?.id, note: note };
    addNote(values)
      .then((res) => {
        console.log(res);
        notify("success", "Note Added", "Note added successfully");
        handleCloseAddingNoteModal();
        setIsLoadingItem(false);
        setNote("");
        refetch();
      })
      .catch((err) => {
        notify("danger", "Error", err.message);
      });
  };

  const handleReassignTicket = (assignee_id) => {
    setIsLoadingItem(true);
    const values = { ticket_id: [ticketData?.id], assignee_id: assignee_id };
    reassign_ticket_user(values)
      .then((res) => {
        console.log(res);
        notify("success", "Ticket Reassigned", "Reassigned Successfully");
        // handleCloseAddingNoteModal();
        setIsLoadingItem(false);
        // setNote("");
        refetch();
      })
      .catch((err) => {
        notify("danger", "Error", err.message);
        setIsLoadingItem(false);
      });
  };

  const handleReplyMail = (emailId) => {
    setIsReplying(true);
    const values = {
      email_id: emailId,
      body: mail,
      attachment_ids: attachmentIds,
    };
    // console.log('mail sent 2 ',values);
    replyMail(ticketData?.id, values)
      .then((res) => {
        notify("success", "Mail Sent", "Mail sent successfully");
        queryClient.refetchQueries([queryName, query]);
        setIsReplying(false);
        setMail("");
        setOpenReply(false);
        setAttachmentIds([]);
        setAttachments([]);
      })
      .catch((err) => {
        notify("danger", err.code, err.message);
        setIsReplying(false);
      });
  };

  const handleSendMail = () => {
    setIsReplying(true);
    const values = {
      // email_id: emailId,
      body: mail,
      attachment_ids: attachmentIds,
    };
    sendMail(ticketData?.id, values)
      .then((res) => {
        notify("success", "Mail Sent", "Mail sent successfully");
        queryClient.refetchQueries([queryName, query]);
        setIsReplying(false);
        setMail("");
        setOpenNewMail(false);
        setAttachmentIds([]);
        setAttachments([]);
      })
      .catch((err) => {
        notify("danger", err.code, err.message);
        setIsReplying(false);
      });
  };

  const initEdit = (id) => {
    setStateData(dispatch, "formActionType", id);
    setModalTitleUpdate("Update status");
    // forms.setValue("status_id", statusHistories[0]?.ticketStatus);
    showModalUpdate();
  };

  const initTicketEdit = (
    email,
    phone,
    name,
    policyNumber,
    ticketType,
    ticketCategory,
    ticketSubCategory
  ) => {
    setStateData(dispatch, "formActionType", "updateTicket");
    setModalTitle("Edit Ticket");
    forms.setValue("customer_email", email);
    // forms.setValue("customer_address", address);
    forms.setValue("customer_phone", phone);
    forms.setValue("customer_name", name);
    forms.setValue("policy_number", policyNumber);
    forms.setValue("ticket_type", ticketType);
    forms.setValue("ticket_category", ticketCategory);
    forms.setValue("ticket_sub_category", ticketSubCategory);
    setModalState(true);
  };

  // const {
  //   data: notesData,
  //   isFetching: isFetchingNotes,
  //   refetch,
  // } = useQuery("notes", () => getNotes(ticketData?.id), {
  //   onError: (error) => {
  //     console.log(error);
  //   },
  // });

  const handleCloseUpdateFormModal = () => {
    closeModalUpdate();
    forms.reset();
  };

  const onStatusUpdateSubmit = (data) => {
    tfsUpdateStatus(true, "Updating...");
    handleStatusUpdate({ id: formActionType, values: { ...data } });
    setQuery(data);
  };

  const emailRelated = ticketData?.statusHistories?.filter(
    (email) => email.type === "EMAIL_RECEIVED" || email.type === "EMAIL_REPLY"
  );
  const noteRelated = ticketData?.statusHistories?.filter(
    (email) => email.type === "NOTE"
  );
  const fileRelated = ticketData?.statusHistories?.filter(
    (email) => email.type === "FILE"
  );
  const statusRelated = ticketData?.statusHistories?.filter(
    (email) => email.type === "UPDATE"
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  console.log("ticketdata", emailRelated);

  const colorMapping = {
    ASSIGNED: "#007dcc",
    IN_PROGRESS: "#f1734f",
    NEED_FEEDBACK: "#f1b44c",
    RESOLVED: "#34c38f",
    NEW: "#343a40",
  };

  const defaultColor = "red"; // Default color for unknown values

  const getColorForStatus = (statusKey) => {
    return colorMapping[statusKey] || defaultColor;
  };

  const filteredUnit = unitsData?.filter(
    (unit) =>
      unit?.departmentId === selectedDepartment &&
      unit?.unitKey !== "TECHNICAL" &&
      unit?.unitKey !== "ALLOCATION & RECONCILIATION" &&
      unit?.unitKey !== "RETAIL AGENCY"
  );

  console.log("this is department data", departmentsData);

  const filteredDepartments = departmentsData?.filter(
    (dept) => dept?.departmentKey !== "IT" && dept?.departmentKey !== "FINANCE"
  );

  const addAttachments = async (e) => {
    const formData = new FormData();
    const file = e.target.files;

    if (file) {
      setUploadingAttachment(true);
      formData.append("file", file[0]);

      addAttachment(ticketData?.id, formData)
        .then((res) => {
          setUploadingAttachment(false);
          setAttachmentIds((prevAttachmentIds) => [
            ...prevAttachmentIds,
            res?.id,
          ]);

          setAttachments((prevAttachments) => [...prevAttachments, res]);
        })
        .catch((error) => {
          notify(
            "danger",
            "Something went wrong",
            "could not upload your file, please try again"
          );
          setUploadingAttachment(false);
        });
    } else {
      alert("Please select a file first.");
    }
  };
  // const handleUpload = () => {
  //   if (file) {
  //     const formData = new FormData();
  //     formData.append("file", file);

  //     // Perform your upload logic using formData.
  //     // You can use fetch or an HTTP library like Axios to send the formData to the server.

  //     // For demonstration purposes, you can log the FormData object.
  //     for (var pair of formData.entries()) {
  //       console.log(pair[0] + ", " + pair[1]);
  //     }
  //   } else {
  //     alert("Please select a file first.");
  //   }
  // };


  return (
    <>
      {isLoading ? (
        <>loading, please wait...</>
      ) : (
        <div className="row">
          <div className="col-9 policy-details">
            <div className="d-flex align-items-center justify-content-between">
              <div className="row mb-4">
                {!ticketData?.isIngested && (
                  <Button
                    className="column mr-3"
                    variant="custom"
                    onClick={() => {
                      setOpenNewMail(!openNewMail);
                    }}
                  >
                    <VscReply /> New Mail
                  </Button>
                )}
                <Button
                  className="column mr-3"
                  onClick={() => {
                    setIsAddingNote(true);
                  }}
                  variant="custom"
                >
                  <PiNote /> Add note
                </Button>
                <DropdownButton
                  id="dropdown-basic-button"
                  title={
                    <span>
                      <CgMailForward /> Forward
                    </span>
                  }
                  drop="down"
                  variant="custom"
                  className="column mr-3"
                  // autoClose={false}
                >
                  {filteredDepartments
                    .sort(function (a, b) {
                      if (a.departmentName < b.departmentName) {
                        return -1;
                      }
                      if (a.departmentName > b.departmentName) {
                        return 1;
                      }
                      return 0;
                    })
                    ?.map((department) => {
                      return (
                        <>
                          <Dropdown
                            // id="dropdown-basic-button"
                            // title={}
                            drop="down"
                            variant="custom"
                            // autoClose={false}
                            style={{ width: "100%" }}
                            onClick={() => {
                              setSelectedDepartment(department?.id);
                            }}
                          >
                            <Dropdown.Toggle
                              split
                              variant="custom"
                              style={{ border: 0 }}
                              id="dropdown-split-basic"
                              className="w-100"
                            >
                              <span>{department?.departmentName}</span>
                            </Dropdown.Toggle>
                            <Dropdown.Divider />
                            <Dropdown.Menu>
                              {filteredUnit
                                .sort(function (a, b) {
                                  if (a.unitName < b.unitName) {
                                    return -1;
                                  }
                                  if (a.unitName > b.unitName) {
                                    return 1;
                                  }
                                  return 0;
                                })
                                ?.map((units) => {
                                  return (
                                    <Dropdown.Item
                                      onClick={() => {
                                        !ticketData?.ticketType ||
                                        !ticketData?.ticketCategory ||
                                        !ticketData?.ticketSubCategory ||
                                        !data.currentStatus
                                          ? notify(
                                              "warning",
                                              "Notification",
                                              "Please update ticket information before forwarding ticket."
                                            )
                                          : setIsForward(true);
                                        setUnitId(units?.unitKey);
                                      }}
                                    >
                                      <span>{units?.unitName}</span>
                                    </Dropdown.Item>
                                  );
                                })}
                            </Dropdown.Menu>
                          </Dropdown>

                          {/* <Dropdown.Item>
                        <span>{department?.departmentName}</span>
                      </Dropdown.Item> */}
                        </>
                      );
                    })}
                </DropdownButton>
                <DropdownButton
                  id="dropdown-basic-button"
                  title={
                    <span>
                      <CgMailForward /> Reassign
                    </span>
                  }
                  drop="down"
                  variant="custom"
                  className="column mr-3"
                  // autoClose={false}
                >
                  {teamMatesData?.map((teamMate) => {
                    return (
                      <>
                        <Dropdown
                          // id="dropdown-basic-button"
                          // title={}
                          drop="down"
                          variant="custom"
                          // autoClose={false}
                          style={{ width: "100%" }}
                          // onClick={() => {
                          //   setSelectedDepartment(department?.id);
                          // }}
                        >
                          <Dropdown.Toggle
                            split
                            variant="custom"
                            style={{ border: 0 }}
                            id="dropdown-split-basic"
                            className="w-100"
                            onClick={() => {
                              setSelectedAssignee(teamMate);
                              setIsReassignModal(true);
                              console.log(teamMate?.id);
                            }}
                          >
                            <span>{teamMate?.fullname}</span>
                          </Dropdown.Toggle>
                          <Dropdown.Divider />
                          {/* <Dropdown.Menu>
                            {filteredUnit?.map((units) => {
                              return (
                                <Dropdown.Item
                                  // onClick={() => {

                                  // }}
                                  onClick={() => {
                                    !ticketData?.ticketType ||
                                    !ticketData?.ticketCategory ||
                                    !ticketData?.ticketSubCategory ||
                                    !data.currentStatus
                                      ? notify(
                                          "warning",
                                          "Notification",
                                          "Please update ticket information before forwarding ticket."
                                        )
                                      : setIsForward(true);
                                    setUnitId(units?.unitKey);
                                  }}
                                >
                                  <span>{units?.unitName}</span>
                                </Dropdown.Item>
                              );
                            })}
                          </Dropdown.Menu> */}
                        </Dropdown>

                        {/* <Dropdown.Item>
                        <span>{department?.departmentName}</span>
                      </Dropdown.Item> */}
                      </>
                    );
                  })}
                </DropdownButton>
                {/* {ticketData?.currentStatus?.ticketStatusName !== "Closed" && (
                  <Button
                    className="column mr-3"
                    onClick={handleCloseTicketModal}
                    variant="custom"
                  >
                    <GrClose /> Close
                  </Button>
                )} */}
                <Button
                  className="column mr-3"
                  onClick={() => {
                    refetch();
                  }}
                  variant="custom"
                >
                  <GrRefresh />
                </Button>
              </div>
              {isFetching || (uploadingAttachment && <IosSpinner />)}
            </div>

            <Collapse in={openNewMail} className="w-100 mb-3">
              <div>
                <div className="mb-4 row">
                  {attachments?.map((attachment) => {
                    return (
                      <Button
                        variant="custom"
                        className="mr-2"
                        key={attachment?.id}
                      >
                        <a href={attachment?.uri} className="text-dark" target="_blank">
                          <GrAttachment /> {attachment?.filename}
                        </a>
                        <VscClose
                          style={{
                            color: "red",
                            fontSize: "1rem",
                            marginLeft: "10px",
                          }}
                          onClick={() => {
                            setAttachments(
                              attachments?.filter(
                                (attach) => attach?.id !== attachment?.id
                              )
                            );

                            setAttachmentIds(
                              attachmentIds?.filter(
                                (attach) => attach !== attachment?.id
                              )
                            );
                          }}
                        />
                      </Button>
                    );
                  })}
                </div>
                <textarea
                  rows={6}
                  type="text"
                  className="dashed-form-control"
                  placeholder="Type a mail..."
                  value={mail}
                  onChange={(e) => setMail(e.target.value)}
                />
                <div
                  className="mt-3"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: "row",
                  }}
                >
                  <div>
                    <button
                      className="btn btn-success mr-3"
                      onClick={() => {
                        handleSendMail();
                      }}
                      disabled={isReplying ? true : false}
                    >
                      {isReplying ? "Sending Mail..." : "Send"}
                    </button>

                    <label for="files">
                      <GrAttachment />{" "}
                      {uploadingAttachment
                        ? "Uploading, please wait..."
                        : "Add attachment"}
                    </label>

                    <input
                      type="file"
                      id="files"
                      name="file"
                      className="hidden"
                      style={{ display: "none" }}
                      onChange={addAttachments}
                    />
                  </div>

                  <input
                    type="button"
                    onClick={() => {
                      setOpenNewMail(!openNewMail);
                      setAttachmentIds([]);
                      setAttachments([]);
                    }}
                    className="btn btn-default"
                    value="Cancel"
                  />
                </div>
              </div>
            </Collapse>

            <div className="">
              <Box sx={{ width: "100%", typography: "body1" }}>
                <TabContext value={value}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList
                      onChange={handleChange}
                      aria-label="lab API tabs example"
                    >
                      <Tab
                        label={
                          ticketData?.isIngested ? "Mails" : "Conversation"
                        }
                        value="1"
                        style={{ fontSize: "0.75rem" }}
                      />
                      <Tab
                        label="TIMELINE"
                        value="2"
                        style={{ fontSize: "0.75rem" }}
                      />
                      <Tab
                        label="NOTES"
                        value="3"
                        style={{ fontSize: "0.75rem" }}
                      />
                      <Tab
                        label="TICKET FILES"
                        value="4"
                        style={{ fontSize: "0.75rem" }}
                      />
                    </TabList>
                  </Box>
                  <TabPanel value="1" style={{ padding: 0, marginTop: "20px" }}>
                    <h5 className="mt-3">
                      {ticketData?.isIngested ? (
                        <CgMail className="mr-2" />
                      ) : (
                        <BiWalk className="mr-2" />
                      )}
                      {ticketData?.ticketSubject}
                    </h5>

                    {/* {ticketData?.isIngested && (
              <div className="mt-3 text-muted">
                <div>
                  <p className="mb-1 mt-3">
                    From: {ticketData?.ingestedEmail?.from?.emailAddress?.address}
                  </p>
                  <p>
                    To:
                    {ticketData?.ingestedEmail?.toRecipients?.map((recipient) => {
                      return (
                        <span className="ml-1">
                          {recipient?.emailAddress?.address}
                        </span>
                      );
                    })}
                  </p>
                </div>
              </div>
            )} */}

                    <div
                      // style={{
                      //   border: "1px solid #D4D4D8",
                      // }}
                      className="rounded mt-3"
                    >
                      {/* <Collapse in={openReply} className="w-100 mb-3">
                <div>
                  <textarea
                    rows={6}
                    type="text"
                    className="dashed-form-control"
                    placeholder="Type a mail..."
                  />
                  <div
                    className="mt-3"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      flexDirection: "row",
                    }}
                  >
                    <button
                      className="btn btn-success"
                      onClick={() => {
                        updateTicketStatus(
                          val,
                          ticketData?.id,
                          "updating the status"
                        );
                      }}
                      disabled={isSaving ? true : false}
                    >
                      {isSaving ? "Saving..." : "Send"}
                    </button>
                    <input
                      type="button"
                      onClick={() => {
                        setOpenReply(!openReply);
                      }}
                      className="btn btn-default"
                      value="Cancel"
                    />
                  </div>
                  <Divider className="mt-4" />
                </div>
              </Collapse> */}
                      {ticketData?.isIngested ? (
                        // <div
                        //   dangerouslySetInnerHTML={{
                        //     __html: `${ticketData?.ingestedEmail?.body?.content}`,
                        //   }}
                        // ></div>
                        emailRelated
                          ?.slice() // Create a shallow copy of the array to avoid mutating the original
                          .sort((a, b) => {
                            // Sort in descending order based on updatedAt date
                            const dateA = new Date(a.createdAt);
                            const dateB = new Date(b.createdAt);
                            return dateB - dateA;
                          })
                          .map((email) => {
                            return (
                              <div
                                onClick={() => {
                                  setIsFullMailId(email?.id);
                                }}
                                key={email.id}
                                style={{
                                  border: `${
                                    isFullMailId === email?.id
                                      ? "1.5px solid #000"
                                      : "1px solid #D4D4D8"
                                  }`,
                                }}
                                className="hover mb-3 p-3 rounded cursor-pointer"
                              >
                                {isFullMailId === email.id ? (
                                  <>
                                    <div className="row justify-content-between mb-3 align-items-center pl-2 pr-2">
                                      <div>
                                      <p
                                        style={{
                                          fontSize: "0.8rem",
                                          fontWeight: "500",
                                        }}
                                        className="mb-0"
                                      >
                                        {email?.email?.from?.emailAddress?.name}{" "}
                                        {`<${email?.email?.from?.emailAddress?.address}>`}
                                      </p>
                                      <p
                                        style={{
                                          fontSize: "0.8rem",
                                          fontWeight: "500",
                                        }}
                                        className="mb-0"
                                      >Sent: {moment(ticketData?.statusHistories[1]?.email?.createdAt).format('dddd, MMMM D, YYYY h:mm A')}</p>
                                      </div>

                                      {email?.type === "EMAIL_RECEIVED" && (
                                        <Button
                                          className="column mr-3"
                                          variant="custom"
                                          onClick={() => {
                                            !ticketData?.ticketType ||
                                            !ticketData?.ticketCategory ||
                                            !ticketData?.ticketSubCategory ||
                                            !data.currentStatus
                                              ? notify(
                                                  "warning",
                                                  "Notification",
                                                  "Please update ticket information before replying"
                                                )
                                              : setOpenReply(!openReply);
                                          }}
                                        >
                                          <VscReply />
                                        </Button>
                                      )}
                                    </div>

                                    <div className="mb-4 row">
                                      {attachments?.map((attachment) => {
                                        return (
                                          <Button
                                            variant="custom"
                                            className="mr-2"
                                            key={attachment?.id}
                                          >
                                            <a
                                              href={attachment?.uri}
                                              className="text-dark"
                                              target="_blank"
                                            >
                                              <GrAttachment />{" "}
                                              {attachment?.filename}
                                            </a>
                                            <VscClose
                                              style={{
                                                color: "red",
                                                fontSize: "1rem",
                                                marginLeft: "10px",
                                              }}
                                              onClick={() => {
                                                setAttachments(
                                                  attachments?.filter(
                                                    (attach) =>
                                                      attach?.id !==
                                                      attachment?.id
                                                  )
                                                );

                                                setAttachmentIds(
                                                  attachmentIds?.filter(
                                                    (attach) =>
                                                      attach !== attachment?.id
                                                  )
                                                );
                                              }}
                                            />
                                          </Button>
                                        );
                                      })}
                                    </div>

                                    <Collapse
                                      in={
                                        openReply && isFullMailId === email.id
                                      }
                                      className="w-100 mb-3"
                                    >
                                      <div>
                                        {/* <textarea
                                          rows={6}
                                          type="text"
                                          wrap="hard"
                                          className="dashed-form-control"
                                          placeholder="Type a mail..."
                                          value={mail}
                                          onChange={(e) =>
                                            setMail(e.target.value)
                                          }
                                        /> */}
                                        <ReactQuill className="custom-quill" value={mail} onChange={(value) =>
                                            setMail(value)} />
                                        <div
                                          className="mt-3"
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            flexDirection: "row",
                                          }}
                                        >
                                          <div>
                                            <button
                                              className="btn btn-success mr-3"
                                              onClick={() => {
                                                handleReplyMail(
                                                  email?.ticketEmailId
                                                );
                                              }}
                                              disabled={
                                                isReplying ? true : false
                                              }
                                            >
                                              {isReplying
                                                ? "Sending Mail..."
                                                : "Send"}
                                            </button>

                                            <label for="files">
                                              <GrAttachment />{" "}
                                              {uploadingAttachment
                                                ? "Uploading, please wait..."
                                                : "Add attachment"}
                                            </label>

                                            <input
                                              type="file"
                                              id="files"
                                              name="file"
                                              className="hidden"
                                              style={{ display: "none" }}
                                              onChange={addAttachments}
                                            />
                                          </div>

                                          <input
                                            type="button"
                                            onClick={() => {
                                              setOpenReply(!openReply);
                                              setAttachmentIds([]);
                                              setAttachments([]);
                                            }}
                                            className="btn btn-default"
                                            value="Cancel"
                                          />
                                        </div>
                                        <Divider className="mt-4" />
                                      </div>
                                    </Collapse>
                                    <div>
                                      <div className="mb-4 row">
                                        {email?.email?.attachments.map(
                                          (attachment) => {
                                            return (
                                              <Button
                                                variant="custom"
                                                className="mr-2"
                                              >
                                                <a
                                                  href={attachment?.uri}
                                                  className="text-dark"
                                                  target="_blank"
                                                >
                                                  <GrAttachment />{" "}
                                                  {attachment?.name}
                                                </a>
                                              </Button>
                                            );
                                          }
                                        )}
                                        {email?.email?.attachments?.length == 0 && email?.email?.hasAttachments == true && (
                                          
                                          <p
                                            // href={attachment?.uri}
                                            className="text-dark"
                                          >
                                            Attachment not visible. Check weCare mailbox.
                                          </p>
                                        )}
                                      </div>
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: `${email?.email?.body?.content}`,
                                        }}
                                      ></div>
                                    </div>
                                  </>
                                ) : (
                                  <div>
                                    <div className="row justify-content-between mb-3 align-items-center pl-2 pr-2">
                                      <div>
                                      <p
                                        style={{
                                          fontSize: "0.8rem",
                                          fontWeight: "500",
                                        }}
                                        className="mb-0"
                                      >
                                        {email?.email?.from?.emailAddress?.name}{" "}
                                        {`<${email?.email?.from?.emailAddress?.address}>`}
                                        {email?.email?.attachments?.length >
                                          0 && (
                                          <GrAttachment
                                            style={{
                                              fontSize: "0.6rem",
                                            }}
                                          />
                                        )}
                                      </p>
                                      <p
                                        style={{
                                          fontSize: "0.8rem",
                                          fontWeight: "500",
                                        }}
                                        className="mb-0"
                                      >Sent: {moment(ticketData?.statusHistories[1]?.email?.createdAt).format('dddd, MMMM D, YYYY h:mm A')}</p>
                                      </div>
                                      {email.type === "EMAIL_RECEIVED" && (
                                        <Button
                                          className="column mr-3"
                                          variant="custom"
                                          onClick={() => {
                                            !ticketData?.ticketType ||
                                            !ticketData?.ticketCategory ||
                                            !ticketData?.ticketSubCategory ||
                                            !data.currentStatus
                                              ? notify(
                                                  "warning",
                                                  "Notification",
                                                  "Please update ticket information before replying."
                                                )
                                              : setOpenReply(!openReply);
                                          }}
                                        >
                                          <VscReply />
                                        </Button>
                                      )}
                                    </div>
                                    <p style={{ fontSize: "0.8rem" }}>
                                      {email?.email?.bodyPreview}
                                    </p>
                                  </div>
                                )}
                              </div>
                            );
                          })
                      ) : (
                        <>
                          {emailRelated
                            ?.slice() // Create a shallow copy of the array to avoid mutating the original
                            .sort((a, b) => {
                              // Sort in descending order based on updatedAt date
                              const dateA = new Date(a.createdAt);
                              const dateB = new Date(b.createdAt);
                              return dateB - dateA;
                            })
                            .map((email) => {
                              return (
                                <div
                                  onClick={() => {
                                    setIsFullMailId(email?.id);
                                  }}
                                  key={email.id}
                                  style={{
                                    border: `${
                                      isFullMailId === email?.id
                                        ? "1.5px solid #000"
                                        : "1px solid #D4D4D8"
                                    }`,
                                  }}
                                  className="hover mb-3 p-3 rounded cursor-pointer"
                                >
                                  {isFullMailId === email.id ? (
                                    <>
                                      <div className="row justify-content-between mb-3 align-items-center pl-2 pr-2">
                                        <p
                                          style={{
                                            fontSize: "0.8rem",
                                            fontWeight: "500",
                                          }}
                                          className="mb-0"
                                        >
                                          {
                                            email?.email?.from?.emailAddress
                                              ?.name
                                          }{" "}
                                          {`<${email?.email?.from?.emailAddress?.address}>`}
                                        </p>

                                        {email?.type === "EMAIL_RECEIVED" && (
                                          <Button
                                            className="column mr-3"
                                            variant="custom"
                                            onClick={() => {
                                              !ticketData?.ticketType ||
                                              !ticketData?.ticketCategory ||
                                              !ticketData?.ticketSubCategory ||
                                              !data.currentStatus
                                                ? notify(
                                                    "warning",
                                                    "Notification",
                                                    "Please update ticket information before replying"
                                                  )
                                                : setOpenReply(!openReply);
                                            }}
                                          >
                                            <VscReply />
                                          </Button>
                                        )}
                                      </div>

                                      <div className="mb-4 row">
                                        {attachments?.map((attachment) => {
                                          return (
                                            <Button
                                              variant="custom"
                                              className="mr-2"
                                              key={attachment?.id}
                                            >
                                              <a
                                                href={attachment?.uri}
                                                className="text-dark"
                                                target="_blank"
                                              >
                                                <GrAttachment />{" "}
                                                {attachment?.filename}
                                              </a>
                                              <VscClose
                                                style={{
                                                  color: "red",
                                                  fontSize: "1rem",
                                                  marginLeft: "10px",
                                                }}
                                                onClick={() => {
                                                  setAttachments(
                                                    attachments?.filter(
                                                      (attach) =>
                                                        attach?.id !==
                                                        attachment?.id
                                                    )
                                                  );

                                                  setAttachmentIds(
                                                    attachmentIds?.filter(
                                                      (attach) =>
                                                        attach !==
                                                        attachment?.id
                                                    )
                                                  );
                                                }}
                                              />
                                            </Button>
                                          );
                                        })}
                                      </div>

                                      <Collapse
                                        in={
                                          openReply && isFullMailId === email.id
                                        }
                                        className="w-100 mb-3"
                                      >
                                        <div>
                                          {/* <textarea
                                            rows={6}
                                            type="text"
                                            className="dashed-form-control"
                                            wrap="hard"
                                            placeholder="Type a mail..."
                                            value={mail}
                                            onChange={(e) =>
                                              setMail(e.target.value)
                                            }
                                          /> */}
                                          <ReactQuill className="custom-quill" value={mail} onChange={(value) =>
                                            setMail(value)} />
                                          <div
                                            className="mt-3"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "space-between",
                                              flexDirection: "row",
                                            }}
                                          >
                                            <div>
                                              <button
                                                className="btn btn-success mr-3"
                                                onClick={() => {
                                                  handleReplyMail(
                                                    email?.ticketEmailId
                                                  );
                                                }}
                                                disabled={
                                                  isReplying ? true : false
                                                }
                                              >
                                                {isReplying
                                                  ? "Sending Mail..."
                                                  : "Send"}
                                              </button>

                                              <label for="files">
                                                <GrAttachment />
                                              </label>

                                              <input
                                                type="file"
                                                id="files"
                                                name="file"
                                                className="hidden"
                                                style={{ display: "none" }}
                                                onChange={addAttachments}
                                              />
                                            </div>

                                            <input
                                              type="button"
                                              onClick={() => {
                                                setOpenReply(!openReply);
                                                setAttachmentIds([]);
                                                setAttachments([]);
                                              }}
                                              className="btn btn-default"
                                              value="Cancel"
                                            />
                                          </div>
                                          <Divider className="mt-4" />
                                        </div>
                                      </Collapse>
                                      <div>
                                        <div className="mb-4 row">
                                          {email?.email?.attachments.map(
                                            (attachment) => {
                                              return (
                                                <Button
                                                  variant="custom"
                                                  className="mr-2"
                                                >
                                                  <a
                                                    href={attachment?.uri}
                                                    className="text-dark"
                                                    target="_blank"
                                                  >
                                                    <GrAttachment />{" "}
                                                    {attachment?.name}
                                                  </a>
                                                </Button>
                                              );
                                            }
                                          )}
                                        </div>
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: `${email?.email?.body?.content}`,
                                          }}
                                        ></div>
                                      </div>
                                    </>
                                  ) : (
                                    <div>
                                      <div className="row justify-content-between mb-3 align-items-center pl-2 pr-2">
                                        <p
                                          style={{
                                            fontSize: "0.8rem",
                                            fontWeight: "500",
                                          }}
                                          className="mb-0"
                                        >
                                          {
                                            email?.email?.from?.emailAddress
                                              ?.name
                                          }{" "}
                                          {`<${email?.email?.from?.emailAddress?.address}>`}
                                          {email?.email?.attachments?.length >
                                            0 && (
                                            <GrAttachment
                                              style={{
                                                fontSize: "0.6rem",
                                              }}
                                            />
                                          )}
                                        </p>
                                        {email.type === "EMAIL_RECEIVED" && (
                                          <Button
                                            className="column mr-3"
                                            variant="custom"
                                            onClick={() => {
                                              !ticketData?.ticketType ||
                                              !ticketData?.ticketCategory ||
                                              !ticketData?.ticketSubCategory ||
                                              !data.currentStatus
                                                ? notify(
                                                    "warning",
                                                    "Notification",
                                                    "Please update ticket information before replying."
                                                  )
                                                : setOpenReply(!openReply);
                                            }}
                                          >
                                            <VscReply />
                                          </Button>
                                        )}
                                      </div>
                                      <p style={{ fontSize: "0.8rem" }}>
                                        {email?.email?.bodyPreview}
                                      </p>
                                    </div>
                                  )}
                                </div>
                              );
                            })}

                          <p
                            style={{
                              border: "1px solid #D4D4D8",
                            }}
                            className="mb-0 p-4 rounded"
                          >
                            {ticketData?.ticketBody}
                          </p>
                        </>
                      )}
                    </div>
                  </TabPanel>
                  <TabPanel value="3" style={{ padding: 0, marginTop: "20px" }}>
                    <Card
                      className="border rounded"
                      style={{
                        fontSize: "0.9rem",
                      }}
                    >
                      <Card.Body>
                        {noteRelated?.length > 0 ? (
                          noteRelated
                            ?.slice() // Create a shallow copy of the array to avoid mutating the original
                            .sort((a, b) => {
                              // Sort in descending order based on updatedAt date
                              const dateA = new Date(a.createdAt);
                              const dateB = new Date(b.createdAt);
                              return dateB - dateA;
                            })
                            .map((status) => {
                              const formattedDate = new Date(status.createdAt);
                              return (
                                <div>
                                  <span
                                    className="mb-2"
                                    style={{ fontSize: "0.7rem" }}
                                  >
                                    {formattedDate.toLocaleTimeString()}
                                    <PiDotOutlineFill />
                                    {formattedDate.toDateString()}
                                  </span>
                                  <p
                                    className="mb-2 mt-2"
                                    style={{ fontSize: "0.8rem" }}
                                  >
                                    {status?.note?.note}
                                  </p>
                                  <h6 className="mb-0 mt-2">
                                    {status?.author?.fullname}
                                  </h6>
                                  <p
                                    className="mb-2"
                                    style={{
                                      color: getColorForStatus(
                                        status?.status?.ticketStatusKey
                                      ),
                                      fontSize: "0.8rem",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {status?.status?.ticketStatusName}
                                  </p>

                                  {/* </Chip> */}
                                  <p
                                    className="mb-2"
                                    style={{ fontSize: "0.8rem" }}
                                  >
                                    {status?.author?.role?.roleName}
                                  </p>

                                  <Divider />
                                </div>
                              );
                            })
                        ) : (
                          <div className="text-center">
                            <h5>No Notes</h5>
                            <p className="mb-1">
                              There are currently no notes for this ticket.
                            </p>
                            <p>when notes are added, you will see them here.</p>
                          </div>
                        )}
                      </Card.Body>
                    </Card>
                  </TabPanel>
                  <TabPanel value="4" style={{ padding: 0, marginTop: "20px" }}>
                    <Card
                      className="border rounded"
                      style={{
                        fontSize: "0.9rem",
                      }}
                    >
                      <Card.Body>
                        {fileRelated?.length > 0 ? (
                          fileRelated[0]?.file?.files?.map((status) => {
                            // const formattedDate = new Date(status.createdAt);
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "flex-start",
                                  flexDirection: "column",
                                }}
                              >
                                <a
                                  href={status?.uri}
                                  className="mb-2 mt-2"
                                  style={{
                                    fontSize: "0.8rem",
                                    border: "0.5px solid gray",
                                    padding: "8px",
                                    borderRadius: "6px",
                                  }}
                                >
                                  {status?.name}
                                </a>

                                <Divider />
                              </div>
                            );
                          })
                        ) : (
                          <div className="text-center">
                            <h5>No Files</h5>
                            <p className="mb-1">
                              There are no files for this ticket.
                            </p>
                          </div>
                        )}
                      </Card.Body>
                    </Card>
                  </TabPanel>
                  <TabPanel value="2" style={{ padding: 0, marginTop: "20px" }}>
                    <Card
                      className="border rounded"
                      style={{
                        fontSize: "0.9rem",
                      }}
                    >
                      {/* <Card.Header className="bg-transparent border rounded font-weight-bold">
                        Ticket timeline
                      </Card.Header> */}
                      <Card.Body>
                        {/* <div className="mb-4">
                  <span>Name</span>
                  <h6 className="text-primary cursor-pointer">
                    {ticketData?.customer?.customerName}
                  </h6>
                </div>
                <div
                  className="mb-4"
                  onClick={() => {
                    handlePolicyNumClick(ticketData?.policy?.policyNumber);
                  }}
                >
                  <span>Policy Number</span>
                  <h6
                    className="text-primary cursor-pointer"
                    // onClick={handlePolicyNoClick(ticketData?.policy?.policyNumber)}
                  >
                    {ticketData?.policy?.policyNumber}
                  </h6>
                  {fetchingPolicyInfo && (
                    <p>
                      <span
                        class="spinner-border spinner-border-sm mr-1 text-primary"
                        role="status"
                      ></span>
                      Getting policy 360
                    </p>
                  )}
                </div>
                <div className="mb-4">
                  <span>Email</span>
                  <h6>{ticketData?.customer?.customerEmail}</h6>
                </div>
                <div>
                  <span>Phone</span>
                  <h6>{ticketData?.customer?.customerPhone}</h6>
                </div> */}
                        {statusRelated
                          ?.slice() // Create a shallow copy of the array to avoid mutating the original
                          .sort((a, b) => {
                            // Sort in descending order based on updatedAt date
                            const dateA = new Date(a.createdAt);
                            const dateB = new Date(b.createdAt);
                            return dateB - dateA;
                          })
                          .map((status) => {
                            const formattedDate = new Date(status.createdAt);
                            return (
                              <div>
                                <span
                                  className="mb-2"
                                  style={{ fontSize: "0.7rem" }}
                                >
                                  {formattedDate.toLocaleTimeString()}
                                  <PiDotOutlineFill />
                                  {formattedDate.toDateString()}
                                </span>
                                <p
                                  className="mb-2 mt-2"
                                  style={{ fontSize: "0.8rem" }}
                                >
                                  {status?.note?.note}
                                </p>
                                <h6 className="mb-0 mt-2">
                                  {status?.author?.fullname}
                                </h6>
                                <p
                                  className="mb-2"
                                  style={{
                                    color: getColorForStatus(
                                      status?.status?.ticketStatusKey
                                    ),
                                    fontSize: "0.8rem",
                                    fontWeight: "500",
                                  }}
                                >
                                  {status?.status?.ticketStatusName}
                                </p>

                                {/* </Chip> */}
                                <p
                                  className="mb-2"
                                  style={{ fontSize: "0.8rem" }}
                                >
                                  {status?.author?.role?.roleName}
                                </p>

                                <Divider />
                              </div>
                            );
                          })}
                      </Card.Body>
                    </Card>
                  </TabPanel>
                </TabContext>
              </Box>
            </div>

            {/* <div className="mt-4">
              <h6 className="mb-4">
                TIMELINE CONVERSATION
                {isFetchingNotes && (
                  <span
                    className="spinner-border spinner-border-sm ml-2 text-primary"
                    role="status"
                  ></span>
                )}
              </h6>

              {notesticketData?.map((note) => {
                const newDate = new Date(note.updatedAt);
                return (
                  <div key={note.id} className="mb-3">
                    <span className="text-muted" style={{ fontSize: "0.8rem" }}>
                      {newDate.toLocaleString()}
                    </span>
                    <p className="mb-3 mt-2">{note.note}</p>
                    <Divider />
                  </div>
                );
              })}
            </div> */}
          </div>

          <div className="col-3 policy-details">
            <span
              className="font-weight-bold cursor-pointer text-primary"
              // onClick={() => {
              //   setIsEditing(!isEditing);
              // }}
              onClick={() => {
                initTicketEdit(
                  ticketData?.customer?.customerEmail,
                  // ticketData?.customer?.customerAddress,
                  ticketData?.customer?.customerPhone,
                  ticketData?.customer?.customerName,
                  ticketData?.policy?.policyNumber,
                  ticketData?.ticketType?.ticketTypeName,
                  ticketData?.ticketCategory?.ticketCategoryName,
                  ticketData?.ticketSubCategory?.ticketSubCategoryName
                );
              }}
            >
              Update <VscEdit className="ml-1 mb-1" />
            </span>
            <Card className="border rounded">
              <Card.Header className="bg-transparent border rounded font-weight-bold">
                Ticket Information (#{ticketData?.ticketNumber})
              </Card.Header>
              <Card.Body>
                <div className="mb-4">
                  <span>Ticket Type</span>
                  {!isEditing ? (
                    <h6>{ticketData?.ticketType?.ticketTypeName}</h6>
                  ) : (
                    <select
                      type="text"
                      className="form-control"
                      placeholder="Admin"
                      value={val}
                      onChange={(e) => setVal(e.target.value)}
                    >
                      <option></option>
                      {ticketStatusesData?.map((row) => (
                        <option key={row.id} value={row.id}>
                          {row.ticketStatus}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
                <div className="mb-4">
                  <span>Ticket Category</span>
                  {!isEditing ? (
                    <h6>{ticketData?.ticketCategory?.ticketCategoryName}</h6>
                  ) : (
                    <select
                      type="text"
                      className="form-control"
                      placeholder="Admin"
                      value={val}
                      onChange={(e) => setVal(e.target.value)}
                    >
                      <option></option>
                      {ticketStatusesData?.map((row) => (
                        <option key={row.id} value={row.id}>
                          {row.ticketStatus}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
                <div className="mb-4">
                  <span>Ticket Sub Category</span>
                  {!isEditing ? (
                    <h6>
                      {ticketData?.ticketSubCategory?.ticketSubCategoryName}
                    </h6>
                  ) : (
                    <select
                      type="text"
                      className="form-control"
                      placeholder="Admin"
                      value={val}
                      onChange={(e) => setVal(e.target.value)}
                    >
                      <option></option>
                      {ticketStatusesData?.map((row) => (
                        <option key={row.id} value={row.id}>
                          {row.ticketStatus}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
                {/* <div className="mb-4">
                  <span>Current Unit</span>

                  <h6>{ticketData?.unit?.unitName}</h6>
                </div> */}
                <div className="">
                  <span>Ticket Status</span>
                  <h6
                    style={{
                      color: getColorForStatus(
                        ticketData?.currentStatus?.ticketStatusKey
                      ),
                      fontSize: "0.8rem",
                      fontWeight: "500",
                    }}
                  >
                    {ticketData?.currentStatus?.ticketStatusName}
                  </h6>
                </div>
                <span
                  style={{ fontSize: "0.7rem" }}
                  className="cursor-pointer text-primary"
                  onClick={() => {
                    initEdit(ticketData?.id);
                  }}
                >
                  update ticket status
                  {/* <VscEdit className="ml-1 mb-1" /> */}
                </span>
              </Card.Body>
              {isEditing && (
                <Card.Footer>
                  {!isSaving && (
                    <input
                      type="button"
                      onClick={() => {
                        setIsEditing(false);
                      }}
                      className="btn btn-default"
                      value="Cancel"
                    />
                  )}
                  <button
                    className="btn btn-success"
                    onClick={() => {
                      updateTicketStatus(
                        val,
                        ticketData?.id,
                        "updating the status"
                      );
                    }}
                    disabled={isSaving ? true : false}
                  >
                    {isSaving ? "Saving..." : "Save"}
                  </button>
                </Card.Footer>
              )}
            </Card>
            <Card className="border rounded">
              <Card.Header className="bg-transparent border rounded font-weight-bold">
                Customer Information
              </Card.Header>
              <Card.Body>
                <div className="mb-4">
                  <span>Name</span>
                  <h6 className="text-primary cursor-pointer">
                    {ticketData?.customer?.customerName}
                  </h6>
                </div>
                <div
                  className="mb-4"
                  onClick={() => {
                    handlePolicyNumClick(ticketData?.policy?.policyNumber);
                  }}
                >
                  <span>Policy Number</span>
                  <h6
                    className="text-primary cursor-pointer"
                    // onClick={handlePolicyNoClick(ticketData?.policy?.policyNumber)}
                  >
                    {ticketData?.policyNumber}
                  </h6>
                  {fetchingPolicyInfo && (
                    <p>
                      <span
                        class="spinner-border spinner-border-sm mr-1 text-primary"
                        role="status"
                      ></span>
                      Getting policy 360
                    </p>
                  )}
                </div>
                <div className="mb-4">
                  <span>Email</span>
                  <h6>{ticketData?.customer?.customerEmail}</h6>
                </div>
                <div>
                  <span>Phone</span>
                  <h6>{ticketData?.customer?.customerPhone}</h6>
                </div>
              </Card.Body>
            </Card>
          </div>

          <Modal
            show={isClosing}
            onHide={handleCloseTicketModal}
            backdrop="static"
            keyboard={false}
            animation
            centered
            size="sm"
          >
            <Modal.Header closeButton>
              <Modal.Title>Close ticket</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Are you sure you want to close this ticket?</p>

              <Modal.Footer>
                {!isLoadingItem && (
                  <input
                    type="button"
                    onClick={handleCloseTicketModal}
                    className="btn btn-default"
                    value="No"
                  />
                )}
                <button
                  className="btn btn-primary btn-main"
                  onClick={() => {
                    handleCloseTicket(ticketData?.id);
                    console.log(data);
                  }}
                  disabled={isLoadingItem}
                >
                  {isLoadingItem ? "Closing..." : "Yes"}
                </button>
              </Modal.Footer>
            </Modal.Body>
          </Modal>

          <Modal
            show={isReassignModal}
            onHide={handleCloseReassignModal}
            backdrop="static"
            keyboard={false}
            animation
            centered
            size="sm"
          >
            <Modal.Header closeButton>
              <Modal.Title>Confirm</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>{`Are you sure you want to reassign this ticket to ${selectedAssignee?.fullname}?`}</p>

              <Modal.Footer>
                {!isLoadingItem && (
                  <input
                    type="button"
                    onClick={handleCloseReassignModal}
                    className="btn btn-default"
                    value="No"
                  />
                )}
                <button
                  className="btn btn-primary btn-main"
                  onClick={() => {
                    handleReassignTicket(selectedAssignee?.id);
                    // console.log(data);
                  }}
                  disabled={isLoadingItem}
                >
                  {isLoadingItem ? "Reassigning..." : "Yes"}
                </button>
              </Modal.Footer>
            </Modal.Body>
          </Modal>

          <Modal
            show={isAddingNote}
            onHide={handleCloseAddingNoteModal}
            backdrop="static"
            keyboard={false}
            animation
            // centered
            size="md"
          >
            <Modal.Header closeButton>
              <Modal.Title>Add Note</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-group col-12">
                <textarea
                  rows={6}
                  type="text"
                  className="form-control"
                  value={note}
                  placeholder="Type Something..."
                  onChange={(e) => setNote(e.target.value)}
                />
              </div>

              <Modal.Footer>
                {!isLoadingItem && (
                  <input
                    type="button"
                    onClick={handleCloseAddingNoteModal}
                    className="btn btn-default"
                    value="Close"
                  />
                )}
                <button
                  className="btn btn-primary btn-main"
                  onClick={() => {
                    handleAddNote();
                  }}
                  disabled={isLoadingItem}
                >
                  {isLoadingItem ? "Saving..." : "Save"}
                </button>
              </Modal.Footer>
            </Modal.Body>
          </Modal>

          <Modal
            show={modalState}
            onHide={handleCloseFormModal}
            backdrop="static"
            keyboard={false}
            size="md"
          >
            <Modal.Header closeButton>
              <Modal.Title>{modalTitle}</Modal.Title>
            </Modal.Header>

            <TicketForm
              handleCloseModal={handleCloseFormModal}
              formID={formID}
              formActionType={formActionType}
              onSubmit={onSubmit}
              forms={forms}
              history={history}
              setModalState={setModalState}
            />
          </Modal>

          <Modal
            show={modalStatusUpdate}
            onHide={closeModalUpdate}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>{modalTitleUpdate}</Modal.Title>
            </Modal.Header>

            <UpdateStatus
              handleCloseModal={closeModalUpdate}
              formActionType={formActionType}
              onStatusUpdateSubmit={onStatusUpdateSubmit}
              query={query}
              // isExport={isExport}
              tfsUpdateStatus={tfsUpdateStatus}
              resetUpdate={closeModalUpdate}
              queryClient={queryClient}
              queryName={queryName}
            />
          </Modal>

          <Modal
            show={isForward}
            onHide={handleCloseForwardModal}
            backdrop="static"
            keyboard={false}
            animation
            centered
            size="sm"
          >
            <Modal.Header closeButton>
              <Modal.Title>Forward ticket</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Are you sure you want to forward this ticket?</p>

              <Modal.Footer>
                {!isLoadingItem && (
                  <input
                    type="button"
                    onClick={handleCloseForwardModal}
                    className="btn btn-default"
                    value="No"
                  />
                )}
                <button
                  className="btn btn-primary btn-main"
                  onClick={() => {
                    handleForwardTicket(ticketData?.id, unitId);
                    // console.log(data);
                  }}
                  disabled={isLoadingItem}
                >
                  {isLoadingItem ? "Forwarding..." : "Yes"}
                </button>
              </Modal.Footer>
            </Modal.Body>
          </Modal>
        </div>
      )}
    </>
  );
};

export default TicketDetails;
