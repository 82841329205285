import * as React from 'react';
import AppContext from '../../context';
import { Modal } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { ErrorMsg } from '../../components';

export default function SetPasswordForm({ handleCloseModal, onSetPasswordSubmit, forms, _id }) {
    const { register, handleSubmit, formState: { errors } } = forms;

    return (<>
        <form id="form-set-password" onSubmit={handleSubmit(val => onSetPasswordSubmit({ ...val, _id }))}>

            <Modal.Body>
                <div className="row">
                    <div className="form-group col-12">
                        <label>Set Password</label>
                        <input type="password" className="form-control"
                            placeholder="Password"
                            {...register('password', {
                                required: "Password is required!"
                            })}
                        />
                        <ErrorMsg errors={errors} name="password" />
                    </div>

                    <div className="form-group col-12">
                        <input type="password" className="form-control"
                            placeholder="Auth Passcode"
                            {...register('passcode', {
                                required: "Passcode is required!"
                            })}
                        />
                        <ErrorMsg errors={errors} name="passcode" />
                    </div>

                </div>
            </Modal.Body>

            <Modal.Footer>
                <input type="button" onClick={handleCloseModal} className="btn btn-default" value="Cancel" />
                <button className="btn btn-success btn-main" type="submit">Save</button>
            </Modal.Footer>
        </form>
    </>);
}