import * as React from "react";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { store } from "react-notifications-component";
import { initialState } from "./../../context/reducer";

const commonActions = {
  sleep: async (ms) => new Promise((resolve) => setTimeout(resolve, ms)),
  setPageTitle: (dispatch, title) =>
    dispatch({ type: "SET_PAGE_TITLE", title }),
  togglePageTitle: (dispatch, showPageTitle = true) =>
    dispatch({ type: "SHOW_PAGE_TITLE", showPageTitle }),
  setCurrentPath: (dispatch, pathname) =>
    dispatch({ type: "SET_PATH_NAME", pathname }),
  setNA: (string) =>
    string ? string : <span className="text-muted">N/A</span>,
  setQueryString: (query) => {
    query =
      Object.keys(query).length === 0 && query.constructor === Object
        ? ""
        : "?" +
          Object.keys(query)
            .map((key) => key + "=" + query[key])
            .join("&");
    return query;
  },
  useQueryString: () => {
    return new URLSearchParams(useLocation().search);
  },
  setStateData: (dispatch, name, value) => {
    dispatch({ type: "SET_STATE", name, value });
  },
  emailRegex: (email) => {
    let re = /\S+@\S+\.\S+/;
    return re.test(email);
  },
  formatDate: (date, dateFormat = "ll") => moment(date).format(dateFormat),
  formatDateInput: (date, inputFormat = "DD-MM-YYYY") =>
    moment(date, inputFormat).format("ll"),
  formatDateBr: (date) => (
    <>
      {moment(date).format("ll")}
      <br />
      {moment(date).format("LT")}
    </>
  ),
  //formatDateBr: (date) => `${moment(date).format('ll')}<br/>${moment(date).format('LT')}`,
  notify: (type, title, message) => {
    store.addNotification({
      title,
      message: <div dangerouslySetInnerHTML={{ __html: message }} />,
      type,
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        showIcon: true,
        duration: 4000,
        onScreen: true,
        pauseOnHover: true,
      },
    });
  },
  checkObject: (obj) => obj && Object.entries(obj).length > 0,
  checkObjectData: (obj) =>
    obj &&
    Object.entries(obj).length > 0 &&
    Object.values(obj).some((dt) => dt),
  compareObject: (obj, source) =>
    Object.keys(source).every(
      (key) => obj.hasOwnProperty(key) && obj[key] === source[key]
    ),
  checkArray: (obj) => obj && Array.isArray(obj),
  checkArrayData: (obj) => obj && Array.isArray(obj) && obj.length > 0,
  objectToArray: async (obj, key = "key", label = "label") =>
    await Promise.all(
      Object.keys(obj).map((k) => ({ [key]: k, [label]: obj[k] }))
    ),
  isSearchQuery: (obj) => {
    return (
      obj &&
      Object.entries(obj).length > 0 &&
      Object.values(obj).some((dt) => dt) &&
      Object.keys(obj).some((dt) => dt !== "page")
    );
  },
  capitalize: (str) => {
    str = String(str).trim();
    if (str) {
      //console.log(str, 'str__format_____')
      return str
        .split(" ")
        .filter((word) => word !== "")
        .map((word) => {
          let string = word.toLowerCase();
          const cap = string[0].toString().toUpperCase();
          //console.log(cap, 'cap___')
          string = cap + string.slice(1);
          return string;
        })
        .join(" ");
    }

    //console.log(str, 'str_____')
    return str;
  },
  dataURItoBlob: (dataURI) => {
    let byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0)
      byteString = atob(dataURI.split(",")[1]);
    else byteString = unescape(dataURI.split(",")[1]);

    // separate out the mime component
    let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to a typed array
    let ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  },
  randomString: (length = 8) => {
    let result = "";
    const characters = "abcdefghijklmnopqrstuvwxyz";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  },
  arrayEquals: (a, b) => {
    return (
      Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index])
    );
  },
  numberFormat: (value, toFixed = 2) =>
    Number(value)
      .toFixed(toFixed)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,"),
  frequency: (abbr) => {
    let freq = "M";
    switch (abbr) {
      case "M":
        freq = "Monthly";
        break;
      case "Q":
        freq = "Quarterly";
        break;
      case "A":
        freq = "Annual";
        break;
      case "S":
        freq = "Semi-Annual";
        break;
      default:
        freq = "Monthly";
    }
    return freq;
  },
  dateDiff: (from, to, by = "years") => {
    console.log(commonActions.capitalize("sdfsdfs"), "capitalize...");
    let a = moment(from, "DD-MM-YYY");
    let b = moment(to, "DD-MM-YYY");
    return b.diff(a, `${by}`);
  },
  policyDateDiff: (from, to, by = "months") => {
    let a = moment(from, "DD-MM-YYY");
    let b = moment(to, "DD-MM-YYY");
    const diff = b.diff(a, by);
    const humanize = moment.duration(diff, by).humanize();
    return commonActions.capitalize(humanize);
  },
  /* getUserType(type) {
        let user_type = initialState.userTypes.find(dt => dt.key === type);
        return user_type?.label;
    }, */
  badgeToggle: (status, title) => {
    return (
      <span
        className={`badge badge-${status ? "success" : "danger"} font-size-12`}
      >
        {status ? title[0] : title[1]}
      </span>
    );
  },
  bytesToSize: (bytes) => {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes == 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  },
  addDays: (date, number = 1) => {
    const newDate = new Date(date);
    return new Date(newDate.setDate(newDate.getDate() + number));
  },
  sortArray: (array, param1, param2) => {
    array.sort(function (a, b) {
      if (param1 < param2) {
        return -1;
      }
      if (param1 > param2) {
        return 1;
      }
      return 0;
    });
  },
};

export default commonActions;
