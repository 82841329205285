import * as React from "react";
import { Switch, useHistory } from "react-router-dom";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import AppContext from "./context";
import { initialState, AppReducer } from "./context/reducer";
import {
  errorResponseActions,
  formActions,
  authActions,
  commonActions,
  errorAuthActions,
} from "./context/actions";
import AppRoute from "./components/AppRoute";
import { bootstraps, branches, companies, roles } from "./services";

import { Modal } from "react-bootstrap";
import useModal from "./hooks/useModal";

import Master from "./layout";
import route_list from "./routes";

import 'react-quill/dist/quill.snow.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchAllOnWindowFocus: false,
      refetchOnWindowFocus: false,
    },
  },
});

export default function App() {
  const [state, dispatch] = React.useReducer(AppReducer, initialState);

  return (
    <QueryClientProvider client={queryClient}>
      <NavStack state={state} dispatch={dispatch} />
    </QueryClientProvider>
  );
}

const NavStack = ({ state, dispatch }) => {
  let history = useHistory();
  let userToken;
  userToken = localStorage.getItem(process.env.REACT_APP_USER_TOKEN) || null;
  const [errorStatus, setErrorStatus] = React.useState(null);
  const [routes] = React.useState(route_list);
  const {
    modalState,
    showModal,
    modalTitle,
    setModalTitle,
    closeModal,
    modalContent,
    setModalContent,
    modalSize,
    setModalSize,
    className,
    setClassName,
  } = useModal();
  const [ticketQueryName] = React.useState("tickets");

  /* Placeholder bootstrapping */
  // const [userToken] = React.useState("token");
  const [userTypes] = React.useState({});
  const [userData] = React.useState({});
  // React.useEffect(() => {
  //   dispatch({
  //     type: "APP_READY",
  //     userToken,
  //     userData,
  //     userTypes,
  //     branchesData,
  //     rolesData,
  //     companiesData,
  //   });
  // }, []);
  /* Remove later */

  const { isLoading: userDataLoading } = useQuery(
    "bootstrap-data",
    bootstraps,
    {
      retry: 0,
      manual: true,
      enabled: Boolean(userToken),
      //refetchInterval: 50000,
      onSuccess: async ({
        user,
        companies,
        branches,
        roles,
        ticketTypes,
        ticketStatuses,
        ticketCategories,
        ticketSubCategories,
        channels,
        departments,
        units,
        team_mates,
      }) => {
        dispatch({
          type: "APP_READY",
          userToken,
          userData: user,
          companiesData: companies,
          branchesData: branches,
          rolesData: roles,
          ticketTypesData: ticketTypes,
          ticketStatusesData: ticketStatuses,
          ticketCategoriesData: ticketCategories,
          ticketSubCategoriesData: ticketSubCategories,
          channelsData: channels,
          departmentsData: departments,
          unitsData: units,
          teamMatesData: team_mates,
        });
      },
      onError: (error) => {
        const eS = errorAuthActions.errorResponse({ error, dispatch, history });
        if (eS) {
          setErrorStatus(eS);
        }
      },
    }
  );

  React.useEffect(() => {
    if (!userToken && !userDataLoading) {
      dispatch({ type: "APP_READY", userToken });
    }
  }, [userToken, userDataLoading, dispatch]);

  const handleCloseModal = () => {
    closeModal();
  };

  const initModal = ({
    content = null,
    title = null,
    size = "md",
    className = "",
  }) => {
    setModalTitle(title);
    setModalContent(content);
    showModal();
    setModalSize(size);
    setClassName(className);
  };

  const [policy360, setPolicy360] = React.useState();
  const [phonePolicies, setPhonePolicies] = React.useState();
  const [ticket360, setTicket360] = React.useState();

  return (
    <AppContext.Provider
      value={{
        dispatch,
        ...state,
        ...authActions,
        ...errorResponseActions,
        ...formActions,
        ...commonActions,
        handleCloseModal,
        initModal,
        policy360,
        setPolicy360,
        ticket360,
        setTicket360,
        phonePolicies,
        setPhonePolicies,
        ticketQueryName,
      }}
    >
      {state.appIsReady ? (
        <Master>
          <Switch>
            {routes.map(({ exact, path, component, auth, pageTitle }) => (
              <AppRoute
                key={path}
                path={path}
                exact={exact}
                component={component}
                auth={auth}
                pageTitle={pageTitle}
              />
            ))}
          </Switch>

          <Modal
            show={modalState}
            onHide={handleCloseModal}
            animation={false}
            backdrop="static"
            keyboard={false}
            size={modalSize}
            className={className}
          >
            {modalTitle && (
              <Modal.Header closeButton>
                <Modal.Title>{modalTitle}</Modal.Title>
              </Modal.Header>
            )}
            {modalContent}
          </Modal>
        </Master>
      ) : (
        <>
          {errorStatus ? (
            <div style={{ textAlign: "center" }}>
              {/* <h4>{errorStatus?.errorHeader}</h4>
      {errorStatus?.errMsg}<br />
      {errorStatus?.errCode} */}
              Error loading modules!
            </div>
          ) : (
            // <>loading app...</>
            <div
              style={{
                width: "100vw",
                height: "100vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="200px"
                width="200px"
                viewBox="0 0 200 200"
                class="pencil"
              >
                <defs>
                  <clipPath id="pencil-eraser">
                    <rect height="30" width="30" ry="5" rx="5"></rect>
                  </clipPath>
                </defs>
                <circle
                  transform="rotate(-113,100,100)"
                  stroke-linecap="round"
                  stroke-dashoffset="439.82"
                  stroke-dasharray="439.82 439.82"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  r="70"
                  class="pencil__stroke"
                ></circle>
                <g transform="translate(100,100)" class="pencil__rotate">
                  <g fill="none">
                    <circle
                      transform="rotate(-90)"
                      stroke-dashoffset="402"
                      stroke-dasharray="402.12 402.12"
                      stroke-width="30"
                      stroke="hsl(223,90%,50%)"
                      r="64"
                      class="pencil__body1"
                    ></circle>
                    <circle
                      transform="rotate(-90)"
                      stroke-dashoffset="465"
                      stroke-dasharray="464.96 464.96"
                      stroke-width="10"
                      stroke="hsl(223,90%,60%)"
                      r="74"
                      class="pencil__body2"
                    ></circle>
                    <circle
                      transform="rotate(-90)"
                      stroke-dashoffset="339"
                      stroke-dasharray="339.29 339.29"
                      stroke-width="10"
                      stroke="hsl(223,90%,40%)"
                      r="54"
                      class="pencil__body3"
                    ></circle>
                  </g>
                  <g
                    transform="rotate(-90) translate(49,0)"
                    class="pencil__eraser"
                  >
                    <g class="pencil__eraser-skew">
                      <rect
                        height="30"
                        width="30"
                        ry="5"
                        rx="5"
                        fill="hsl(223,90%,70%)"
                      ></rect>
                      <rect
                        clip-path="url(#pencil-eraser)"
                        height="30"
                        width="5"
                        fill="hsl(223,90%,60%)"
                      ></rect>
                      <rect
                        height="20"
                        width="30"
                        fill="hsl(223,10%,90%)"
                      ></rect>
                      <rect
                        height="20"
                        width="15"
                        fill="hsl(223,10%,70%)"
                      ></rect>
                      <rect
                        height="20"
                        width="5"
                        fill="hsl(223,10%,80%)"
                      ></rect>
                      <rect
                        height="2"
                        width="30"
                        y="6"
                        fill="hsla(223,10%,10%,0.2)"
                      ></rect>
                      <rect
                        height="2"
                        width="30"
                        y="13"
                        fill="hsla(223,10%,10%,0.2)"
                      ></rect>
                    </g>
                  </g>
                  <g
                    transform="rotate(-90) translate(49,-30)"
                    class="pencil__point"
                  >
                    <polygon
                      points="15 0,30 30,0 30"
                      fill="hsl(33,90%,70%)"
                    ></polygon>
                    <polygon
                      points="15 0,6 30,0 30"
                      fill="hsl(33,90%,50%)"
                    ></polygon>
                    <polygon
                      points="15 0,20 10,10 10"
                      fill="hsl(223,10%,10%)"
                    ></polygon>
                  </g>
                </g>
              </svg>
              <h4>LOADING CRM 360</h4>
            </div>
          )}
        </>
      )}
    </AppContext.Provider>
  );
};
