import * as React from "react";
import { Link, withRouter, useHistory } from "react-router-dom";
import AppContext from "../../context";
import Search from "./search";
import usePageSetup from "../../hooks/usePageSetup";
import useRouter from "../../hooks/useRouter";
import {
  users,
  userAdd,
  userEdit,
  userDelete,
  branches as branchesList,
  ticket_types,
  get_assignments,
  user_actions,
  userUpload,
  userSetPassword,
  user,
  tickets,
  update_ticket_status,
  assign_ticket,
} from "../../services";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { Paginate, PaginateWrapper } from "../../components";
import useFormState from "../../hooks/useFormState";
import { useForm } from "react-hook-form";
import useModal from "../../hooks/useModal";
import { Col, Dropdown, Modal, Row } from "react-bootstrap";
// import UserDetails from "./details";
import UploadUser from "./upload";
import SetPasswordForm from "./form-set-password";
import {
  PiDotOutlineFill,
  PiDotsThreeOutlineVerticalFill,
} from "react-icons/pi";
import UserForm from "./form";
import UpdateStatus from "./updateStatus";
import { Button } from "react-bootstrap";
import { IoFilterOutline } from "react-icons/io5";
import Assigned from "./assigned";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import InProgress from "./inProgress";
import NeedFeedback from "./needFeedback";
import Resolved from "./resolved";
import Closed from "./closed";
import All from "./all";
import Forwarded from "./forwarded";

const Tickets = ({ history }) => {
  const {
    dispatch,
    initModal,
    handleCloseModal,
    pageTitle,
    setQueryString,
    formatDateBr,
    currentPath,
    isSearchQuery,
    errorResponse,
    checkArray,
    checkArrayData,
    getUserType,
    badgeToggle,
    setStateData,
    formActionType,
    notify,
    userData,
    ticketQueryName,
    setTicket360,
    ticketStatusesData,
  } = React.useContext(AppContext);
  const router = useRouter();

  const queryClient = useQueryClient();
  const [value, setValue] = React.useState("1");
  const [query, setQuery] = React.useState(router.query);
  const [defaultValues, setDefaultValues] = React.useState({});
  const [branches, setBranches] = React.useState([]);
  const [ticketTypes, setTicketTypes] = React.useState([]);
  const [assignments, setAssignments] = React.useState([]);
  const [filter, setFiler] = React.useState("");
  const [formID] = React.useState("form-user");
  const { toggleFormState: toggleFormStateUser } = useFormState(formID);
  const { toggleFormState: toggleFormStateUserUpload } =
    useFormState("form-upload-user");
  const { toggleFormState: toggleFormStateSetPassword } =
    useFormState("form-set-password");
  const { toggleFormState: tfsSearch } = useFormState("form-search");
  const { toggleFormState: tfsUpdateStatus } =
    useFormState("form-update-status");
  const forms = useForm({ defaultValues });
  const formsUpload = useForm();
  const formsPassword = useForm();
  const [isAssigning, setIsAssigning] = React.useState(false);

  const {
    modalState: modalStateForm,
    modalTitle: modalTitleForm,
    setModalTitle: setModalTitleForm,
    closeModal: closeModalForm,
    showModal: showModalForm,
  } = useModal();

  const {
    modalState: modalStateSearch,
    modalTitle: modalTitleSearch,
    setModalTitle: setModalTitleSearch,
    closeModal: closeModalSearch,
    showModal: showModalSearch,
  } = useModal();

  const {
    modalState: modalStatusUpdate,
    modalTitle: modalTitleUpdate,
    setModalTitle: setModalTitleUpdate,
    closeModal: closeModalUpdate,
    showModal: showModalUpdate,
  } = useModal();

  usePageSetup({
    breadcrumb: [{ to: null, label: pageTitle }],
  });

  /* export query */
  const [queryExport, setQueryExport] = React.useState({});
  const [exportData, setExportData] = React.useState([]);
  const [exporting, setExporting] = React.useState(false);
  const [exportReady, setExportReady] = React.useState(false);
  const [toggleCloseBtn, setToggleCloseBtn] = React.useState(true);
  const [isExport, setIsExport] = React.useState(false);
  const [active, setActive] = React.useState("open");

  React.useEffect(() => {
    console.log(query, "query_____");
  }, [query]);

  const onSearchSubmit = (data) => {
    closeModalSearch();
    setQuery(data);
    history.push(`${currentPath}${setQueryString(data)}`);
  };

  const handleCloseFormModal = () => {
    closeModalForm();
    forms.reset();
  };

  const handleCloseUpdateFormModal = () => {
    closeModalUpdate();
    forms.reset();
  };

  React.useEffect(() => {
    setQuery(router?.query);
    return (_) => setQuery({});
  }, [router?.query]);

  /* Add data */
  const { mutate: handleSubmitAdd } = useMutation((value) => userAdd(value), {
    onSuccess: () => {
      notify("success", "Data Added", "New user successfully created!");
    },
    onError: (error) => errorResponse({ error, dispatch, history, forms }),
    onSettled: async (data, error, variables, context) => {
      //console.log(error, 'error....')
      await queryClient.refetchQueries([ticketQueryName, query]);
      if (!error) handleCloseFormModal();
      toggleFormStateUser(false);
    },
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  /* Edit data */
  const { mutate: handleSubmitEdit } = useMutation(
    ({ id, values }) => userEdit({ id, values }),
    {
      onSuccess: () => {
        handleCloseModal();
        notify("success", "Data Modified", "User successfully modified!");
      },
      onError: (error) => errorResponse({ error, dispatch, history, forms }),
      onSettled: async (data, error, variables, context) => {
        await queryClient.refetchQueries([ticketQueryName, query]);
        if (!error) handleCloseFormModal();
        toggleFormStateUser(false);
      },
    }
  );

  const { mutate: handleStatusUpdate } = useMutation(
    ({ id, values }) => update_ticket_status({ id, values }),
    {
      onSuccess: () => {
        handleCloseModal();
        notify("success", "Data Modified", "Status successfully modified!");
      },
      onError: (error) => errorResponse({ error, dispatch, history, forms }),
      onSettled: async (data, error, variables, context) => {
        await queryClient.refetchQueries([ticketQueryName, query]);
        if (!error) handleCloseUpdateFormModal();
        tfsUpdateStatus(false);
      },
    }
  );

  const onSubmit = (data) => {
    toggleFormStateUser(true, "saving...");
    if (formActionType === 1) {
      handleSubmitAdd({ ...data });
    } else {
      const { email, ...values } = data;
      handleSubmitEdit({ id: formActionType, values: { ...values } });
    }
  };

  const onStatusUpdateSubmit = (data) => {
    tfsUpdateStatus(true, "Updating...");
    console.log(formActionType);
    handleStatusUpdate({ id: formActionType, values: { ...data } });
    setQuery(data);
  };

  const initSearch = (isExport = false) => {
    setModalTitleSearch(isExport ? "Filter Export.." : "Search..");
    setIsExport(isExport);
    showModalSearch();
  };

  /* Export data */
  useQuery(
    ["export-users", queryExport],
    () => users(setQueryString(queryExport)),
    {
      retry: 0,
      manual: true,
      enabled: exporting,
      refetchInterval: 1000,
      onSuccess: ({ data, nextPage }) => {
        //console.log(data, 'export data...');
        data.length > 0 && setExportData([...exportData, ...data]);

        if (nextPage) {
          setQueryExport({ ...queryExport, page: queryExport.page + 1 });
        } else {
          setExporting(false);
          setExportReady(true);
          setQueryExport({});
          tfsSearch(false);
        }
      },
      onError: (error) => errorResponse({ error, history, dispatch }),
    }
  );

  const onExportSubmit = (values) => {
    setToggleCloseBtn(false);
    setExportData([]);
    setQueryExport({ ...values, page: 1 });
    setExporting(true);
  };

  const resetExport = () => {
    closeModalSearch();
    setToggleCloseBtn(true);
    setExportData([]);
    setQueryExport({});
    setExporting(false);
    setExportReady(false);
    tfsSearch(false);
    setIsExport(false);
    history.push(currentPath);
  };

  return (
    <>
      <div className="row mb-3">
        <div className="col-sm-7 d-flex flex-row">
          <div
            className="btn-group mr-2"
            role="group"
            aria-label="Basic example"
          >
            <button
              type="button"
              onClick={() => initSearch(false)}
              className="btn border-dark btn-rounded waves-effect waves-light"
            >
              <IoFilterOutline />
            </button>

            {isSearchQuery(router?.query) && (
              <>
                <button
                  type="button"
                  onClick={() => history.push(currentPath)}
                  className="btn btn-success btn-rounded waves-effect waves-light"
                >
                  Clear{" "}
                  <i className="bx bx-x search-icon font-size-16 align-middle"></i>
                </button>
              </>
            )}
          </div>
        </div>
        <div className="col-sm-5 text-sm-right">
          <button
            type="button"
            onClick={() => {
              setIsAssigning(true);
              assign_ticket()
                .then((res) => {
                  console.log(res);
                  notify(
                    "success",
                    "Tickets assigned",
                    "Tickets have been successfully assigned to you, well done."
                  );
                  queryClient.refetchQueries([ticketQueryName, query]);
                  setIsAssigning(false);
                })
                .catch((err) => {
                  notify("danger", "OOPS", err?.response?.data?.message);
                  setIsAssigning(false);
                });
            }}
            disabled={isAssigning ? true : false}
            className="btn btn-primary btn-rounded"
          >
            <i className="bx bx-plus align-middle"></i>
            {isAssigning ? "Assigning..." : "Assign me tickets"}
          </button>
        </div>
      </div>

      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="All" value="1" style={{ fontSize: "0.75rem" }} />
              <Tab label="Assigned" value="2" style={{ fontSize: "0.75rem" }} />
              <Tab
                label="In Progress"
                value="3"
                style={{ fontSize: "0.75rem" }}
              />
              <Tab
                label="Need Feedback"
                value="4"
                style={{ fontSize: "0.75rem" }}
              />
              <Tab label="Resolved" value="5" style={{ fontSize: "0.75rem" }} />
              <Tab label="Closed" value="6" style={{ fontSize: "0.75rem" }} />
              <Tab
                label="Forwarded"
                value="7"
                style={{ fontSize: "0.75rem" }}
              />
            </TabList>
          </Box>
          <TabPanel value="1" style={{ padding: 0, marginTop: "20px" }}>
            <All history={history} />
          </TabPanel>
          <TabPanel value="2" style={{ padding: 0, marginTop: "20px" }}>
            <Assigned history={history} />
          </TabPanel>
          <TabPanel value="3" style={{ padding: 0, marginTop: "20px" }}>
            <InProgress history={history} />
          </TabPanel>
          <TabPanel value="4" style={{ padding: 0, marginTop: "20px" }}>
            <NeedFeedback history={history} />
          </TabPanel>
          <TabPanel value="5" style={{ padding: 0, marginTop: "20px" }}>
            <Resolved history={history} />
          </TabPanel>
          <TabPanel value="6" style={{ padding: 0, marginTop: "20px" }}>
            <Closed history={history} />
          </TabPanel>
          <TabPanel value="7" style={{ padding: 0, marginTop: "20px" }}>
            <Forwarded history={history} />
          </TabPanel>
        </TabContext>
      </Box>

      <Modal
        show={modalStateForm}
        onHide={handleCloseFormModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{modalTitleForm}</Modal.Title>
        </Modal.Header>

        <UserForm
          handleCloseModal={handleCloseFormModal}
          formID={formID}
          formActionType={formActionType}
          branches={branches}
          ticketTypes={ticketTypes}
          allAssignments={assignments}
          onSubmit={onSubmit}
          forms={forms}
        />
      </Modal>

      <Modal
        show={modalStateSearch}
        onHide={resetExport}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{modalTitleSearch}</Modal.Title>
        </Modal.Header>

        <Search
          handleCloseModal={resetExport}
          onSearchSubmit={onSearchSubmit}
          query={query}
          isExport={isExport}
          onExportSubmit={onExportSubmit}
          tfsSearch={tfsSearch}
          resetExport={resetExport}
          exporting={exporting}
          exportData={exportData}
          exportReady={exportReady}
        />
      </Modal>
      <Modal
        show={modalStatusUpdate}
        onHide={closeModalUpdate}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{modalTitleUpdate}</Modal.Title>
        </Modal.Header>

        <UpdateStatus
          handleCloseModal={closeModalUpdate}
          formActionType={formActionType}
          onStatusUpdateSubmit={onStatusUpdateSubmit}
          query={query}
          isExport={isExport}
          tfsUpdateStatus={tfsUpdateStatus}
          resetUpdate={closeModalUpdate}
        />
      </Modal>
    </>
  );
};

export default Tickets;
