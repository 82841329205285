import * as React from "react";
import { Link, withRouter } from "react-router-dom";
import AppContext from "../../../context";
import { Button, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { ErrorMsg } from "../../../components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import ReactExport from "react-export-excel";
import { update_ticket_status } from "../../../services";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const UpdateStatus = ({
  defaultValue,
  query,
  handleCloseModal,
  onStatusUpdateSubmit,
  isExport,
  onExportSubmit,
  resetExport,
  exporting,
  exportData,
  exportReady,
  tfsUpdateStatus,
}) => {
  const {
    userData,
    checkObjectData,
    userTypes,
    checkArrayData,
    formatDate,
    getUserType,
    ticketStatusesData,
  } = React.useContext(AppContext);
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({ defaultValue });

  console.log(ticketStatusesData);

  const filteredStatuses = ticketStatusesData?.filter(
    (ticket) =>
      ticket?.ticketStatusKey !== "NEW" &&
      ticket?.ticketStatusKey !== "ASSIGNED"
  );

  return (
    <form
      id={"form-update-status"}
      onSubmit={handleSubmit(onStatusUpdateSubmit)}
    >
      {!exportReady && (
        <Modal.Body>
          <div className="row">
            <div className="form-group col-12">
              <label>New status</label>
              <select
                type="text"
                className="form-control"
                placeholder="Admin"
                {...register("status_id", {
                  required: "Status is required!",
                })}
              >
                <option></option>
                {filteredStatuses?.map((row) => (
                  <option key={row.id} value={row.id}>
                    {row.ticketStatusName}
                  </option>
                ))}
              </select>

              <ErrorMsg errors={errors} name="status_id" />
            </div>
            <div className="form-group col-12">
              <label>Comment</label>
              <textarea
                rows={6}
                type="text"
                className="form-control"
                {...register("comment", {
                  // required: "Comment is required",
                })}
              />
              <ErrorMsg errors={errors} name="comment" />
            </div>
          </div>
        </Modal.Body>
      )}
      <Modal.Footer>
        {isExport ? (
          <>
            {exportReady ? (
              <>
                <input
                  type="button"
                  onClick={resetExport}
                  className="btn btn-secondary"
                  value={
                    exportData.length > 0
                      ? "Close"
                      : "No data found, please retry"
                  }
                />
                {exportData.length > 0 && (
                  <ExcelFile
                    element={
                      <button
                        className="btn btn-success btn-download"
                        type="button"
                      >
                        Download to Excel ({exportData.length} Results)
                      </button>
                    }
                    filename={`users-${moment()}`}
                  >
                    <ExcelSheet data={exportData} name="DV Tracker Users">
                      <ExcelColumn label="Name" value="name" />
                      <ExcelColumn label="Email" value="email" />
                      <ExcelColumn
                        label="User Type"
                        value={(col) => getUserType(col?.user_type)}
                      />
                      <ExcelColumn
                        label="Agent Code"
                        value={(col) =>
                          col?.agentCode &&
                          col?.agentCode !== "false" &&
                          col?.agentCode !== "true" &&
                          col?.agentCode !== "null"
                            ? col?.agentCode
                            : ""
                        }
                      />
                      <ExcelColumn
                        label="Status"
                        value={(col) => (col?.status ? "Active" : "Inactive")}
                      />
                      <ExcelColumn
                        label="Date Created"
                        value={(col) => formatDate(col?.createdAt)}
                      />
                    </ExcelSheet>
                  </ExcelFile>
                )}
              </>
            ) : (
              <>
                <input
                  type="button"
                  onClick={handleCloseModal}
                  className="btn btn-default"
                  value="Close"
                />
                <button className="btn btn-success" type="submit">
                  {exporting ? "exporting, please wait..." : "Begin Export"}
                </button>
              </>
            )}
          </>
        ) : (
          <>
            <input
              type="button"
              onClick={handleCloseModal}
              className="btn btn-default"
              value="Close"
            />
            <button className="btn btn-success" type="submit">
              Update
            </button>
          </>
        )}
      </Modal.Footer>
    </form>
  );
};

export default UpdateStatus;
