import * as React from "react";
import { Link, withRouter, useHistory } from "react-router-dom";
import AppContext from "../../context";
import Search from "./search";
import usePageSetup from "../../hooks/usePageSetup";
import useRouter from "../../hooks/useRouter";
import {
  users,
  userAdd,
  userEdit,
  userDelete,
  branches as branchesList,
  ticket_types,
  get_assignments,
  user_actions,
  userUpload,
  userSetPassword,
  user,
  tickets,
  update_ticket_status,
  assign_ticket,
} from "../../services";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { Paginate, PaginateWrapper } from "../../components";
import useFormState from "../../hooks/useFormState";
import { useForm } from "react-hook-form";
import useModal from "../../hooks/useModal";
import { Col, Dropdown, Modal, Row } from "react-bootstrap";
// import UserDetails from "./details";
import UploadUser from "./upload";
import SetPasswordForm from "./form-set-password";
import {
  PiDotOutlineFill,
  PiDotsThreeOutlineVerticalFill,
} from "react-icons/pi";
import UserForm from "./form";
import UpdateStatus from "./updateStatus";
import { Button } from "react-bootstrap";
import { IoFilterOutline } from "react-icons/io5";

const NeedFeedback = ({ history }) => {
  const {
    dispatch,
    initModal,
    handleCloseModal,
    pageTitle,
    setQueryString,
    formatDateBr,
    currentPath,
    isSearchQuery,
    errorResponse,
    checkArray,
    checkArrayData,
    getUserType,
    badgeToggle,
    setStateData,
    formActionType,
    notify,
    userData,
    ticketQueryName,
    setTicket360,
    ticketStatusesData,
  } = React.useContext(AppContext);
  const router = useRouter();

  const queryClient = useQueryClient();

  const [query, setQuery] = React.useState({
    ...router.query,
    ticket_status: "NEED_FEEDBACK",
  });
  const [defaultValues, setDefaultValues] = React.useState({});
  const [branches, setBranches] = React.useState([]);
  const [ticketTypes, setTicketTypes] = React.useState([]);
  const [assignments, setAssignments] = React.useState([]);
  const [filter, setFiler] = React.useState("");
  const [formID] = React.useState("form-user");
  const { toggleFormState: toggleFormStateUser } = useFormState(formID);
  const { toggleFormState: toggleFormStateUserUpload } =
    useFormState("form-upload-user");
  const { toggleFormState: toggleFormStateSetPassword } =
    useFormState("form-set-password");
  const { toggleFormState: tfsSearch } = useFormState("form-search");
  const { toggleFormState: tfsUpdateStatus } =
    useFormState("form-update-status");
  const forms = useForm({ defaultValues });
  const formsUpload = useForm();
  const formsPassword = useForm();
  const [isAssigning, setIsAssigning] = React.useState(false);

  const {
    modalState: modalStateForm,
    modalTitle: modalTitleForm,
    setModalTitle: setModalTitleForm,
    closeModal: closeModalForm,
    showModal: showModalForm,
  } = useModal();

  const {
    modalState: modalStateSearch,
    modalTitle: modalTitleSearch,
    setModalTitle: setModalTitleSearch,
    closeModal: closeModalSearch,
    showModal: showModalSearch,
  } = useModal();

  const {
    modalState: modalStatusUpdate,
    modalTitle: modalTitleUpdate,
    setModalTitle: setModalTitleUpdate,
    closeModal: closeModalUpdate,
    showModal: showModalUpdate,
  } = useModal();

  usePageSetup({
    breadcrumb: [{ to: null, label: pageTitle }],
  });

  /* export query */
  const [queryExport, setQueryExport] = React.useState({});
  const [exportData, setExportData] = React.useState([]);
  const [exporting, setExporting] = React.useState(false);
  const [exportReady, setExportReady] = React.useState(false);
  const [toggleCloseBtn, setToggleCloseBtn] = React.useState(true);
  const [isExport, setIsExport] = React.useState(false);
  const [active, setActive] = React.useState("open");

  /* Page data */
  const { isLoading, isFetching, isError, data, error } = useQuery(
    [ticketQueryName, query],
    () => tickets(setQueryString(query)),
    {
      keepPreviousData: true,
      staleTime: 9000,
      onError: (error) => errorResponse({ error, history, dispatch }),
    }
  );

  console.log(data);

  function truncateTo50Characters(sentence) {
    if (sentence && sentence.length <= 40) {
      return sentence;
    } else {
      return `${sentence && sentence.slice(0, 40)}...`;
    }
  }

  /* Requery on data, query change */
  React.useEffect(() => {
    if (data?.nextPage) {
      let nextQuery = { ...query, page: data?.nextPage };
      queryClient.prefetchQuery([ticketQueryName, nextQuery], () =>
        users(setQueryString(nextQuery))
      );
    }
  }, [data, query, queryClient]);

  React.useEffect(() => {
    console.log(query, "query_____");
  }, [query]);

  const onSearchSubmit = (data) => {
    closeModalSearch();
    setQuery(data);
    history.push(`${currentPath}${setQueryString(data)}`);
  };

  // const onStatusUpdateSubmit = (data) => {
  //   closeModalSearch();
  //   setQuery(data);
  //   // history.push(`${currentPath}${setQueryString(data)}`);
  // };

  /* handle paginate data */
  const handlePageClick = ({ selected }) => {
    const page = selected + 1;
    let nQ = { ...query, page };
    console.log(nQ);
    setQuery(nQ);
    history.push(`${currentPath}${setQueryString(nQ)}`);
  };

  const handleCloseFormModal = () => {
    closeModalForm();
    forms.reset();
  };

  const handleCloseUpdateFormModal = () => {
    closeModalUpdate();
    forms.reset();
  };

  React.useEffect(() => {
    setQuery(router?.query);
    return (_) => setQuery({});
  }, [router?.query]);

  /* Add data */
  const { mutate: handleSubmitAdd } = useMutation((value) => userAdd(value), {
    onSuccess: () => {
      notify("success", "Data Added", "New user successfully created!");
    },
    onError: (error) => errorResponse({ error, dispatch, history, forms }),
    onSettled: async (data, error, variables, context) => {
      //console.log(error, 'error....')
      await queryClient.refetchQueries([ticketQueryName, query]);
      if (!error) handleCloseFormModal();
      toggleFormStateUser(false);
    },
  });

  /* Upload Users */
  const { mutate: handleSubmitUploadUsers } = useMutation(
    (value) => userUpload(value),
    {
      onSuccess: () => {
        notify("success", "Users Uploaded", "Users successfully uploaded!");
      },
      onError: (error) => errorResponse({ error, dispatch, history, forms }),
      onSettled: async (data, error, variables, context) => {
        //console.log(error, 'error....')
        await queryClient.refetchQueries([ticketQueryName, query]);
        if (!error) handleCloseModal();
        toggleFormStateUserUpload(false);
      },
    }
  );

  /* Edit data */
  const { mutate: handleSubmitEdit } = useMutation(
    ({ id, values }) => userEdit({ id, values }),
    {
      onSuccess: () => {
        handleCloseModal();
        notify("success", "Data Modified", "User successfully modified!");
      },
      onError: (error) => errorResponse({ error, dispatch, history, forms }),
      onSettled: async (data, error, variables, context) => {
        await queryClient.refetchQueries([ticketQueryName, query]);
        if (!error) handleCloseFormModal();
        toggleFormStateUser(false);
      },
    }
  );

  /* Set password */
  const { mutate: handleSubmitSetPassword } = useMutation(
    ({ id, values }) => userSetPassword({ id, values }),
    {
      onSuccess: () => {
        handleCloseModal();
        notify(
          "success",
          "Password Modified",
          "User password successfully modified!"
        );
      },
      onError: (error) => errorResponse({ error, dispatch, history, forms }),
      onSettled: async (data, error, variables, context) => {
        await queryClient.refetchQueries([ticketQueryName, query]);
        if (!error) handleCloseFormModal();
        toggleFormStateSetPassword(false);
      },
    }
  );

  /* Delete data */
  const { mutate: handleSubmitDelete } = useMutation((id) => userDelete(id), {
    onSuccess: () => {
      notify("success", "Data Deleted", "Select data successfully deleted!");
    },
    onMutate: (id) => {
      queryClient.cancelQueries([ticketQueryName, query]);

      const previousData = queryClient.getQueryData([ticketQueryName, query]);
      const updateValue = previousData?.data;

      const removeDeleted = updateValue.filter((dt) => dt.id !== id);
      const newData = { ...previousData, data: removeDeleted };
      return queryClient.setQueryData([ticketQueryName, query], newData);
    },
    onError: (error) => errorResponse({ error, dispatch, history }),
    onSettled: async () =>
      await queryClient.refetchQueries([ticketQueryName, query]),
  });

  /* User actions */
  const { mutate: handleSubmitAction } = useMutation(
    (value) => user_actions(value),
    {
      onSuccess: () => {
        notify(
          "success",
          "User Account Updated",
          "Account updated successfully!"
        );
      },
      onError: (error) => errorResponse({ error, dispatch, history }),
      onSettled: async (data, error, variables, context) => {
        await queryClient.refetchQueries([ticketQueryName, query]);
        if (!error) handleCloseModal();
        //toggleFormStateUser(false);
      },
    }
  );

  const { mutate: handleStatusUpdate } = useMutation(
    ({ id, values }) => update_ticket_status({ id, values }),
    {
      onSuccess: () => {
        handleCloseModal();
        notify("success", "Data Modified", "Status successfully modified!");
      },
      onError: (error) => errorResponse({ error, dispatch, history, forms }),
      onSettled: async (data, error, variables, context) => {
        await queryClient.refetchQueries([ticketQueryName, query]);
        if (!error) handleCloseUpdateFormModal();
        tfsUpdateStatus(false);
      },
    }
  );

  const onSubmit = (data) => {
    toggleFormStateUser(true, "saving...");
    if (formActionType === 1) {
      handleSubmitAdd({ ...data });
    } else {
      const { email, ...values } = data;
      handleSubmitEdit({ id: formActionType, values: { ...values } });
    }
  };

  const onStatusUpdateSubmit = (data) => {
    tfsUpdateStatus(true, "Updating...");
    console.log(formActionType);
    handleStatusUpdate({ id: formActionType, values: { ...data } });
    setQuery(data);
  };

  const initAdd = () => {
    forms.reset();
    setStateData(dispatch, "formActionType", 1);
    setModalTitleForm("Create User");
    showModalForm();
  };

  const initEdit = ({ id, statusHistories }) => {
    setStateData(dispatch, "formActionType", id);
    setModalTitleUpdate("Update status");
    // forms.setValue("status_id", statusHistories[0]?.ticketStatus);
    showModalUpdate();
  };

  const initSetPassword = ({ id, email }) => {
    formsPassword.reset();
    initModal({
      title: `Reset password
            ${email}`,
      size: "md",
      content: (
        <SetPasswordForm
          handleCloseModal={handleCloseModal}
          onSetPasswordSubmit={onSetPasswordSubmit}
          forms={formsPassword}
          id={id}
        />
      ),
    });
  };

  const onSetPasswordSubmit = ({ id, password, passcode }) => {
    toggleFormStateSetPassword(true, "saving...");
    handleSubmitSetPassword({ id: id, values: { password, passcode } });
  };

  const initDelete = (id) => {
    const conf = window.confirm(
      "You are about to delete a user.. Are you sure?"
    );
    if (!conf) return;
    handleSubmitDelete(id);
  };

  //   const initDetails = (data) => {
  //     initModal({
  //       title: data?.name,
  //       size: "md",
  //       content: (
  //         <UserDetails
  //           handleCloseModal={handleCloseModal}
  //           onUserActionSubmit={onUserActionSubmit}
  //           initSetPassword={initSetPassword}
  //           data={data}
  //         />
  //       ),
  //     });
  //   };

  const onUserActionSubmit = (id, action) => {
    const conf = window.confirm("Press Ok to continue...");
    if (!conf) return;
    handleSubmitAction({ id, action });
  };

  const initUpload = (data) => {
    initModal({
      title: "Upload User",
      size: "md",
      content: (
        <UploadUser
          handleCloseModal={handleCloseModal}
          onSubmitUpload={onSubmitUpload}
          forms={formsUpload}
        />
      ),
    });
  };

  const onSubmitUpload = ({ user_type, file }) => {
    toggleFormStateUserUpload(true, "uploading...");
    let formData = new FormData();
    formData.append("user_type", user_type);
    formData.append("file", file[0]);
    handleSubmitUploadUsers(formData);
  };

  const initSearch = (isExport = false) => {
    setModalTitleSearch(isExport ? "Filter Export.." : "Search..");
    setIsExport(isExport);
    showModalSearch();
  };

  /* Export data */
  useQuery(
    ["export-users", queryExport],
    () => users(setQueryString(queryExport)),
    {
      retry: 0,
      manual: true,
      enabled: exporting,
      refetchInterval: 1000,
      onSuccess: ({ data, nextPage }) => {
        //console.log(data, 'export data...');
        data.length > 0 && setExportData([...exportData, ...data]);

        if (nextPage) {
          setQueryExport({ ...queryExport, page: queryExport.page + 1 });
        } else {
          setExporting(false);
          setExportReady(true);
          setQueryExport({});
          tfsSearch(false);
        }
      },
      onError: (error) => errorResponse({ error, history, dispatch }),
    }
  );

  const onExportSubmit = (values) => {
    setToggleCloseBtn(false);
    setExportData([]);
    setQueryExport({ ...values, page: 1 });
    setExporting(true);
  };

  const resetExport = () => {
    closeModalSearch();
    setToggleCloseBtn(true);
    setExportData([]);
    setQueryExport({});
    setExporting(false);
    setExportReady(false);
    tfsSearch(false);
    setIsExport(false);
    history.push(currentPath);
  };

  const colorMapping = {
    ASSIGNED: "#007dcc",
    IN_PROGRESS: "#f1734f",
    NEED_FEEDBACK: "#f1b44c",
    RESOLVED: "#34c38f",
    NEW: "#343a40",
  };

  const defaultColor = "red"; // Default color for unknown values

  console.log("Assigned data", data);

  React.useEffect(() => {
    history.push(
      `${currentPath}${setQueryString({
        ...router.query,
        ticket_status: "NEED_FEEDBACK",
      })}`
    );
  }, [data]);

  const getColorForStatus = (statusKey) => {
    return colorMapping[statusKey] || defaultColor;
  };

  return (
    <>
      {isLoading ? (
        <>loading, please wait...</>
      ) : (
        <>
          {checkArray(data?.data) && (
            <>
              {!checkArrayData(data?.data) && (
                <>No tickets that needs feedback found!</>
              )}

              {checkArrayData(data?.data) && (
                <div className="table-responsive">
                  <table className="table table-centered table-hover  table-nowrap">
                    <thead className="thead-light">
                      <tr>
                        {/* <th width="1%">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="customCheck1"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customCheck1"
                            >
                              &nbsp;
                            </label>
                          </div>
                        </th> */}
                        <th width="11%">Ticket Details</th>
                        <th width="20%">Customer Details</th>
                        <th width="20%">Subject</th>
                        {/* <th width="22%">Body</th> */}
                        <th width="12%">Category</th>
                        <th width="12%" className="text-right">
                          Assgn. to
                        </th>
                        <th width="12%" className="text-right">
                          Status
                        </th>
                        <th width="12%" className="text-right">
                          Created
                        </th>
                        {/* <th width="1%" className="text-center">
                          <i className="mdi mdi-dots-horizontal font-size-18"></i>
                        </th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {data?.data?.map((row, index) => {
                        const newDate = new Date(
                          row?.createdAt
                        ).toLocaleDateString();

                        return (
                          <tr
                            key={row.id}
                            onClick={() => {
                              setTicket360(row?.id);
                              sessionStorage.setItem("nnn", row?.id);
                              history.push(
                                `/tickets/details/${row?.ticketNumber}`
                              );
                            }}
                            className="cursor-pointer"
                          >
                            {/* <td>
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="customCheck2"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="customCheck2"
                                >
                                  &nbsp;
                                </label>
                              </div>
                            </td> */}
                            <td>
                              <span
                                // onClick={() => initDetails(row)}
                                className="text-body font-weight-bold cursor-pointer"
                              >
                                {row?.ticketNumber}
                              </span>
                              <br />
                              <span className="text-muted">
                                {row?.ticketType?.ticketTypeName}
                              </span>
                            </td>
                            <td>
                              <span
                                // onClick={() => initDetails(row)}
                                className="text-body font-weight-bold cursor-pointer"
                              >
                                {row?.customer?.customerName}
                              </span>
                              <br />
                              <span className="text-muted">
                                {row?.customer?.customerEmail}
                              </span>
                            </td>
                            <td>
                              {truncateTo50Characters(row?.ticketSubject)}
                            </td>
                            <td>
                              <span
                                // onClick={() => initDetails(row)}
                                className="text-body font-weight-bold cursor-pointer"
                              >
                                {row?.ticketCategory?.ticketCategoryName}
                              </span>
                              <br />
                              <span className="text-muted">
                                {row?.ticketSubCategory?.ticketSubCategoryName}
                              </span>
                            </td>

                            <td className="text-right">
                              <span
                                // onClick={() => initDetails(row)}
                                className="text-body font-weight-bold cursor-pointer "
                              >
                                {row?.assignee?.fullname}
                              </span>
                              <br />
                              <span className="text-muted">
                                {row?.assignee?.role?.roleName}
                              </span>
                            </td>

                            <td
                              className="text-right"
                              style={{
                                color: getColorForStatus(
                                  row?.currentStatus?.ticketStatusKey
                                ),
                                fontSize: "0.8rem",
                                fontWeight: "500",
                              }}
                            >
                              {row?.currentStatus?.ticketStatusName}
                            </td>

                            <td className="text-right">{newDate}</td>

                            {/* <td className="cursor-pointer">
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="custom"
                                  className="p-0"
                                  style={{ margin: 0, border: 0 }}
                                >
                                  <PiDotsThreeOutlineVerticalFill
                                  // onClick={() => handleClick(id)}
                                  />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    onClick={() => {
                                      history.push(
                                        `/tickets/details/${row?.ticketNumber}`
                                      );
                                    }}
                                  >
                                    View details
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => {
                                      initEdit(row);
                                    }}
                                  >
                                    Update status
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td> */}
                            {/* <td className="text-center">
                              <div className="dropdown">
                                <span
                                  className="dropdown-toggle card-drop"
                                  data-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i className="mdi mdi-dots-horizontal font-size-18"></i>
                                </span>
                                <ul className="dropdown-menu dropdown-menu-right">
                                  <li>
                                    <span
                                      className="dropdown-item"
                                      onClick={() => initEdit(row)}
                                    >
                                      <i className="fas fa-pencil-alt text-success mr-1"></i>{" "}
                                      Edit
                                    </span>
                                    {userData?.user_type === "ADMIN" && (
                                      <>
                                        <span
                                          className="dropdown-item"
                                          onClick={() => initDelete(row.id)}
                                        >
                                          <i className="fas fa-trash-alt text-danger mr-1"></i>{" "}
                                          Delete
                                        </span>

                                        <span
                                          className="dropdown-item"
                                          onClick={() => initSetPassword(row)}
                                        >
                                          <i className="fas fa-lock text-danger mr-1"></i>{" "}
                                          Set Password
                                        </span>
                                      </>
                                    )}
                                  </li>
                                </ul>
                              </div>
                            </td> */}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <div>
                    <PaginateWrapper
                      data={data}
                      onPageChange={handlePageClick}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </>
      )}

      <Modal
        show={modalStateForm}
        onHide={handleCloseFormModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{modalTitleForm}</Modal.Title>
        </Modal.Header>

        <UserForm
          handleCloseModal={handleCloseFormModal}
          formID={formID}
          formActionType={formActionType}
          branches={branches}
          ticketTypes={ticketTypes}
          allAssignments={assignments}
          onSubmit={onSubmit}
          forms={forms}
        />
      </Modal>

      <Modal
        show={modalStateSearch}
        onHide={resetExport}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{modalTitleSearch}</Modal.Title>
        </Modal.Header>

        <Search
          handleCloseModal={resetExport}
          onSearchSubmit={onSearchSubmit}
          query={query}
          isExport={isExport}
          onExportSubmit={onExportSubmit}
          tfsSearch={tfsSearch}
          resetExport={resetExport}
          exporting={exporting}
          exportData={exportData}
          exportReady={exportReady}
        />
      </Modal>
      <Modal
        show={modalStatusUpdate}
        onHide={closeModalUpdate}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{modalTitleUpdate}</Modal.Title>
        </Modal.Header>

        <UpdateStatus
          handleCloseModal={closeModalUpdate}
          formActionType={formActionType}
          onStatusUpdateSubmit={onStatusUpdateSubmit}
          query={query}
          isExport={isExport}
          tfsUpdateStatus={tfsUpdateStatus}
          resetUpdate={closeModalUpdate}
        />
      </Modal>
    </>
  );
};

export default NeedFeedback;
