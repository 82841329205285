import * as React from "react";
import { Link, withRouter } from "react-router-dom";
import AppContext from "../../context";
import { useForm } from "react-hook-form";
import { ErrorMsg } from "../../components";
import useFormState from "./../../hooks/useFormState";
import { adValidate, login } from "../../services";
import logo from "./logo.png";
import { Button } from "react-bootstrap";
import { SiMicrosoftoffice } from "react-icons/si";
import { url } from "../../config/config";

const Login = ({ history }) => {
  const {
    dispatch,
    setUserData,
    validateEmail,
    errorResponse,
    updateRoute,
    baseUrl,
  } = React.useContext(AppContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();
  const { toggleFormState } = useFormState("form-login");
  const [isLoggingIn, setIsLoggingIn] = React.useState(false);

  const onSubmit = (data) => {
    toggleFormState(true, "logging you in...");
    login(data)
      .then(async ({ token, user, user_types }) => {
        setUserData(dispatch, token, user, user_types);
      })
      .catch((error) => {
        toggleFormState(false);
        errorResponse({ error, exclude: [401] });
      });
    //.then(() => toggleFormState(false));
  };

  const getAuth = async () => {
    try {
      setIsLoggingIn(true);
      const auth = await fetch(process.env.REACT_APP_REDIRECTION, {
        method: "GET",
        headers: {
          //"content-type": "application/json",
          //Accept: "application/json",
          //  Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }).then((res) => res.json());
      console.log(auth);
      window.location.href = auth.redirect_uri;
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const authCode = urlSearchParams.get("code");

    if (authCode) {
      setIsLoggingIn(true);
      let param = { code: authCode };
      adValidate(param)
        .then(async ({ token, user, user_types }) => {
          setUserData(dispatch, token, user, user_types);
          setIsLoggingIn(false);
        })
        .catch((error) => {
          toggleFormState(false);
          errorResponse({ error, exclude: [401] });
          setIsLoggingIn(false);
        });
    }
  }, [window.location.search]);

  return (
    <div className="login-container w-100 h-100 bg-primary position-absolute d-flex align-items-center justify-content-center bg-img">
      <div className="account-pages">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-6">
              <h1 className="text-white" style={{ fontSize: "2.8rem" }}>
                Customer relationship management portal
              </h1>
              <p className="text-white" style={{ fontSize: "1.1rem" }}>
                Streamlining Operations and Enhancing <br /> Customer Service
              </p>
            </div>

            <div className=" col-6 col-xl-5">
              <div className="card overflow-hidden p-3">
                <div className="card-body pt-0">
                  <div>
                    <img
                      src={logo}
                      alt="logo"
                      style={{ width: "140px" }}
                      className="mb-4"
                    />
                    {/* <div className="avatar-md profile-user-wid mb-4">
                      <h5>Log in your details</h5>
                      <p>Please provide your email and password</p>
                    </div> */}
                  </div>
                  {/* <div>
                    <img
                      src={logo}
                      alt="logo"
                      style={{ width: "140px" }}
                      className="mb-4"
                    />
                    <div className="avatar-md profile-user-wid mb-4">
                      <h5>Log in your details</h5>
                      <p>Please provide your email and password</p>
                    </div>
                  </div>
                  <div className="p-2">
                    <form id="form-login" onSubmit={handleSubmit(onSubmit)}>
                      <div className="form-group">
                        <label htmlFor="staff_id">Email Address</label>
                        <input
                          type="email"
                          className="form-control"
                          {...register("email", {
                            validate: () => validateEmail(getValues("email")),
                            //required: "Staff ID is required!"
                          })}
                          placeholder="your.address@email.com"
                        />
                        <ErrorMsg errors={errors} name="email" />
                      </div>

                      <div className="form-group">
                        <label htmlFor="userpassword">Password</label>
                        <input
                          type="password"
                          className="form-control"
                          {...register("password", {
                            required: "Password is required!",
                          })}
                          placeholder=""
                        />
                        <ErrorMsg errors={errors} name="password" />
                      </div>

                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="customControlInline"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customControlInline"
                        >
                          Remember me
                        </label>
                      </div>

                      <div className="mt-3">
                        <button
                          className="btn btn-primary btn-block waves-effect waves-light btn-main"
                          type="submit"
                        >
                          Log In
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock mr-1"></i> Forgot your
                          password?
                        </Link>
                      </div>
                      <Button
                        onClick={getAuth}
                        className="text-white"
                        style={{ backgroundColor: "#ca5010" }}
                      >
                        <SiMicrosoftoffice /> Login with Office
                      </Button>
                    </form>
                  </div> */}
                  <Button
                    onClick={getAuth}
                    className="text-white"
                    style={{ backgroundColor: "#ca5010" }}
                  >
                    <SiMicrosoftoffice />{" "}
                    {isLoggingIn ? "Logging you in..." : "Login with office365"}
                  </Button>
                </div>
              </div>
              <div className="mt-5 text-center">
                {/* <p>Don't have an account ? <a href="auth-register.html" className="font-weight-medium text-primary"> Signup now </a> </p> */}
                {/* <p>� 2020 Skote. Crafted with <i className="mdi mdi-heart text-danger"></i> by Themesbrand</p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
