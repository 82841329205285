import * as React from 'react';
import { useLocation } from 'react-router-dom';
import AppContext from '../context';
import MasterContext from '../layout/context';


const usePageSetup = ({ breadcrumb = null, submenu = null,
  rightBarTitle = null, rightBarContent = null, rightBarData1 = null, rightBarData2 = null }) => {
  const { dispatch, pageTitle } = React.useContext(AppContext);
  const { initRightBar, initSubMenu, setBreadcrumb } = React.useContext(MasterContext);

  React.useEffect(() => {
    if (submenu) initSubMenu(submenu);
    //if (rightBarContent) initRightBar({ title: rightBarTitle, content: rightBarContent });

    return _ => {
      if (submenu) initSubMenu(null);
      //if (rightBarContent) initRightBar({ content: null });
    }
  }, []);

  React.useEffect(() => {
    if (rightBarContent) initRightBar({ title: rightBarTitle, content: rightBarContent });

    return _ => {
      if (rightBarContent) initRightBar({ content: null });
    }
  }, [rightBarData1, rightBarData2])

  React.useEffect(() => {
    if (breadcrumb) setBreadcrumb(breadcrumb);
    return _ => setBreadcrumb(null);
  }, [pageTitle])

  return null;
}

export default usePageSetup;