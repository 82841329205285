import * as React from "react";
import { Link, withRouter } from "react-router-dom";
import AppContext from "../../context";
import Chart from "react-apexcharts";
import {
  summary,
  ticket_type_summary,
  ticket_status_by_dates,
  recent_tickets,
  ticket_status_summary,
} from "../../services";
import { useQuery, useMutation, useQueryClient, queryCache } from "react-query";
import { DataPlacer, Placeholder } from "../../components";
import moment from "moment";
import {
  Button,
  Col,
  Container,
  Dropdown,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { IoFilterOutline } from "react-icons/io5";
import useModal from "../../hooks/useModal";
import Form from "../branches/form";
import { useForm } from "react-hook-form";
import {
  PiDotOutlineFill,
  PiDotsThreeOutlineVerticalFill,
} from "react-icons/pi";
import CustomerForm from "../branches/newCustomerForm";

const Customers = ({ history }) => {
  const {
    dispatch,
    numberFormat,
    errorResponse,
    checkArrayData,
    userData,
    formActionType,
  } = React.useContext(AppContext);
  const [active, setActive] = React.useState("open");
  const [formID] = React.useState("form-ticket-type");
  const [defaultValues, setDefaultValues] = React.useState({});
  const [selectedCustomerId, setSelectedCustomerId] = React.useState(null);

  const {
    modalState,
    setModalState,
    modalTitle,
    setModalTitle,
    closeModal,
    showModal,
  } = useModal();
  const forms = useForm({ defaultValues });

  const onSubmit = () => {};
  const handleCloseFormModal = () => {
    //console.log('handleCloseFormModal...')
    closeModal();
    //setDefaultValues({});
    forms.reset();
  };
  const handleClick = (customerId) => {
    setSelectedCustomerId(customerId);
  };

  const customers = [
    // {
    //   id: 1,
    //   name: "Efe Samuel",
    //   phone: "07067896171",
    //   email: "efe.samuel@sanlam.com.ng",
    // },
    // {
    //   id: 2,
    //   name: "Efe Samuel",
    //   phone: "07067896171",
    //   email: "efe.samuel@sanlam.com.ng",
    // },
    // {
    //   id: 3,
    //   name: "Efe Samuel",
    //   phone: "07067896171",
    //   email: "efe.samuel@sanlam.com.ng",
    // },
    // {
    //   id: 4,
    //   name: "Efe Samuel",
    //   phone: "07067896171",
    //   email: "efe.samuel@sanlam.com.ng",
    // },
    // {
    //   id: 5,
    //   name: "Efe Samuel",
    //   phone: "07067896171",
    //   email: "efe.samuel@sanlam.com.ng",
    // },
    // {
    //   id: 6,
    //   name: "Efe Samuel",
    //   phone: "07067896171",
    //   email: "efe.samuel@sanlam.com.ng",
    // },
  ];

  return (
    <>
      <Container className="d-flex justify-content-between mb-4">
        <p></p>
        <Row className="d-flex align-items-center">
          <Col xxl="sm" className="mr-4">
            <Button
              variant="primary"
              onClick={() => {
                setModalState(true);
                setModalTitle("New Customer");
              }}
            >
              + New Customer
            </Button>
          </Col>
          <Col xxl="sm">
            <Dropdown>
              <Dropdown.Toggle variant="custom">
                <IoFilterOutline />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="#/action-1" className="text-success">
                  <PiDotOutlineFill /> Assigned
                </Dropdown.Item>
                <Dropdown.Item href="#/action-1" className="text-warning">
                  <PiDotOutlineFill /> In Progress
                </Dropdown.Item>
                <Dropdown.Item href="#/action-1" className="text-primary">
                  <PiDotOutlineFill /> Forwarded
                </Dropdown.Item>
                <Dropdown.Item href="#/action-2" className="text-danger">
                  <PiDotOutlineFill /> Need Feedback
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </Container>

      <Table hover>
        <thead>
          <tr style={{ backgroundColor: "#f7f7f7" }} className="heead">
            <th className="left">CUSTOMER</th>
            <th>PHONE</th>
            <th>EMAIL ADDRESS</th>
            <th>
              <PiDotsThreeOutlineVerticalFill />
            </th>
          </tr>
        </thead>
        <tbody>
          {customers.map((customer) => {
            const { id, name, phone, email } = customer;

            return (
              <tr key={id}>
                <td>{name}</td>
                <td>{phone}</td>
                <td>{email}</td>
                <td className="cursor-pointer">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="custom"
                      className="p-0"
                      style={{ margin: 0, border: 0 }}
                    >
                      <PiDotsThreeOutlineVerticalFill
                        onClick={() => handleClick(id)}
                      />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          history.push(`/customers/${id}`);
                        }}
                      >
                        View details
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-1">
                        View tickets
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-1">
                        Edit customer
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>

      <Modal
        show={modalState}
        onHide={handleCloseFormModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>

        <CustomerForm
          handleCloseModal={handleCloseFormModal}
          formID={formID}
          formActionType={formActionType}
          onSubmit={onSubmit}
          forms={forms}
        />
      </Modal>
    </>
  );
};

export default Customers;
