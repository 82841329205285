import * as React from "react";
import AppContext from "../../context";
import { Modal } from "react-bootstrap";
import { ErrorMsg } from "../../components";
import { userAdd } from "../../services";
import commonActions from "../../context/actions/commonActions";

export default function UserForm({
  handleCloseModal,
  onSubmit,
  errorResponse,
  formID,
  forms,
  isLoading,
  data,
}) {
  const { dispatch, branchesData, rolesData, companiesData, userData } =
    React.useContext(AppContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = forms;

  console.log(branchesData);
  console.log(rolesData);
  console.log(companiesData);
  console.log(userData);
  return (
    <>
      <form id={formID} onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="row">
            <div className="form-group col-12">
              <label>Full Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="John Doe"
                {...register("Fullname", {
                  required: "Full name is required!",
                })}
              />
              <ErrorMsg errors={errors} name="Fullname" />
            </div>
            <div className="form-group col-6">
              <label>Phone</label>
              <input
                type="text"
                className="form-control"
                placeholder="07067896171"
                {...register("UserPhone", {
                  required: "Phone is required!",
                })}
              />
              <ErrorMsg errors={errors} name="UserPhone" />
            </div>
            <div className="form-group col-6">
              <label>Email Address</label>
              <input
                type="text"
                className="form-control"
                placeholder="samuelefe.37@gmail.com"
                {...register("UserEmail", {
                  required: "Email is required!",
                })}
              />
              <ErrorMsg errors={errors} name="UserEmail" />
            </div>
            <div className="form-group col-6">
              <label>Company</label>
              <select
                type="text"
                className="form-control"
                placeholder="Admin"
                {...register("CompanyID", {
                  required: "Company is required!",
                })}
              >
                <option></option>
                {companiesData.map((company) => (
                  <option key={company.id} value={company.id}>
                    {company.CompanyName}
                  </option>
                ))}
              </select>

              <ErrorMsg errors={errors} name="CompanyID" />
            </div>
            <div className="form-group col-6">
              <label>Branch</label>
              <select
                type="text"
                className="form-control"
                placeholder="John Doe"
                {...register("BranchID", {
                  required: "Branch is required!",
                })}
              >
                <option></option>
                {branchesData.map((branch) => (
                  <option key={branch.id} value={branch.id}>
                    {branch.BranchName}
                  </option>
                ))}
              </select>

              <ErrorMsg errors={errors} name="BranchID" />
            </div>
            <div className="form-group col-12">
              <label>Role</label>
              <select
                type="text"
                className="form-control"
                placeholder="samuelefe.37@gmail.com"
                {...register("RoleID", {
                  required: "Role is required!",
                })}
              >
                <option></option>
                {rolesData.map((role) => (
                  <option key={role.id} value={role.id}>
                    {role.RoleName}
                  </option>
                ))}
              </select>

              <ErrorMsg errors={errors} name="RoleID" />
            </div>
            <div className="form-group col-12">
              <label>Password</label>
              <input
                type="password"
                className="form-control"
                placeholder=""
                {...register("UserPassword", {
                  required: "Password is required!",
                })}
              />
              <ErrorMsg errors={errors} name="UserPassword" />
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <input
            type="button"
            onClick={handleCloseModal}
            className="btn btn-default"
            value="Cancel"
          />
          <button className="btn btn-primary btn-main" type="submit">
            Save
          </button>
        </Modal.Footer>
      </form>
    </>
  );
}
