import * as React from 'react';
import { Link, withRouter, useHistory } from 'react-router-dom';
import AppContext from '../context';
import useWindowResize from '../hooks/useWindowResize';

const LayoutRightBar = ({ children, title = null }) => {
    const { dispatch, userData, logoutUser } = React.useContext(AppContext);
    const history = useHistory();

    let body = document.querySelector('body');
    const [windowWidth, windowHeight] = useWindowResize();


    React.useEffect(() => {
        body.classList.add('toggle-right-bar');
        if (windowWidth <= 1024) {
            body.classList.remove('toggle-right-bar');
        }

        return _ => body.classList.remove('toggle-right-bar');
    }, []);

    React.useEffect(() => {
        let rightNavToggle = document.getElementById('btn-toggle-right');
        rightNavToggle.addEventListener('click', () => {
            if(body.classList.contains('toggle-right-bar')){
                body.classList.remove('toggle-right-bar');
            } else {
                body.classList.add('toggle-right-bar');
        
                /* if (windowWidth <= 768) {
                    body.classList.remove('toggle-nav');
                } */
            }
        })
    }, []);

    React.useEffect(() => {
        if (windowWidth <= 1024) {
            if (body.classList.contains('toggle-right-bar')) {
                body.classList.remove('toggle-right-bar');
            }
        }
    }, [windowWidth]);

    return (<div className="nav-container-right">
        <div className="nav-right-header flex-ms">
            <div id="btn-toggle-right" className="btn-toggle-right-box flex-cc">
                <span className="btn-trb flex-cc">
                    <i className="bx bx-chevron-right"></i>
                </span>
            </div>
            <div className="nrh-title flex-m">
                <h5 className="mb-0">{title}</h5>
            </div>
        </div>

        {children}
    </div>)

}

export default LayoutRightBar;