import * as React from "react";
import AppContext from "../../context";
import { Modal } from "react-bootstrap";
import { ErrorMsg } from "../../components";
import { userAdd } from "../../services";
import commonActions from "../../context/actions/commonActions";

export default function UserForm({
  handleCloseModal,
  onSubmit,
  errorResponse,
  formID,
  forms,
  isLoading,
  data,
}) {
  const {
    dispatch,
    branchesData,
    rolesData,
    companiesData,
    userData,
    departmentsData,
    unitsData,
  } = React.useContext(AppContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = forms;

  const filteredUnit = unitsData?.filter(
    (unit) => unit?.departmentId === data?.departmentId
  );
  console.log(filteredUnit);
  return (
    <>
      <form id={formID} onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="row">
            <div className="form-group col-12">
              <label>Full Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="John Doe"
                {...register("fullname", {
                  required: "Full name is required!",
                })}
              />
              <ErrorMsg errors={errors} name="fullname" />
            </div>
            {/* <div className="form-group col-6">
              <label>Phone</label>
              <input
                type="text"
                className="form-control"
                placeholder="07067896171"
                {...register("userPhone", {
                  required: "Phone is required!",
                })}
              />
              <ErrorMsg errors={errors} name="userPhone" />
            </div> */}
            <div className="form-group col-6">
              <label>Email Address</label>
              <input
                type="text"
                className="form-control"
                placeholder="firstname.lastname@sanlam.com.ng"
                {...register("userEmail", {
                  required: "Email is required!",
                })}
              />
              <ErrorMsg errors={errors} name="userEmail" />
            </div>
            <div className="form-group col-6">
              <label>Department</label>
              <select
                type="text"
                className="form-control"
                placeholder="Admin"
                {...register("departmentId", {
                  required: "Department is required!",
                })}
              >
                <option></option>
                {departmentsData?.map((department) => (
                  <option key={department?.id} value={department?.id}>
                    {department?.departmentName}
                  </option>
                ))}
              </select>

              <ErrorMsg errors={errors} name="departmentId" />
            </div>
            <div className="form-group col-6">
              <label>Unit</label>
              <select
                type="text"
                className="form-control"
                placeholder="Admin"
                {...register("unitId", {
                  required: "Unit is required!",
                })}
              >
                <option></option>
                {unitsData?.map((unit) => (
                  <option key={unit?.id} value={unit?.id}>
                    {unit?.unitName}
                  </option>
                ))}
              </select>

              <ErrorMsg errors={errors} name="unitId" />
            </div>
            <div className="form-group col-6">
              <label>Company</label>
              <select
                type="text"
                className="form-control"
                placeholder="Admin"
                {...register("companyId", {
                  required: "Company is required!",
                })}
              >
                <option></option>
                {companiesData?.map((company) => (
                  <option key={company?.id} value={company?.id}>
                    {company?.companyName}
                  </option>
                ))}
              </select>

              <ErrorMsg errors={errors} name="companyId" />
            </div>
            <div className="form-group col-12">
              <label>Branch</label>
              <select
                type="text"
                className="form-control"
                placeholder="John Doe"
                {...register("branchId", {
                  required: "Branch is required!",
                })}
              >
                <option></option>
                {branchesData.map((branch) => (
                  <option key={branch.id} value={branch.id}>
                    {branch.branchName}
                  </option>
                ))}
              </select>

              <ErrorMsg errors={errors} name="branchId" />
            </div>
            <div className="form-group col-12">
              <label>Role</label>
              <select
                type="text"
                className="form-control"
                placeholder="samuelefe.37@gmail.com"
                {...register("roleId", {
                  required: "Role is required!",
                })}
              >
                <option></option>
                {rolesData.map((role) => (
                  <option key={role.id} value={role.id}>
                    {role.roleName}
                  </option>
                ))}
              </select>

              <ErrorMsg errors={errors} name="roleId" />
            </div>
            {/* <div className="form-group col-12">
              <label>Password</label>
              <input
                type="password"
                className="form-control"
                placeholder=""
                {...register("userPassword", {
                  required: "Password is required!",
                })}
              />
              <ErrorMsg errors={errors} name="userPassword" />
            </div> */}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <input
            type="button"
            onClick={handleCloseModal}
            className="btn btn-default"
            value="Cancel"
          />
          <button className="btn btn-primary btn-main" type="submit">
            Save
          </button>
        </Modal.Footer>
      </form>
    </>
  );
}
