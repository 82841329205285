import { useState, useEffect } from 'react';

const useModal = () => {
    const [modalState, setToggleModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalContent, setModalContent] = useState(null);
    const [modalSize, setModalSize] = useState('md');
    const [className, setClassName] = useState('');

    const setModalState = (status) => setToggleModal(status ? true : false);
    const closeModal = () => setToggleModal(false);
    const showModal = () => setToggleModal(true);

    useEffect(() => {

        return () => {
            setModalTitle('');
            setToggleModal(false);
            setModalSize('sm')
            setClassName('')
        }
    }, [])


    return { modalState, setModalState, modalTitle, setModalTitle, className, setClassName,
        closeModal, showModal, modalContent, setModalContent, modalSize, setModalSize };
};

export default useModal;