import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import { BiSearchAlt } from "react-icons/bi";
import AppContext from "../../context";

import { PiDotOutlineFill } from "react-icons/pi";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
  searchPolicyByPhoneNum,
  searchPolicyByPolicyNum,
} from "../../services";
import { useMutation } from "react-query";
import { useForm } from "react-hook-form";
import useFormState from "../../hooks/useFormState";

const Customer360 = ({ history, defaultValue }) => {
  const [formID] = React.useState("form-search");
  const { toggleFormState } = useFormState(formID);
  const {
    dispatch,
    numberFormat,
    notify,
    errorResponse,
    checkArrayData,
    userData,
    setPhonePolicies,
    phonePolicies,
    setPolicy360,
  } = React.useContext(AppContext);
  const [value, setValue] = React.useState("1");
  const [expanded, setExpanded] = React.useState(false);
  const [active, setActive] = React.useState();
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    watch,
    forms,
  } = useForm({ defaultValue });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const { mutate: handlePhoneNumSearch } = useMutation(
    (data) => searchPolicyByPhoneNum(data),
    {
      onSuccess: (res) => {
        if (res?.message !== "Policy not found") {
          notify("success", "Success", "Details fetched successfully!");
          // setPolicy360(res?.data);
          // history.push(`/policy-details`);
          console.log(res);
          // closeModalSearch();
          setPhonePolicies(res);
          return;
        }
        // setPhonePolicies(res?.code);
        notify("danger", "No Policy", res?.message);
      },
      onError: (error) => errorResponse({ error, dispatch, history, forms }),
      onSettled: async (data, error, variables, context) => {
        console.log(error, "error....");
        // await queryClient.refetchQueries([queryName, query]);
        //   if (!error) closeModalSearch();ss
        toggleFormState(false);
      },
    }
  );

  const handleSubmits = (data) => {
    toggleFormState(true, "Searching...");
    handlePhoneNumSearch({ ...data });
  };

  const { mutate: handlePolicyNumSearch } = useMutation(
    (data) => searchPolicyByPolicyNum(data),
    {
      onSuccess: (res) => {
        if (res?.message !== "Policy not found") {
          notify("success", "Success", "Policy fetched successfully!");
          setPolicy360(res?.data);
          history.push(`/policy-details/${res?.data?.details?.polPolicyNo}`);
          console.log(res);
          // closeModalSearch();
          return;
        }
        notify("danger", "No Policy", res?.message);
      },
      onError: (error) => errorResponse({ error, dispatch, history, forms }),
      onSettled: async (data, error, variables, context) => {
        console.log(error, "error....");
        setIsLoading(false);
        toggleFormState(false);
      },
    }
  );

  const handlePolicyNoClick = (policyNo) => {
    setIsLoading(true);
    handlePolicyNumSearch({ data: policyNo });
  };

  return (
    <>
      <form id={"form-search"} onSubmit={handleSubmit(handleSubmits)}>
        <InputGroup className="mb-3">
          <InputGroup.Text
            id="basic-addon1"
            className="bg-transparent"
            style={{ borderRight: "0px", borderRadius: "0px" }}
          >
            <BiSearchAlt />
          </InputGroup.Text>
          <Form.Control
            style={{ borderLeft: "0px" }}
            placeholder="Search for a customer by policy number and phone number..."
            aria-label="Search for a customer by policy number and phone number..."
            aria-describedby="basic-addon1"
            {...register("data")}
          />
          <Button
            className="btn btn-success btn-main"
            type="submit"
            style={{ borderRadius: "0px" }}
          >
            <i className="bx bx-search-alt search-icon font-size-16 align-middle"></i>
            Search
          </Button>
        </InputGroup>
      </form>

      {phonePolicies && (
        <div className="row">
          <div className="col-8">
            <div
              className="d-flex align-items-start w-100"
              //   style={{ height: "98px" }}
            >
              <div className="d-flex align-items-center flex-row justify-content-center">
                <div
                  className=" rounded d-flex align-items-center justify-content-center flex-column bg-primary"
                  style={{ width: "108px", height: "108px" }}
                >
                  <h1 className="m-0 text-white">
                    {phonePolicies[0]?.FULLNAME[0]}
                  </h1>
                </div>
                <div className="d-flex align-items-start pl-2 flex-column text-left h-100">
                  <h4 className="m-1">{phonePolicies[0]?.FULLNAME}</h4>
                  <p className="m-1 text-muted">{phonePolicies[0]?.MOBILENO}</p>
                  <p className="m-1 text-muted">
                    {phonePolicies[0]?.EMAILADDR}
                  </p>
                </div>
              </div>
            </div>

            <div className="mt-4">
              <Box sx={{ width: "100%", typography: "body1" }}>
                <TabContext value={value}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList
                      onChange={handleChange}
                      aria-label="lab API tabs example"
                    >
                      <Tab
                        label="POLICIES"
                        value="1"
                        style={{ fontSize: "0.75rem" }}
                      />
                      <Tab
                        label="TICKETS"
                        value="2"
                        style={{ fontSize: "0.75rem" }}
                      />
                      <Tab
                        label="DV REQUESTS"
                        value="3"
                        style={{ fontSize: "0.75rem" }}
                      />
                      <Tab
                        label="OTHER REQUESTS"
                        value="4"
                        style={{ fontSize: "0.75rem" }}
                      />
                    </TabList>
                  </Box>
                  <TabPanel value="1" style={{ padding: 0, marginTop: "20px" }}>
                    <div className="row">
                      {phonePolicies?.map((policy) => {
                        const { POLICYNO, POL_STATUS, POL_REF_NO } = policy;
                        return (
                          <div
                            className="col-md-4 cursor-pointer mb-3"
                            onClick={() => {
                              setExpanded(true);
                              setActive(policy);
                            }}
                          >
                            <div
                              className={
                                active?.POLICYNO === POLICYNO
                                  ? "h-100 p-2 border rounded bg-grayss border-dark"
                                  : "h-100 p-2 border rounded"
                              }
                            >
                              <h6 style={{ color: "#002C47" }}>{POLICYNO}</h6>
                              <p
                                className="mb-1"
                                style={{ fontSize: "0.7rem" }}
                              >
                                {POL_REF_NO}
                              </p>
                              <p
                                className={
                                  POL_STATUS === "S"
                                    ? "text-warning mb-1"
                                    : POL_STATUS === "A"
                                    ? "text-success mb-1"
                                    : "text-danger mb-1"
                                }
                                style={{
                                  fontSize: "0.7rem",
                                  fontWeight: "500",
                                }}
                              >
                                <PiDotOutlineFill /> {POL_STATUS}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </TabPanel>
                  <TabPanel value="2">COMING SOON</TabPanel>
                  <TabPanel value="3">COMING SOON</TabPanel>
                  <TabPanel value="4">COMING SOON</TabPanel>
                </TabContext>
              </Box>
            </div>
          </div>
          <div className="col-4">
            <div>
              <Accordion
                style={{
                  marginBottom: "10px",
                  border: "1px solid gray",
                  boxShadow: "unset",
                }}
                className="rounded"
                expanded={expanded}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  onClick={handleExpandClick}
                >
                  <h6>Policy details</h6>
                </AccordionSummary>
                {active ? (
                  <AccordionDetails>
                    <div>
                      <p
                        className="mb-1 text-muted"
                        style={{ fontSize: "0.8rem" }}
                      >
                        Policy number
                      </p>
                      <p
                        className="font-weight-bold"
                        style={{ fontSize: "0.8rem" }}
                      >
                        {active?.POLICYNO}
                      </p>
                    </div>
                    <div>
                      <p
                        className="mb-1 text-muted"
                        style={{ fontSize: "0.8rem" }}
                      >
                        Policy reference
                      </p>
                      <p
                        className="font-weight-bold"
                        style={{ fontSize: "0.8rem" }}
                      >
                        {active?.POL_REF_NO}
                      </p>
                    </div>
                    <div>
                      <p
                        className="mb-1 text-muted"
                        style={{ fontSize: "0.8rem" }}
                      >
                        Effective date
                      </p>
                      <p
                        className="font-weight-bold"
                        style={{ fontSize: "0.8rem" }}
                      >
                        {active?.STARTDATE}
                      </p>
                    </div>
                    <div>
                      <p
                        className="mb-1 text-muted"
                        style={{ fontSize: "0.8rem" }}
                      >
                        Maturity date
                      </p>
                      <p
                        className="font-weight-bold"
                        style={{ fontSize: "0.8rem" }}
                      >
                        {active?.EXPIRYDATE}
                      </p>
                    </div>
                    <div>
                      <p
                        className="mb-1 text-muted"
                        style={{ fontSize: "0.8rem" }}
                      >
                        Recurrent premium
                      </p>
                      <p
                        className="font-weight-bold"
                        style={{ fontSize: "0.8rem" }}
                      >
                        N{active?.RECURRENTPREMIUM}
                      </p>
                    </div>
                    <div>
                      <p
                        className="mb-1 text-muted"
                        style={{ fontSize: "0.8rem" }}
                      >
                        Sum assured
                      </p>
                      <p
                        className="font-weight-bold"
                        style={{ fontSize: "0.8rem" }}
                      >
                        N{active?.SUMASSURED}
                      </p>
                    </div>
                    <div>
                      <p
                        className="mb-1 text-muted"
                        style={{ fontSize: "0.8rem" }}
                      >
                        Total payment
                      </p>
                      <p
                        className="font-weight-bold"
                        style={{ fontSize: "0.8rem" }}
                      >
                        N{active?.TOTALPAYMENT}
                      </p>
                    </div>
                    <div>
                      <p
                        className="mb-1 text-muted"
                        style={{ fontSize: "0.8rem" }}
                      >
                        Policy status
                      </p>
                      <p
                        className="font-weight-bold text-success"
                        style={{ fontSize: "0.8rem" }}
                      >
                        {active?.POL_STATUS}
                      </p>
                    </div>

                    <p
                      className="text-primary font-weight-bold cursor-pointer"
                      onClick={() => {
                        handlePolicyNoClick(active?.POLICYNO);
                      }}
                    >
                      {isLoading
                        ? "Getting policy 360, please wait..."
                        : "See policy 360"}
                    </p>
                  </AccordionDetails>
                ) : (
                  <AccordionDetails>
                    Please select a policy to view details
                  </AccordionDetails>
                )}
              </Accordion>
              {/* <Accordion
                style={{
                  marginBottom: "10px",
                  border: "1px solid gray",
                  boxShadow: "unset",
                }}
                className="rounded"
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <h6>Contact details</h6>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse malesuada lacus ex, sit amet blandit leo
                    lobortis eget.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                style={{
                  marginBottom: "10px",
                  border: "1px solid gray",
                  boxShadow: "unset",
                }}
                className="rounded"
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <h6>Beneficiary details</h6>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse malesuada lacus ex, sit amet blandit leo
                    lobortis eget.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                style={{
                  marginBottom: "10px",
                  border: "1px solid gray",
                  boxShadow: "unset",
                }}
                className="rounded"
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel4a-content"
                  id="panel4a-header"
                >
                  <h6>Dependant details</h6>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse malesuada lacus ex, sit amet blandit leo
                    lobortis eget.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                style={{
                  marginBottom: "10px",

                  border: "1px solid gray",
                  boxShadow: "unset",
                }}
                className="rounded"
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel5a-content"
                  id="panel5a-header"
                >
                  <h6>Financial advisor details</h6>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse malesuada lacus ex, sit amet blandit leo
                    lobortis eget.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                style={{
                  marginBottom: "10px",
                  border: "1px solid gray",
                  boxShadow: "unset",
                }}
                className="rounded"
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel6a-content"
                  id="panel6a-header"
                >
                  <h6>Payment details</h6>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse malesuada lacus ex, sit amet blandit leo
                    lobortis eget.
                  </Typography>
                </AccordionDetails>
              </Accordion> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Customer360;
