import * as React from "react";
import { Link, withRouter } from "react-router-dom";
import AppContext from "../../context";
import Chart from "react-apexcharts";
import {
  summary,
  ticket_type_summary,
  ticket_status_by_dates,
  recent_tickets,
  ticket_status_summary,
} from "../../services";
import { useQuery, useMutation, useQueryClient, queryCache } from "react-query";
import { DataPlacer, Placeholder } from "../../components";
import moment from "moment";
import {
  PiCaretDownFill,
  PiCaretUpFill,
  PiCheckCircleFill,
  PiWarningFill,
} from "react-icons/pi";
import { FaLock, FaLockOpen } from "react-icons/fa";

const Dashboard = ({ history }) => {
  const { dispatch, numberFormat, errorResponse, checkArrayData, userData } =
    React.useContext(AppContext);

  const [ticketCategories, setTicketCategories] = React.useState(() => {
    let labels = ["Claims", "New business", "Termination", "Direct debit"];
    let series = [90, 76, 250, 200];

    let options = {
      series,
      options: {
        chart: {
          type: "donut",
        },
        stroke: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: true,
          horizontalAlign: "center",
          position: "bottom",
          floating: false,
          formatter: (val, opt) => {
            return `${val} - ${opt?.w?.globals?.series[opt?.seriesIndex]}`;
          },
          markers: {
            width: 8,
            height: 8,
          },
        },
        plotOptions: {
          pie: {
            donut: {
              size: "75%",
              labels: {
                show: true,
                value: {
                  fontFamily: "Poppins,sans-serif",
                  fontWeight: 700,
                  offsetY: 0,
                },
                total: {
                  showAlways: true,
                  show: true,
                  label: "TOTAL",
                  color: "#000",
                  fontSize: "10px",
                  fontFamily: "Poppins,sans-serif",
                  fontWeight: 600,
                  formatter: function (w) {
                    const val = w.globals.seriesTotals.reduce((a, b) => {
                      return a + b;
                    }, 0);
                    return val;
                  },
                },
              },
            },
          },
        },
        labels,
        colors: ["#B0D7EF", "#002C47", "#E6F2FA", "#007dcc"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
    };

    return options;
  });

  return (
    <>
      <div
        className="d-flex align-items-center justify-content-between"
        style={{ height: "98px" }}
      >
        <div
          className="w-25 h-100 rounded p-2 d-flex align-items-center justify-content-between flex-direction-row"
          style={{ border: "1px solid #d4d4d8" }}
        >
          <div>
            <p className="mb-3 text-muted">Total tickets</p>
            <h4 className="mb-0" style={{ color: "#000" }}>
              {numberFormat(124567)}
            </h4>
          </div>

          <div
            className="rounded-circle d-flex align-items-center justify-content-center"
            style={{
              width: "35px",
              height: "35px",
              backgroundColor: "#e6f2fa",
            }}
          >
            <PiCheckCircleFill
              style={{ fontSize: "2.1rem" }}
              className="text-primary"
            />
          </div>
        </div>
        <div
          className="w-25 h-100 rounded p-2 d-flex align-items-center justify-content-between flex-direction-row ml-3"
          style={{ border: "1px solid #d4d4d8" }}
        >
          <div>
            <p className="mb-3 text-muted">Resolved tickets</p>
            <h4 className="mb-0" style={{ color: "#000" }}>
              {numberFormat(124567)}
            </h4>
          </div>

          <div
            className="rounded-circle d-flex align-items-center justify-content-center"
            style={{
              width: "35px",
              height: "35px",
              backgroundColor: "#e6f2fa",
            }}
          >
            <FaLock style={{ fontSize: "1.3rem" }} className="text-primary" />
          </div>
        </div>
        <div
          className="w-25 h-100 rounded p-2 d-flex align-items-center justify-content-between flex-direction-row ml-3"
          style={{ border: "1px solid #d4d4d8" }}
        >
          <div>
            <p className="mb-3 text-muted">Open tickets</p>
            <h4 className="mb-0" style={{ color: "#000" }}>
              {numberFormat(124567)}
            </h4>
          </div>

          <div
            className="rounded-circle d-flex align-items-center justify-content-center"
            style={{
              width: "35px",
              height: "35px",
              backgroundColor: "#e6f2fa",
            }}
          >
            <FaLockOpen
              style={{ fontSize: "1.3rem" }}
              className="text-primary"
            />
          </div>
        </div>
        <div
          className="w-25 h-100 rounded p-2 d-flex align-items-center justify-content-between flex-direction-row ml-3"
          style={{ border: "1px solid #d4d4d8" }}
        >
          <div>
            <p className="mb-3 text-muted">Escalated tickets</p>
            <h4 className="mb-0" style={{ color: "#000" }}>
              {numberFormat(124567)}
            </h4>
          </div>

          <div
            className="rounded-circle d-flex align-items-center justify-content-center"
            style={{
              width: "35px",
              height: "35px",
              backgroundColor: "#e6f2fa",
            }}
          >
            <PiWarningFill
              style={{ fontSize: "1.6rem" }}
              className="text-primary"
            />
          </div>
        </div>
      </div>
      <div className="m-0 mr-0 mt-5 d-flex align-items-center justify-content-center">
        <div className="row w-100 mr-0 " style={{ height: "400px" }}>
          <div className="col-9 d-flex justify-content-between flex-direction-row p-0">
            <div
              className="w-50 h-40 rounded p-4 "
              style={{ border: "1px solid #d4d4d8" }}
            >
              <p className="m-0 text-muted">Agent performance</p>

              <div className="h-100 d-flex align-items-end justify-content-between flex-direction-row">
                <div>
                  <h2 style={{ color: "#000", fontSize: "1.5rem" }}>
                    2:02 hours
                  </h2>
                  <p className="text-muted">Avg. resolution time</p>
                </div>
                <p className="text-success">
                  <PiCaretUpFill /> +3.5%
                </p>
              </div>
            </div>
            <div
              className="w-50 h-40 rounded p-4 ml-3"
              style={{ border: "1px solid #d4d4d8" }}
            >
              <p className="m-0 text-muted">Customer satisfaction</p>

              <div className="h-100 d-flex align-items-end justify-content-between flex-direction-row">
                <div>
                  <h2 style={{ color: "#000", fontSize: "1.5rem" }}>60%</h2>
                  <p className="text-muted">CSAT</p>
                </div>
                <p className="text-danger">
                  <PiCaretDownFill /> -3.5%
                </p>
              </div>
            </div>
            <div
              className="w-50 h-40 rounded p-4 ml-3 "
              style={{ border: "1px solid #d4d4d8" }}
            >
              <p className="m-0 text-muted">SLA performance</p>

              <div className="h-100 d-flex align-items-end justify-content-between flex-direction-row">
                <div>
                  <h2 style={{ color: "#000", fontSize: "1.5rem" }}>7.2/10</h2>
                  <p className="text-muted">Response time</p>
                </div>
                <p className="text-success">
                  <PiCaretUpFill /> +3.5%
                </p>
              </div>
            </div>
          </div>
          <div className="col-3 pr-0 w-50">
            <div
              className="w-100 h-100 rounded p-2 pt-4"
              style={{
                border: "1px solid #d4d4d8",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <p className="m-0 text-muted">Ticket category</p>

              <div className="donut h-100 d-flex align-items-center justify-content-center">
                <Chart
                  options={ticketCategories.options}
                  series={ticketCategories.series}
                  type="donut"
                  height={280}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
