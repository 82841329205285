import * as React from "react";
import { Link, withRouter, useLocation, useHistory } from "react-router-dom";
import AppContext from "../context";
import MasterContext from "./context";
import LayoutHeader from "./header";
import LayoutNavbar from "./navbar";
import LayoutRightBar from "./navBarRight";
import { Navbar, Nav, Row, Col, Button, Container } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import {
  PiBellLight,
  PiGear,
  PiChartLineFill,
  PiMagnifyingGlass,
  PiUsersFill,
} from "react-icons/pi";
import { RiDashboardFill } from "react-icons/ri";
import { IoTicket } from "react-icons/io5";
import { MdPeople } from "react-icons/md";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import Collapse from "react-bootstrap/Collapse";
import useModal from "../hooks/useModal";
import TicketForm from "./newCustomerForm";
import { useForm } from "react-hook-form";
import { Modal, Table } from "react-bootstrap";
import Search from "./search";
import useFormState from "../hooks/useFormState";
import useRouter from "../hooks/useRouter";
import { create_ticket } from "../services";
import { useMutation, useQueryClient } from "react-query";
import { Box } from "@mui/material";

const Master = ({ children, history }) => {
  const {
    dispatch,
    userToken,
    logoutUser,
    pageTitle,
    formActionType,
    currentPath,
    setQueryString,
    ticketStatusesData,
    ticketTypesData,
    rolesData,
    notify,
    errorResponse,
    setStateData,
    ticketQueryName,
    userData,
  } = React.useContext(AppContext);
  let { pathname } = useLocation();

  const router = useRouter();

  const queryClient = useQueryClient();
  const [rightBarContent, setRightBarContent] = React.useState(null);
  const [rightBarTitle, setRightBarTitle] = React.useState(null);
  const [query, setQuery] = React.useState(router.query);
  const [subMenu, setSubMenu] = React.useState(null);
  const [breadcrumb, setBreadcrumb] = React.useState(null);
  const [defaultValues, setDefaultValues] = React.useState({});
  const [formID] = React.useState("form-ticket");
  const [openReports, setOpenReports] = React.useState(false);
  const [openCustomers, setOpenCustomers] = React.useState(false);
  const [openTickets, setOpenTickets] = React.useState(false);
  const [exportData, setExportData] = React.useState([]);
  const forms = useForm({ defaultValues });
  const [queryExport, setQueryExport] = React.useState({});
  const [exporting, setExporting] = React.useState(false);
  const [exportReady, setExportReady] = React.useState(false);
  const [toggleCloseBtn, setToggleCloseBtn] = React.useState(true);
  const [isExport, setIsExport] = React.useState(false);
  const [showProfile, setShowProfile] = React.useState(false);
  const [attachments, setAttachments] = React.useState([]);
  const [attachmentIds, setAttachmentIds] = React.useState([]);
  const { toggleFormState: tfsTicket } = useFormState("form-ticket");
  const { toggleFormState: tfsSearch } = useFormState("form-search");

  console.log(userData);

  const {
    modalState,
    setModalState,
    modalTitle,
    setModalTitle,
    closeModal,
    showModal,
  } = useModal();

  // const initTry = ({ id, customer_name }) => {
  //   setModalState(true);
  //   setStateData(dispatch, "formActionType", id);
  //   setModalTitle("try");
  //   //setDefaultValues(data)
  //   forms.setValue("customer_name", "kjhfds");
  // };

  const {
    modalState: modalStateSearch,
    modalTitle: modalTitleSearch,
    setModalTitle: setModalTitleSearch,
    closeModal: closeModalSearch,
    showModal: showModalSearch,
  } = useModal();

  const onExportSubmit = (values) => {
    setToggleCloseBtn(false);
    setExportData([]);
    setQueryExport({ ...values, page: 1 });
    setExporting(true);
  };

  const initRightBar = ({ content = null, title = null }) => {
    //console.log('initRightBar...')
    setRightBarTitle(title);
    setRightBarContent(content);
  };

  const onSearchSubmit = (data) => {
    closeModalSearch();
    setQuery(data);
    history.push(`${currentPath}${setQueryString(data)}`);
  };

  const initSubMenu = (menu) => {
    setSubMenu(menu);
  };

  React.useEffect(() => {
    return (_) => {
      //console.log(subMenu, 'subMenu leave....')
      setBreadcrumb(null);
    };
  }, [pathname]);

  React.useEffect(() => {
    //console.log(subMenu, 'subMenu....')
  }, [subMenu]);

  const handleNavigate = (location) => {
    history.push(`/${location}`);
  };

  /* Add data */
  const { mutate: handleSubmitAdd } = useMutation(
    (value) => create_ticket(value),
    {
      onSuccess: () => {
        notify("success", "Data Added", "Ticket successfully created!");
        // history.push(`/tickets`);
      },
      onError: (error) =>
        notify("danger", "OOPS", error?.response?.data?.message),
      onSettled: async (data, error, variables, context) => {
        await queryClient.refetchQueries([ticketQueryName, query]);
        if (!error) handleCloseFormModal();
        setAttachmentIds([]);
        setAttachments([]);
        tfsTicket(false);
      },
    }
  );

  const toggleProfile = () => {
    setShowProfile(!showProfile);
  };

  const handleCloseFormModal = () => {
    //console.log('handleCloseFormModal...')
    closeModal();
    //setDefaultValues({});
    forms.reset();
  };

  const onSubmit = (data) => {
    console.log("attaid", attachmentIds);
    const updatedData = { ...data, files_id: attachmentIds };
    tfsTicket(true, "saving...");
    handleSubmitAdd({ ...updatedData });
  };

  const handleLogout = () => {
    logoutUser(dispatch, history);
    //history.replace("/login");
  };

  const resetExport = () => {
    closeModalSearch();
    setToggleCloseBtn(true);
    setExportData([]);
    setQueryExport({});
    setExporting(false);
    setExportReady(false);
    tfsSearch(false);
    tfsTicket(false);
    setIsExport(false);
    history.push(currentPath);
  };
  const initSearch = (isExport = false) => {
    setModalTitleSearch(isExport ? "Filter Export.." : "Search..");
    setIsExport(isExport);
    showModalSearch();
  };

  const nameParts = userData?.fullname?.split(" ");
  let placeholder;

  // Check if there are at least two parts (first name and last name)
  if (nameParts?.length >= 2) {
    // Take the first letter of the first name and last name
    const firstInitial = nameParts[0].charAt(0).toUpperCase();
    const lastInitial = nameParts[nameParts.length - 1].charAt(0).toUpperCase();

    // Create the placeholder using the initials
    placeholder = firstInitial + lastInitial;
  }

  return userToken ? (
    <MasterContext.Provider
      value={{
        initRightBar,
        initSubMenu,
        breadcrumb,
        setBreadcrumb,
      }}
    >
      <div id="layout-wrapper">
        <div className="navbar">
          <div className="nav-con">
            <img src="./assets/images/logo.png" className="logo" />
          </div>
          <div className="navvv">
            <h4 style={{ color: "#414141" }} className="mb-0">
              {pageTitle}
            </h4>
            <Row className=" d-flex align-items-center">
              <Col onClick={() => initSearch(false)}>
                <Button variant="custom">
                  <PiMagnifyingGlass
                    style={{ color: "gray", fontSize: "1.2rem" }}
                  />{" "}
                  Search
                </Button>
              </Col>
              <Col>
                <DropdownButton
                  id="dropdown-basic-button"
                  title="+ New"
                  drop="down"
                  variant="custom"
                >
                  <Dropdown.Item
                    onClick={() => {
                      setModalState(true);
                      setModalTitle("New Ticket");
                    }}
                  >
                    New Ticket
                  </Dropdown.Item>
                  {/* <Dropdown.Item
                    onClick={() => {
                      initTry(100, "Efe");
                    }}
                  >
                    Try{" "}
                  </Dropdown.Item> */}
                  {/* <Dropdown.Item href="#/action-2">
                    Potential Customer
                  </Dropdown.Item> */}
                </DropdownButton>
              </Col>
              <Col>
                <PiBellLight style={{ color: "#000", fontSize: "1.5rem" }} />
              </Col>
              <Col>
                <PiGear style={{ color: "#000", fontSize: "1.5rem" }} />
              </Col>
              <Col>
                <Dropdown>
                  <Dropdown.Toggle className="placeholder d-flex align-items-center justify-content-center">
                    {placeholder}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      href="#/action-1"
                      active
                      // onClick={toggleProfile}
                    >
                      Your profile
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-2">
                      Your tickets
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Settings</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item
                      onClick={handleLogout}
                      style={{ color: "red" }}
                    >
                      Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>
          </div>
        </div>
        <div className="sidebar" style={{ gap: "1.6rem" }}>
          <NavLink
            to="/"
            exact="true"
            activeClassName="active-path"
            className="mb-0"
            style={{ fontSize: "0.95rem" }}
          >
            <RiDashboardFill /> Dashboard
          </NavLink>
          <NavLink
            style={{ fontSize: "0.95rem" }}
            onClick={() => setOpenTickets(!openTickets)}
            aria-controls="example-collapse-text"
            aria-expanded={openTickets}
            to="/tickets"
            activeClassName="active-path"
            className="mb-0"
          >
            <IoTicket /> Tickets
          </NavLink>
          {/* <Collapse in={openTickets}>
            <div
              id="example-collapse-text"
              className="row ml-2 pl-4 border-left border-secondary"
            >
              <Link
                className="mb-2"
                style={{ fontSize: "0.85rem" }}
                to="/reports/agent-performance"
              >
                Create new tickets
              </Link>
              <Link
                className="mb-2"
                style={{ fontSize: "0.85rem" }}
                to="/reports/ticket-reports"
              >
                Open tickets
              </Link>
              <Link
                className="mb-2"
                style={{ fontSize: "0.85rem" }}
                to="/reports/agent-reports"
              >
                Resolved tickets
              </Link>
              <Link
                className="mb-0"
                style={{ fontSize: "0.85rem" }}
                to="/reports/sla-tat-reports"
              >
                Escalated tickets
              </Link>
            </div>
          </Collapse> */}
          <p
            style={{ fontSize: "0.95rem" }}
            className="mb-0 cursor-pointer"
            onClick={() => setOpenCustomers(!openCustomers)}
          >
            <MdPeople /> Customers
          </p>
          <Collapse in={openCustomers}>
            <div
              id="example-collapse-text"
              className="row ml-2 pl-4 border-left border-secondary"
            >
              <NavLink
                className="mb-2"
                style={{ fontSize: "0.85rem" }}
                to="/customer360"
                activeClassName="active-path"
              >
                Customer 360
              </NavLink>
              {/* <NavLink
                className="mb-2"
                style={{ fontSize: "0.85rem" }}
                to="/customers"
                activeClassName="active-path"
              >
                List of customers
              </NavLink> */}
            </div>
          </Collapse>
          {(userData?.role?.roleKey === "ADMINISTRATOR" ||
            userData?.role?.roleKey === "TECHNICAL_ADMIN") && (
            <NavLink
              style={{ fontSize: "0.95rem" }}
              to="/users"
              activeClassName="active-path"
              className="mb-0"
            >
              <PiUsersFill /> Users
            </NavLink>
          )}
          {/* <NavLink
            style={{ fontSize: "0.95rem" }}
            onClick={() => setOpenReports(!openReports)}
            aria-controls="example-collapse-text"
            aria-expanded={openReports}
            to="/reports"
            activeClassName="active-path"
            className="mb-0"
          >
            <PiChartLineFill /> Reports
          </NavLink>
          <Collapse in={openReports}>
            <div
              id="example-collapse-text"
              className="row ml-2 pl-4 border-left border-secondary"
            >
              <Link
                className="mb-2"
                style={{ fontSize: "0.85rem" }}
                to="/reports/agent-performance"
              >
                Agent performance
              </Link>
              <Link
                className="mb-2"
                style={{ fontSize: "0.85rem" }}
                to="/reports/ticket-reports"
              >
                Ticket reports
              </Link>
              <Link
                className="mb-2"
                style={{ fontSize: "0.85rem" }}
                to="/reports/agent-reports"
              >
                Agent reports
              </Link>
              <Link
                className="mb-0"
                style={{ fontSize: "0.85rem" }}
                to="/reports/sla-tat-reports"
              >
                SLA/TAT reports
              </Link>
            </div>
          </Collapse> */}
          <div
            style={{
              position: "absolute",
              left: 10,
              bottom: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <p className="mb-1" style={{ fontSize: "0.7rem" }}>
              {userData?.userEmail}
            </p>
            <p className="mb-1" style={{ fontSize: "0.7rem" }}>
              {userData?.unit?.unitName}
            </p>
            <p className="mb-1" style={{ fontSize: "0.7rem" }}>
              {userData?.role?.roleName}
            </p>
          </div>
        </div>
        <div className="content-body">{children}</div>
        <Modal
          show={modalState}
          onHide={handleCloseFormModal}
          backdrop="static"
          keyboard={false}
          size="md"
        >
          <Modal.Header closeButton>
            <Modal.Title>{modalTitle}</Modal.Title>
          </Modal.Header>

          <TicketForm
            handleCloseModal={handleCloseFormModal}
            formID={formID}
            formActionType={formActionType}
            onSubmit={onSubmit}
            attachmentIds={attachmentIds}
            setAttachmentIds={setAttachmentIds}
            setAttachments={setAttachments}
            attachments={attachments}
            forms={forms}
            history={history}
            setModalState={setModalState}
          />
        </Modal>
        <Modal
          show={modalStateSearch}
          onHide={resetExport}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>{modalTitleSearch}</Modal.Title>
          </Modal.Header>

          <Search
            handleCloseModal={resetExport}
            onSearchSubmit={onSearchSubmit}
            query={query}
            isExport={isExport}
            onExportSubmit={onExportSubmit}
            tfsSearch={tfsSearch}
            resetExport={resetExport}
            exporting={exporting}
            exportData={exportData}
            exportReady={exportReady}
            history={history}
            closeModalSearch={closeModalSearch}
          />
        </Modal>
        <Modal
          show={showProfile}
          onHide={toggleProfile}
          backdrop="static"
          keyboard={false}
          size="md"
        >
          <Modal.Header closeButton>
            <Modal.Title>Profile information</Modal.Title>
          </Modal.Header>
          <span className="pl-4 pr-3 mt-2">
            You can see your profile information on this frame and confirm your
            details. If any information is incorrect, kindly contact the IT team
          </span>
          <div className="row m-4 p-3" style={{ backgroundColor: "#F2F4F7" }}>
            <div className="col-6 mb-5">
              <span>Name</span>
              <p className="mb-0 font-weight-bold">{userData?.fullname}</p>
            </div>
            <div className="col-6 mb-5">
              <span>Role</span>
              <p className="mb-0 font-weight-bold">
                {userData?.role?.roleName}
              </p>
            </div>
            <div className="col-6">
              <span>Email</span>
              <p className="mb-0 font-weight-bold">{userData?.userEmail}</p>
            </div>
            {/* <div className="col-6 mb-5">
              <span>Department</span>
              <p className="mb-0">IT</p>
            </div> */}
            <div className="col-6">
              <span>Unit</span>
              <p className="mb-0 font-weight-bold">
                {userData?.unit?.unitName}
              </p>
            </div>
          </div>
        </Modal>
      </div>
    </MasterContext.Provider>
  ) : (
    <>{children}</>
  );
};

export default withRouter(Master);
