import React from "react";
import { Redirect, Route, useRouteMatch, useLocation } from "react-router-dom";
import AppContext from "./../context";

const AppRoutes = ({
  component: Component,
  path,
  auth,
  exact,
  pageTitle,
  ...rest
}) => {
  const { dispatch, userToken, setPageTitle, setCurrentPath } =
    React.useContext(AppContext);
  let match1 = useRouteMatch("/login");
  let match2 = useRouteMatch("/password-setup");
  let match3 = useRouteMatch("/forgot-password");
  const match = [
    useRouteMatch("/login"),
    useRouteMatch("/password-setup"),
    useRouteMatch("/forgot-password"),
  ];

  let { pathname } = useLocation();
  const [baseTitle] = React.useState(process.env.REACT_APP_TITLE);

  React.useEffect(() => {
    document.title = `${pageTitle && `${pageTitle} - `}${baseTitle}`;
    setPageTitle(dispatch, pageTitle);
    setCurrentPath(dispatch, pathname);
    return () => {
      document.title = baseTitle;
      setPageTitle(dispatch, baseTitle);
    };
  }, [pathname, dispatch, pageTitle, setPageTitle, baseTitle, setCurrentPath]);

  return (
    <Route
      exact={exact}
      path={path}
      render={(props) =>
        auth && !Boolean(userToken) ? (
          <Redirect to={{ pathname: "/login" }} />
        ) : (
          <>
            {(match1 || match2 || match3) && Boolean(userToken) ? (
              <Redirect to={{ pathname: "/" }} />
            ) : (
              <Component {...props} />
            )}
          </>
        )
      }
      {...rest}
    />
  );
};

export default AppRoutes;
