import * as React from "react";
import { Link, withRouter, useHistory } from "react-router-dom";
import AppContext from "../../context";
import Search from "./search";
import usePageSetup from "../../hooks/usePageSetup";
import useRouter from "../../hooks/useRouter";
import {
  users,
  userAdd,
  userEdit,
  userDelete,
  branches as branchesList,
  ticket_types,
  get_assignments,
  user_actions,
  userUpload,
  userSetPassword,
  user,
} from "../../services";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { Paginate, PaginateWrapper } from "../../components";
import useFormState from "../../hooks/useFormState";
import { useForm } from "react-hook-form";
import useModal from "../../hooks/useModal";
import { Dropdown, Modal } from "react-bootstrap";
import UserDetails from "./details";
import UploadUser from "./upload";
import SetPasswordForm from "./form-set-password";
import {
  PiDotOutlineFill,
  PiDotsThreeOutlineVerticalFill,
} from "react-icons/pi";
import UserForm from "./form";

const Users = ({ history }) => {
  const {
    dispatch,
    initModal,
    handleCloseModal,
    pageTitle,
    setQueryString,
    formatDateBr,
    currentPath,
    isSearchQuery,
    errorResponse,
    checkArray,
    checkArrayData,
    getUserType,
    badgeToggle,
    setStateData,
    formActionType,
    notify,
    userData,
  } = React.useContext(AppContext);
  const router = useRouter();

  const queryClient = useQueryClient();
  const [queryName] = React.useState("users");
  const [query, setQuery] = React.useState(router.query);
  const [defaultValues, setDefaultValues] = React.useState({});
  const [branches, setBranches] = React.useState([]);
  const [ticketTypes, setTicketTypes] = React.useState([]);
  const [assignments, setAssignments] = React.useState([]);
  const [formID] = React.useState("form-user");
  const { toggleFormState: toggleFormStateUser } = useFormState(formID);
  const { toggleFormState: toggleFormStateUserUpload } =
    useFormState("form-upload-user");
  const { toggleFormState: toggleFormStateSetPassword } =
    useFormState("form-set-password");
  const { toggleFormState: tfsSearch } = useFormState("form-search");
  const forms = useForm({ defaultValues });
  const formsUpload = useForm();
  const formsPassword = useForm();

  const {
    modalState: modalStateForm,
    modalTitle: modalTitleForm,
    setModalTitle: setModalTitleForm,
    closeModal: closeModalForm,
    showModal: showModalForm,
  } = useModal();

  const {
    modalState: modalStateSearch,
    modalTitle: modalTitleSearch,
    setModalTitle: setModalTitleSearch,
    closeModal: closeModalSearch,
    showModal: showModalSearch,
  } = useModal();

  usePageSetup({
    breadcrumb: [{ to: null, label: pageTitle }],
  });

  /* export query */
  const [queryExport, setQueryExport] = React.useState({});
  const [exportData, setExportData] = React.useState([]);
  const [exporting, setExporting] = React.useState(false);
  const [exportReady, setExportReady] = React.useState(false);
  const [toggleCloseBtn, setToggleCloseBtn] = React.useState(true);
  const [isExport, setIsExport] = React.useState(false);

  /* Page data */
  const { isLoading, isFetching, isError, data, error } = useQuery(
    [queryName, query],
    () => users(setQueryString(query)),
    {
      keepPreviousData: true,
      staleTime: 9000,
      onError: (error) => errorResponse({ error, history, dispatch }),
    }
  );

  console.log(data);

  /* Requery on data, query change */
  React.useEffect(() => {
    if (data?.nextPage) {
      let nextQuery = { ...query, page: data?.nextPage };
      queryClient.prefetchQuery([queryName, nextQuery], () =>
        users(setQueryString(nextQuery))
      );
    }
  }, [data, query, queryClient]);

  React.useEffect(() => {
    console.log(query, "query_____");
  }, [query]);

  const onSearchSubmit = (data) => {
    closeModalSearch();
    setQuery(data);
    history.push(`${currentPath}${setQueryString(data)}`);
  };

  /* handle paginate data */
  const handlePageClick = ({ selected }) => {
    const page = selected + 1;
    let nQ = { ...query, page };
    setQuery(nQ);
    history.push(`${currentPath}${setQueryString(nQ)}`);
  };

  // useQuery("branches", branchesList, {
  //   onError: (error) => setBranches([]),
  //   onSuccess: ({ data }) => setBranches(data),
  // });

  // useQuery("ticket-types", ticket_types, {
  //   onError: (error) => setTicketTypes([]),
  //   onSuccess: ({ data }) => setTicketTypes(data),
  // });

  // useQuery("assignments", get_assignments, {
  //   onError: (error) => setAssignments([]),
  //   onSuccess: ({ data }) => setAssignments(data),
  // });

  const handleCloseFormModal = () => {
    closeModalForm();
    forms.reset();
  };

  React.useEffect(() => {
    setQuery(router?.query);
    return (_) => setQuery({});
  }, [router?.query]);

  /* Add data */
  const { mutate: handleSubmitAdd } = useMutation((value) => userAdd(value), {
    onSuccess: () => {
      notify("success", "Data Added", "New user successfully created!");
    },
    onError: (error) => errorResponse({ error, dispatch, history, forms }),
    onSettled: async (data, error, variables, context) => {
      //console.log(error, 'error....')
      await queryClient.refetchQueries([queryName, query]);
      if (!error) handleCloseFormModal();
      toggleFormStateUser(false);
    },
  });

  /* Upload Users */
  const { mutate: handleSubmitUploadUsers } = useMutation(
    (value) => userUpload(value),
    {
      onSuccess: () => {
        notify("success", "Users Uploaded", "Users successfully uploaded!");
      },
      onError: (error) => errorResponse({ error, dispatch, history, forms }),
      onSettled: async (data, error, variables, context) => {
        //console.log(error, 'error....')
        await queryClient.refetchQueries([queryName, query]);
        if (!error) handleCloseModal();
        toggleFormStateUserUpload(false);
      },
    }
  );

  /* Edit data */
  const { mutate: handleSubmitEdit } = useMutation(
    ({ id, values }) => userEdit({ id, values }),
    {
      onSuccess: () => {
        handleCloseModal();
        notify("success", "Data Modified", "User successfully modified!");
      },
      onError: (error) => errorResponse({ error, dispatch, history, forms }),
      onSettled: async (data, error, variables, context) => {
        await queryClient.refetchQueries([queryName, query]);
        if (!error) handleCloseFormModal();
        toggleFormStateUser(false);
      },
    }
  );

  /* Set password */
  const { mutate: handleSubmitSetPassword } = useMutation(
    ({ id, values }) => userSetPassword({ id, values }),
    {
      onSuccess: () => {
        handleCloseModal();
        notify(
          "success",
          "Password Modified",
          "User password successfully modified!"
        );
      },
      onError: (error) => errorResponse({ error, dispatch, history, forms }),
      onSettled: async (data, error, variables, context) => {
        await queryClient.refetchQueries([queryName, query]);
        if (!error) handleCloseFormModal();
        toggleFormStateSetPassword(false);
      },
    }
  );

  /* Delete data */
  const { mutate: handleSubmitDelete } = useMutation((id) => userDelete(id), {
    onSuccess: () => {
      notify("success", "Data Deleted", "Select data successfully deleted!");
    },
    onMutate: (id) => {
      queryClient.cancelQueries([queryName, query]);

      const previousData = queryClient.getQueryData([queryName, query]);
      const updateValue = previousData?.data;

      const removeDeleted = updateValue.filter((dt) => dt.id !== id);
      const newData = { ...previousData, data: removeDeleted };
      return queryClient.setQueryData([queryName, query], newData);
    },
    onError: (error) => errorResponse({ error, dispatch, history }),
    onSettled: async () => await queryClient.refetchQueries([queryName, query]),
  });

  /* User actions */
  const { mutate: handleSubmitAction } = useMutation(
    (value) => user_actions(value),
    {
      onSuccess: () => {
        notify(
          "success",
          "User Account Updated",
          "Account updated successfully!"
        );
      },
      onError: (error) => errorResponse({ error, dispatch, history }),
      onSettled: async (data, error, variables, context) => {
        await queryClient.refetchQueries([queryName, query]);
        if (!error) handleCloseModal();
        //toggleFormStateUser(false);
      },
    }
  );

  const onSubmit = (data) => {
    toggleFormStateUser(true, "saving...");
    if (formActionType === 1) {
      handleSubmitAdd({ ...data });
    } else {
      const { email, ...values } = data;
      handleSubmitEdit({ id: formActionType, values: { ...values } });
    }
  };

  const handleToggleStatus = (id, value) => {
    const values = { isActive: value };
    userEdit(id, values);
  };

  const initAdd = () => {
    forms.reset();
    setStateData(dispatch, "formActionType", 1);
    setModalTitleForm("Create User");
    showModalForm();
  };

  const initEdit = ({
    id,
    fullname,
    roleId,
    departments,
    assignable,
    companyId,
    userEmail,
    branchId,
    userPhone,
  }) => {
    setStateData(dispatch, "formActionType", id);
    setModalTitleForm("Edit User");
    //setDefaultValues(data)
    forms.setValue("fullname", fullname);
    forms.setValue("roleId", roleId);
    forms.setValue("companyId", companyId);
    forms.setValue("branchId", branchId);
    forms.setValue("userPhone", userPhone);
    forms.setValue("userEmail", userEmail);

    // if (user_type === "IT") {
    //   forms.setValue("assignable", assignable);
    //   forms.setValue(
    //     "assignments",
    //     (assignments && assignments.map(({ id }) => id)) || []
    //   );
    // }

    // if (user_type === "IT" || user_type === "IT_ADMIN") {
    //   forms.setValue(
    //     "departments",
    //     (departments && departments.map(({ id }) => id)) || []
    //   );
    // }

    // if (user_type === "FRONT_DESK") {
    //   forms.setValue("branch", branch?.id);
    // }

    // if (user_type === "FA") {
    //   forms.setValue("agentCode", agentCode);
    // }

    showModalForm();
  };

  const initSetPassword = ({ id, email }) => {
    formsPassword.reset();
    initModal({
      title: `Reset password
            ${email}`,
      size: "md",
      content: (
        <SetPasswordForm
          handleCloseModal={handleCloseModal}
          onSetPasswordSubmit={onSetPasswordSubmit}
          forms={formsPassword}
          id={id}
        />
      ),
    });
  };

  const onSetPasswordSubmit = ({ id, password, passcode }) => {
    toggleFormStateSetPassword(true, "saving...");
    handleSubmitSetPassword({ id: id, values: { password, passcode } });
  };

  const initDelete = (id) => {
    const conf = window.confirm(
      "You are about to delete a user.. Are you sure?"
    );
    if (!conf) return;
    handleSubmitDelete(id);
  };

  const initDetails = (data) => {
    initModal({
      title: data?.name,
      size: "md",
      content: (
        <UserDetails
          handleCloseModal={handleCloseModal}
          onUserActionSubmit={onUserActionSubmit}
          initSetPassword={initSetPassword}
          data={data}
        />
      ),
    });
  };

  const onUserActionSubmit = (id, action) => {
    const conf = window.confirm("Press Ok to continue...");
    if (!conf) return;
    handleSubmitAction({ id, action });
  };

  const initUpload = (data) => {
    initModal({
      title: "Upload User",
      size: "md",
      content: (
        <UploadUser
          handleCloseModal={handleCloseModal}
          onSubmitUpload={onSubmitUpload}
          forms={formsUpload}
        />
      ),
    });
  };

  const onSubmitUpload = ({ user_type, file }) => {
    toggleFormStateUserUpload(true, "uploading...");
    let formData = new FormData();
    formData.append("user_type", user_type);
    formData.append("file", file[0]);
    handleSubmitUploadUsers(formData);
  };

  const initSearch = (isExport = false) => {
    setModalTitleSearch(isExport ? "Filter Export.." : "Search..");
    setIsExport(isExport);
    showModalSearch();
  };

  /* Export data */
  useQuery(
    ["export-users", queryExport],
    () => users(setQueryString(queryExport)),
    {
      retry: 0,
      manual: true,
      enabled: exporting,
      refetchInterval: 1000,
      onSuccess: ({ data, nextPage }) => {
        //console.log(data, 'export data...');
        data.length > 0 && setExportData([...exportData, ...data]);

        if (nextPage) {
          setQueryExport({ ...queryExport, page: queryExport.page + 1 });
        } else {
          setExporting(false);
          setExportReady(true);
          setQueryExport({});
          tfsSearch(false);
        }
      },
      onError: (error) => errorResponse({ error, history, dispatch }),
    }
  );

  const onExportSubmit = (values) => {
    setToggleCloseBtn(false);
    setExportData([]);
    setQueryExport({ ...values, page: 1 });
    setExporting(true);
  };

  const resetExport = () => {
    closeModalSearch();
    setToggleCloseBtn(true);
    setExportData([]);
    setQueryExport({});
    setExporting(false);
    setExportReady(false);
    tfsSearch(false);
    setIsExport(false);
    history.push(currentPath);
  };

  return (
    <>
      {isLoading ? (
        <>loading, please wait...</>
      ) : (
        <>
          {checkArray(data?.data) && (
            <>
              <div className="row mb-3">
                <div className="col-sm-7">
                  <div
                    className="btn-group mr-2"
                    role="group"
                    aria-label="Basic example"
                  >
                    {/* <button
                      type="button"
                      onClick={() => initSearch(false)}
                      className="btn btn-primary btn-rounded waves-effect waves-light"
                    >
                      <i className="bx bx-search-alt search-icon font-size-16 align-middle"></i>{" "}
                      Search
                    </button> */}
                    <p></p>
                    {/* {userData.user_type === "ADMIN" && (
                      <button
                        type="button"
                        onClick={() => initSearch(true)}
                        className="btn btn-success btn-rounded waves-effect waves-light pr-3"
                      >
                        <i className="bx bx-download search-icon font-size-16 align-middle"></i>{" "}
                        Export to Excel
                      </button>
                    )} */}
                    {isSearchQuery(router?.query) && (
                      <>
                        <button
                          type="button"
                          onClick={() => history.push(currentPath)}
                          className="btn btn-success btn-rounded waves-effect waves-light"
                        >
                          Clear{" "}
                          <i className="bx bx-x search-icon font-size-16 align-middle"></i>
                        </button>
                      </>
                    )}
                  </div>
                </div>
                <div className="col-sm-5 text-sm-right">
                  {/* <button
                    type="button"
                    onClick={initUpload}
                    className="btn btn-success btn-rounded mr-2"
                  >
                    <i className="bx bx-user-plus align-middle"></i> Upload User
                  </button> */}
                  <button
                    type="button"
                    onClick={initAdd}
                    className="btn btn-primary btn-rounded"
                  >
                    <i className="bx bx-user-plus align-middle"></i> Create User
                  </button>
                </div>
              </div>

              {!checkArrayData(data?.data) && <>No user found!</>}

              {checkArrayData(data?.data) && (
                <div className="table-responsive">
                  <table className="table table-centered table-nowrap">
                    <thead className="thead-light">
                      <tr>
                        <th width="1%">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="customCheck1"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customCheck1"
                            >
                              &nbsp;
                            </label>
                          </div>
                        </th>
                        <th width="30%">Details</th>
                        <th width="22%">Role</th>
                        {/* <th width="22%">Location</th> */}
                        {/* <th width="12%" className="text-center">
                          Assign. Status
                        </th> */}
                        <th width="12%">Status</th>
                        <th width="12%" className="text-right">
                          Created At
                        </th>
                        <th width="1%" className="text-center">
                          <i className="mdi mdi-dots-horizontal font-size-18"></i>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.data?.map((row, index) => {
                        return (
                          <tr key={row.id}>
                            <td>
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="customCheck2"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="customCheck2"
                                >
                                  &nbsp;
                                </label>
                              </div>
                            </td>
                            <td>
                              <span
                                onClick={() => initDetails(row)}
                                className="text-body font-weight-bold cursor-pointer"
                              >
                                {row.fullname}
                              </span>
                              <br />
                              {row?.userEmail}
                            </td>
                            {/* <td style={{ whiteSpace: "normal" }}>
                              {row?.role?.roleName}
                              {(row?.user_type === "IT" ||
                                row?.user_type === "IT_ADMIN") && (
                                <div>
                                  {checkArrayData(row?.departments) &&
                                    row?.departments.map((dt, index) => (
                                      <span
                                        key={dt.id}
                                        className="font-weight-bold text-primary"
                                      >
                                        {dt.name}
                                        {row.departments.length !== index + 1 &&
                                          ", "}
                                      </span>
                                    ))}

                                  {row?.user_type === "IT" && (
                                    <div>
                                      {checkArrayData(row?.assignments) &&
                                        row?.assignments.map((dt, index) => (
                                          <span
                                            key={dt.id}
                                            className="font-weight-bold text-dark"
                                          >
                                            {dt.name}{" "}
                                            {row.assignments.length !==
                                              index + 1 && ", "}
                                          </span>
                                        ))}
                                    </div>
                                  )}
                                </div>
                              )}
                            </td> */}

                            <td>
                              <span
                                onClick={() => initDetails(row)}
                                className="text-body font-weight-bold cursor-pointer"
                              >
                                {row?.role?.roleName}
                              </span>
                              <br />
                              {row?.unit?.unitName}
                            </td>
                            {/* <td>
                              <span
                                onClick={() => initDetails(row)}
                                className="text-body font-weight-bold cursor-pointer"
                              >
                                {row?.company?.companyName}
                              </span>
                              <br />
                              {row?.branch?.branchName}
                            </td> */}
                            <td
                              className={
                                !row?.isActive ? "text-danger" : "text-success"
                              }
                            >
                              <PiDotOutlineFill />{" "}
                              {row?.isActive ? "Active" : "Inactive"}
                            </td>
                            <td className="text-right">
                              {formatDateBr(row?.createdAt)}
                            </td>

                            <td className="cursor-pointer">
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="custom"
                                  className="p-0"
                                  style={{ margin: 0, border: 0 }}
                                >
                                  <PiDotsThreeOutlineVerticalFill
                                  // onClick={() => handleClick(id)}
                                  />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    onClick={() => {
                                      initEdit(row);
                                      console.log(row);
                                    }}
                                  >
                                    Edit
                                  </Dropdown.Item>
                                  <Dropdown.Item href="#/action-1">
                                    Delete
                                  </Dropdown.Item>
                                  <Dropdown.Item href="#/action-1">
                                    Activate
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => {
                                      console.log(row?.id);
                                      handleToggleStatus(row?.id, false);
                                    }}
                                  >
                                    Deactivate
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                            {/* <td className="text-center">
                              <div className="dropdown">
                                <span
                                  className="dropdown-toggle card-drop"
                                  data-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i className="mdi mdi-dots-horizontal font-size-18"></i>
                                </span>
                                <ul className="dropdown-menu dropdown-menu-right">
                                  <li>
                                    <span
                                      className="dropdown-item"
                                      onClick={() => initEdit(row)}
                                    >
                                      <i className="fas fa-pencil-alt text-success mr-1"></i>{" "}
                                      Edit
                                    </span>
                                    {userData?.user_type === "ADMIN" && (
                                      <>
                                        <span
                                          className="dropdown-item"
                                          onClick={() => initDelete(row.id)}
                                        >
                                          <i className="fas fa-trash-alt text-danger mr-1"></i>{" "}
                                          Delete
                                        </span>

                                        <span
                                          className="dropdown-item"
                                          onClick={() => initSetPassword(row)}
                                        >
                                          <i className="fas fa-lock text-danger mr-1"></i>{" "}
                                          Set Password
                                        </span>
                                      </>
                                    )}
                                  </li>
                                </ul>
                              </div>
                            </td> */}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <div>
                    <PaginateWrapper
                      data={data}
                      onPageChange={handlePageClick}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </>
      )}

      <Modal
        show={modalStateForm}
        onHide={handleCloseFormModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{modalTitleForm}</Modal.Title>
        </Modal.Header>

        <UserForm
          handleCloseModal={handleCloseFormModal}
          formID={formID}
          formActionType={formActionType}
          branches={branches}
          ticketTypes={ticketTypes}
          allAssignments={assignments}
          onSubmit={onSubmit}
          forms={forms}
        />
      </Modal>

      <Modal
        show={modalStateSearch}
        onHide={resetExport}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{modalTitleSearch}</Modal.Title>
        </Modal.Header>

        <Search
          handleCloseModal={resetExport}
          onSearchSubmit={onSearchSubmit}
          query={query}
          isExport={isExport}
          onExportSubmit={onExportSubmit}
          tfsSearch={tfsSearch}
          resetExport={resetExport}
          exporting={exporting}
          exportData={exportData}
          exportReady={exportReady}
        />
      </Modal>
    </>
  );
};

export default Users;
